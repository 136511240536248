import React,{useState,useEffect} from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import SaltarPaso from "../SaltarPaso";
import { useDispatch } from "react-redux";
import CustomInput from "../../inputs/CustomInput";
import { update_usuario } from "../../../store/users/registro/action";
import CustomSelect from "../../inputs/CustomSelect";
import { getEntidades } from "../../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import { validaNames,validaClabe } from "../../../helpers/formularios/Validation";
import CancelarModal from "../CancelarModal";

const Pagos = ({ item, setStep }) => {
    const [modal, setModal] = useState(false) 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: item.bancarios,
        validationSchema: Yup.object({
            cuenta_nombre: validaNames(),

            clabe: validaClabe(),

            cuenta_no: Yup.string()
            .required('Campo requerido')
            .matches(/^\d{5,10}$/, 'El numero de cuenta debe tener entre 5 y 10 dígitos'),

            entidad_id: Yup.number()
                .required('La entidad es requerida')
                .integer('La entidad debe ser un número entero')
                .positive('La entidad debe ser un número positivo'),
        }),
        onSubmit: async (values, { setErrors }) => {
            let newItem = { ...item, bancarios: values }
            dispatch(update_usuario(newItem))
            window.scrollTo(0, 500);
            setStep(5)
        },
    });

    const [bancarias,setBancarias] = useState([])
    
    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getEntidades()
            setBancarias(response.entidadesBancarias)
        }
        getCatalogos()
    }, []);

    return (
        <>
            <SaltarPaso
                title={'Sobre el registro de información bancaria:'}
                text={'Los datos registrados nos permitirán hacer la transferencia por concepto de pago de honorarios una vez los procesos asociados al servicio hayan concluido'}
                textBold={'Es responsabilidad del profesionista actualizar sus datos bancarios para recibir sus pagos en tiempo y forma .'}
                step={3}
                setStep={setStep}
                button={false}
            />
            <form onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                console.log(validation.values);
                return false;
            }}
            >
                <div className="flex flex-wrap max-w-[80%] ml-[19%] mt-[60px]">
                    <div className="w-full text-base font-bold leading-6 max-md:max-w-full">
                        Cuenta de pago de honorarios
                    </div>
                    <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            Nombre de la cuenta
                        </div>
                        <CustomInput
                            name="cuenta_nombre"
                            type={'text'}
                            placeholder={'Nombre'}
                            value={validation.values?.cuenta_nombre || ''}
                            error={validation.errors?.cuenta_nombre}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            CLABE
                        </div>
                        <CustomInput
                            name="clabe"
                            type={'text'}
                            placeholder={'CLABE'}
                            value={validation.values?.clabe || ''}
                            error={validation.errors?.clabe}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            No. de cuenta
                        </div>
                        <CustomInput
                            name="cuenta_no"
                            type={'number'}
                            placeholder={'No. Cuenta'}
                            value={validation.values?.cuenta_no || ''}
                            error={validation.errors?.cuenta_no}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            Entidad bancaria{' '}<span className='text-red-600'>*</span>
                        </div>
                        <CustomSelect
                            name="entidad_id"
                            options={[
                                { label: 'Seleccione una opción.', value: 0 },
                                ...bancarias?.map(data => {
                                    return { value: data?.id, label: data?.nombre }
                                })
                            ]}
                            value={validation.values?.entidad_id || ''}
                            error={validation.errors?.entidad_id}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>
                <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10 ">
                    <button
                        className="grow justify-center items-center px-16 py-5 shadow-sm bg-stone-100 rounded-[36px] w-fit max-md:px-5 max-md:max-w-full hover:bg-o2o-gris-hover"
                        onClick={() => {
                            setModal(true)
                        }}
                    >
                        Cancelar registro
                    </button>
                    <button className="grow justify-center px-24 py-5 bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover" type="submit">
                        Guardar información y continuar
                    </button>
                </div>
            </form>
            <CancelarModal modal={modal} setModal={setModal}/>
        </>
    )
}

export default Pagos