import React from 'react'
import Buscador from '../home/Buscador'
import CustomInput from '../../../components/inputs/CustomInput'
import CustomSelect from '../../../components/inputs/CustomSelect'
import CustomTextArea from '../../../components/inputs/CustomTextArea'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import CustomTabs from '../../../components/CustomTabs'
import CustomAcordion from '../../../components/CustomAcordion'
import Divider from '../../../components/Divider'
import { useNavigate } from 'react-router-dom'


const CentroAtencion = () => {

    const navigate = useNavigate();

    const items = [
        { title: '¿Qué información necesito para crear una cuenta?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.www.one2one.mx/login' },
        { title: '¿Puedo contratar para un pariente en otro domicilio?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.www.one2one.mx/login' },
        { title: '¿Qué puedo hacer en caso de una emergencia?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.www.one2one.mx/login' },
        { title: '¿Cuáles son mis responsabilidades y cuáles del enfermero?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.www.one2one.mx/login' },
        { title: '¿Puedo solicitar el reembolso de un servicio?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.www.one2one.mx/login' },
        { title: '¿Puedo cambiar de enfermero después de la contratación?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.www.one2one.mx/login' },
        { title: '¿Puedo ofrecer un incentivo al enfermero por su desempeño?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.www.one2one.mx/login' },
        { title: '¿Cómo puedo borrar mi cuenta de cliente?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.www.one2one.mx/login' },
        { title: '¿Puedo cancelar mi solicitud antes de la fecha de servicio?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.www.one2one.mx/login' },
    ];

    const tabs = [
        { id: 1, label: 'Clientes y pacientes', content: <CustomAcordion items={items}/> },
        { id: 2, label: 'Enfermeros y proveedores', content: 'Contenido de la pestaña 2' },
        { id: 3, label: 'Empresas e instituciones', content: 'Contenido de la pestaña 3' },
    ];

    const cardInfo = [
        {
            perfil: 'Perfil Cliente / Paciente',
            titulo1: 'Contrato de Prestación de Servicios Cliente',
            titulo2: 'Código de Conducta Cliente',
            fecha_actualizacion: '17 de Marzo de 2023'
        },
        {
            perfil: 'Socio Enfermero',
            titulo1: 'Contrato de Prestación de Servicios Socio Enfermero',
            titulo2: 'Código de Conducta Socio Enfermero',
            fecha_actualizacion: '17 de Marzo de 2023'
        },
        /*
        {
            perfil: 'Perfil Cliente / Paciente',
            titulo1: 'Contrato de Prestación de Servicios Empresas',
            titulo2: 'Código de Conducta Empresas',
            fecha_actualizacion: '17 de Marzo de 2023'
        },
        */
    ];

    return (
        <>
        <Buscador/>
        <div className='flex flex-wrap mr-[60px]  ml-[60px]'>
            <div className='w-full sm:w-2/12 mt-[252px]'>
                <div className="justify-center items-center px-2.5 w-6 h-6 text-center text-white whitespace-nowrap bg-rose-600 rounded-2xl shadow-sm">
                    !
                </div>
                <div className="mt-3 w-full text-3xl leading-9 font-bold">Levanta un reporte</div>
                <div className="mt-1.5 w-full leading-6">
                    <span className="font-bold">¿Algún inconveniente con tu servicio?</span>
                    <br />
                    Inicia el proceso digital de denuncia para ofrecerte una solución.
                </div>
                <div className="mt-9 w-full text-sm leading-4 mb-2 font-bold">Nombre completo</div>
                <CustomInput 
                    placeholder='Nombre completo'
                    type='text'
                />

                <div className="mt-5 w-full text-sm leading-4 mb-2 font-bold">Correo electrónico</div>
                <CustomInput 
                    placeholder='example@mail.com'
                    type='email'
                />
                
                <div className="mt-5 w-full text-sm leading-4 mb-2 font-bold">Móvil</div>
                <CustomInput 
                    placeholder='Móvil'
                    type='number'
                />

                <div className="mt-5 w-full text-sm leading-4 mb-2 font-bold">Tipo de perfil</div>
                <CustomSelect
                    options={[]}
                /> 

                <div className="mt-5 w-full text-sm leading-4 mb-2 font-bold">Tipo de reporte</div>
                <CustomSelect
                    options={[]}
                /> 

                <div className="mt-5 w-full text-sm leading-4 mb-2 font-bold">Área a la que refiere</div>
                <CustomSelect
                    options={[]}
                /> 

                <div className="mt-5 w-full text-sm leading-4 mb-2 font-bold">Describe el incidente</div>
                <CustomTextArea
                    placeholder='La enfermera...'
                    rows={5}
                />

                <CustomButtonOval
                    className='bg-o2o-aqua hover:bg-o2o-aqua-hover w-[100%] mt-5'
                >
                    <span className='font-bold'>Enviar reporte</span>
                </CustomButtonOval>
                <div className="mt-9 w-full text-sm leading-5">
                    Nos pondremos en contacto vía correo electrónico o llamada telefónica
                    para dar seguimiento a tu petición.
                </div>
            </div>

            <div className='w-full sm:w-10/12 p-10'>
                <div className='flex flex-wrap'>
                    <div className='w-full font mb-10'>
                        <p className='font-bold text-4xl mb-5'>Preguntas frecuentes</p>
                        <p className='text-xl'>
                        Con la finalidad de facilitar la información y los procesos de contratación y 
                        operación de nuestros servicios. Disponemos de un apartado de resolución de dudas 
                        frecuentes. Las preguntas y sus respuestas se encuentran categorizadas por tipo de 
                        perfil y por tema. 
                        <span className='font-bold'>En caso de no encontrar respuesta a algún, favor de utilizar el 
                        formulario de atención o reporte.</span>
                        </p>
                    </div>
                    <div className='w-full'>
                        <CustomTabs defaultTab={1} tabs={tabs}/>
                    </div>
                </div>
            </div>
            <div className='w-full '>
                <Divider/>
            </div>
            
        </div>

        <div className='flex flex-wrap mt-10'>
            <div className='w-full sm:w-2/12'>

            </div>
            <div className='w-full sm:w-10/12 font mb-10'>
                <p className='font-bold text-5xl mb-3'>Documentación y consulta de información</p>
                <p>
                En este apartado puedes encontrar los documentos legales como contratos, 
                términos y condiciones, aviso de privacidad y otros. Los cuales se actualizan periódicamente.
                </p>
            </div>
        </div>

        <div className='flex flex-wrap justify-center'>
            {cardInfo.map((data,index) => {
                return (
                    <div className='w-full md:w-1/3 p-2'>
                        <div className="flex flex-col self-stretch px-6 pt-6 pb-14 bg-white rounded-xl border border-violet-700 border-solid h-[100%]">
                            <CustomButtonOval
                                className={'bg-o2o-violeta hover:bg-o2o-violeta-hover self-start'}
                                onClick={() => navigate('/documentacion-informacion')}
                            >
                                {data?.perfil}
                            </CustomButtonOval>
                            <div className="flex gap-5 justify-between mt-8">
                                <div className="flex justify-center items-center self-start px-3 bg-stone-100 h-[73px] rounded-[40px] w-[73px]">
                                <img
                                    loading="lazy"
                                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&"
                                    className="w-12 aspect-[0.96]"
                                />
                                </div>
                                <div className="flex flex-col text-black">
                                    <div className="text-2xl font-bold leading-8">
                                        {data?.titulo1}
                                    </div>
                                    <div className="justify-center px-2 py-1.5 mt-3 text-xs leading-3 text-center rounded-md border border-violet-700 border-solid">
                                        Última actualización: {data?.fecha_actualizacion}
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-5 justify-between mt-8">
                                <div className="flex justify-center items-center self-start px-3 bg-stone-100 h-[73px] rounded-[40px] w-[73px]">
                                    <img
                                        loading="lazy"
                                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/74022e577d4c84deddc860f59dcbe918ad766b53b2db07ff306baccccf9b7251?apiKey=488c1b104d124b2d9cec11a2285ec3be&"
                                        className="w-12 aspect-[0.96]"
                                    />
                                </div>
                                <div className="flex flex-col text-black">
                                    <div className="text-2xl font-bold leading-8">
                                        {data?.titulo2}
                                    </div>
                                    <div className="justify-center px-2 py-1.5 mt-3 text-xs leading-3 text-center rounded-md border border-violet-700 border-solid">
                                        Última actualización: {data?.fecha_actualizacion}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            
        </div>
        <div className="flex flex-wrap justify-center items-center mt-10 bg-o2o-gris-arena rounded-xl font">
            <div className='w-2/3 text-center font-bold text-5xl mb-10 mt-5'>
            Más sobre nosotros
            </div>
            <div className='w-2/3 text-center font-bold mb-5'>
            <CustomButtonOval
                className={'bg-o2o-gris hover:bg-o2o-gris-hover'}
                onClick={() => navigate('/quienes-somos')}
            >
                ¿Quiénes somos?
            </CustomButtonOval>
            <CustomButtonOval
                className={'bg-o2o-gris hover:bg-o2o-gris-hover'}
                onClick={() => navigate('/como-funciona')}
            >
                ¿Cómo funciona?
            </CustomButtonOval>
            </div>
        </div>
        </>
    )
}

export default CentroAtencion;