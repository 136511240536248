import React from "react";
import { useNavigate } from "react-router-dom"; // Asegúrate de importar useNavigate si lo necesitas
import CustomButtonOval from "../../components/buttons/CustomButtonOval";

const ResumenAdmin = ({ authUser }) => {
    const navigate = useNavigate(); // Asegúrate de inicializar useNavigate

    const servicios = [
        {
            id: 1,
            enfermero: {
                imagen: "/img/user.png",
                nombre: 'Alexis Gonzalez Piedra',
                especialidad: 'Ortopedia',
            },
            fecha_servicio: '1 a 8 de marzo. Lunes a Viernes. Matutino'
        },
        {
            id: 1,
            enfermero: {
                imagen: "/img/user.png",
                nombre: 'Alexis Gonzalez Piedra',
                especialidad: 'Ortopedia',
            },
            fecha_servicio: '1 a 8 de marzo. Lunes a Viernes. Matutino'
        },
    ]

    const pacientes = [
        {
            id: 1,
            imagen: "/img/user.png",
            nombre: 'nombre de paciente',
            necesidad: {
                especialidad: {
                    nombre: 'Enfermeria geriatrica'
                },
                turno: 'Matutino',
                tiempo: '12 hors.',
                dias_solicitud: 'Lunes a viernes',
                cuidados: [
                    { id: 1, nombre: 'Post-operatorio' },
                    { id: 2, nombre: 'Monitoreo de signos' },
                ]
            }
        },
        {
            id: 2,
            imagen: "/img/user.png",
            nombre: 'nombre de paciente',
            necesidad: {
                especialidad: {
                    nombre: 'Enfermería Oncológica'
                },
                turno: 'Nocturno',
                tiempo: '12 hors.',
                dias_solicitud: 'Lunes a viernes',
                cuidados: [
                    { id: 1, nombre: 'Post-operatorio' },
                    { id: 2, nombre: 'Monitoreo de signos' },
                ]
            }
        },
    ]
    return (
        <>
            <div className="flex flex-wrap max-w-full md:max-w-[75%] ml-4 md:ml-[19%] p-4">
                <div className="w-full mb-4">
                    <b className="block mb-2 text-xl">Esta es tu central de información.</b>
                    <p>Aquí puedes consultar y editar tus datos para conectar con especialistas en enfermería.</p>
                </div>
            </div>
            <div className="p-4 flex flex-wrap max-w-full md:max-w-[75%] ml-4 md:ml-[19%]">
                <div className="flex items-center bg-o2o-gris-calido p-4 mt-4 rounded text-xl w-full">
                    <span className="bg-o2o-morado text-white p-3 rounded text-xl">9</span>
                    <span className="ml-2 text-sm">Tienes nuevos mensajes sin leer</span>
                    <span className="ml-auto">
                        <CustomButtonOval
                            className="bg-o2o-gris hover:bg-o2o-aqua-hover text-sm"
                            onClick={() => navigate('/quienes-somos')}
                        >
                            Leer
                        </CustomButtonOval>
                    </span>
                </div>
            </div>
            <div className="p-4 flex flex-wrap max-w-full md:max-w-[75%] ml-4 md:ml-[19%]">
                {servicios.map((s, index) => (
                    <div key={index} className="flex w-full md:w-[48%] items-center bg-o2o-gris-calido p-2 mt-4 rounded mr-4 shadow-md">
                        <div className="w-[60px] h-[60px] flex items-center justify-center rounded-full overflow-hidden mr-4 bg-o2o-aqua mb-4">
                            <img
                                loading="lazy"
                                src={s.enfermero.imagen}
                                className="max-w-full max-h-full"
                                alt="User"
                            />
                        </div>
                        <div className="flex flex-col flex-grow text-sm">
                            <b className="block mb-1">{s.enfermero.nombre}</b>
                            <p>Especialidad en {s.enfermero.especialidad}</p>
                            <p className="mt-2">Califica el primer día de tu servicio</p>
                            <p>{s.fecha_servicio}</p>
                        </div>
                        <div className="flex justify-between mt-0">
                            <CustomButtonOval
                                className="bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm"
                                onClick={() => { } /* Acción de clic */}
                            >
                                Califica el servicio
                            </CustomButtonOval>
                        </div>
                    </div>
                ))}
                <div className="flex flex-col items-start mt-10 w-full">
                    <div className="w-[106px] h-[106px] flex rounded-full overflow-hidden mb-2">
                        <img
                            loading="lazy"
                            src={authUser.perfilImg || "/img/user.png"}
                            className="max-w-full max-h-full"
                            alt="User"
                        />
                    </div>
                    <p className="text-center font-bold text-base">{authUser.nombre}</p>
                </div>
                {pacientes.map((p, index) => (
                    <div key={index} className="flex w-full items-center bg-o2o-gris-calido p-2 mt-4 rounded-xl shadow-md text-sm">
                        <div className="flex flex-col flex-grow">
                            <b className="block mb-1">{p.necesidad.especialidad.nombre}</b>
                            <p>Requiere</p>
                            <p className="mt-2">{p.necesidad.dias_solicitud}. {p.necesidad.tiempo}. {p.necesidad.turno}</p>
                        </div>
                        <div className="flex flex-col flex-grow">
                            <div className="flex flex-wrap justify-end gap-3 mb-10">
                                {p.necesidad.cuidados.map((data, index) => (
                                    <button
                                        type="button"
                                        key={index}
                                        className="justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover bg-o2o-gris-hover"
                                    >
                                        {data.nombre}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
                <div className="flex w-full items-center p-2 mt-4 rounded-xl text-sm">
                    <div className="flex flex-col flex-grow">
                        <p>{authUser.edad} Años</p>
                        <p>{authUser.email}</p>
                        <p>+52{authUser.movil}</p>
                        <p>+52{authUser.telefono}</p>
                        <p>{authUser.calle} {authUser.numeroExterior} {authUser.numeroInterior} CP {authUser.cp} {authUser.direccion.municipio.nombre} {authUser.direccion.estado.nombre}, {authUser.direccion.pais.nombre}</p>
                    </div>
                    <div className="flex flex-col flex-grow">
                        <div className="flex flex-wrap justify-end gap-3 mb-10">
                            {authUser.interesesPersonales.map((data, index) => (
                                <button
                                    type="button"
                                    key={index}
                                    className="justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover bg-o2o-gris-hover"
                                >
                                    {data.nombre}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-full p-12 flex justify-start">
                    <CustomButtonOval
                        className="bg-o2o-gris hover:bg-o2o-aqua-hover text-sm"
                        onClick={() => navigate('/perfiles')}
                    >
                        Editar
                    </CustomButtonOval>
                </div>
            </div>
            <div className="p-4 flex flex-wrap max-w-full md:max-w-[75%] ml-4 md:ml-[19%]">
                {pacientes.map((p, index) => (
                    <div key={index} className="flex w-full md:w-[45%] mr-3 items-center p-2 mt-4 rounded-xl border-2 border-o2o-gris-arena">
                        <div className="flex w-full items-center p-2 mt-4 rounded-xl text-sm">
                            <div className="flex flex-col flex-grow">
                                <div className="w-[60px] h-[60px] flex items-center justify-center rounded-full overflow-hidden mr-4 mb-2">
                                    <img
                                        loading="lazy"
                                        src={p.imagen}
                                        className="max-w-full max-h-full"
                                        alt="User"
                                    />
                                </div>
                                <p>{authUser.edad} Años</p>
                                <p>Requiere</p>
                                <p>{p.necesidad.dias_solicitud}. {p.necesidad.tiempo}. {p.necesidad.turno}</p>
                                <div className="flex flex-wrap justify-start gap-1 mb-10">
                                    {p.necesidad.cuidados.map((data, index) => (
                                        <button
                                            type="button"
                                            key={index}
                                            className="justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover bg-o2o-gris-hover"
                                        >
                                            {data.nombre}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </>
    )
}

export default ResumenAdmin