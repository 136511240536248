import React from "react";
import { imgUrl } from "../../../helpers/recursosurl";
import { registro } from "../../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import { loginSuccess } from "../../../store/auth/login/action";
import { reset_usuario,add_errors } from "../../../store/users/registro/action";
import { useDispatch } from "react-redux";

const CrearCuenta = ({ setStep, item }) => {
    const navigate = useNavigate();
    const dispath = useDispatch();
    const storeUser = async () => {
        const response = await registro(item);
        if (response.status) {
            dispath(loginSuccess(response))
            dispath(reset_usuario())
            navigate('/home')
        }else{
            if(response.response?.data?.errors){
                dispath(add_errors(response.response.data.errors))
            }
        }
    }

    return (
        <>
            <div className="flex flex-col pt-10 ">
                <div className="pl-14 w-full rounded-2xl bg-stone-100 max-md:pl-5 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[36%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col self-stretch my-auto text-base font-bold text-black max-md:mt-10">
                                <div className="leading-[150%]">¡Felicidades!</div>
                                <div className="mt-1.5 text-5xl leading-10 max-md:text-4xl max-md:leading-10">
                                    Bienvenid@ aOne 2 One Nurses
                                </div>
                                {item.perfil_id == 1 ?
                                    <>
                                        <div className="mt-8 leading-6">
                                            <span className="font-bold">
                                                Con tu cuenta podrás contactar con los mejores profesionistas
                                                en enfermería.
                                            </span>{" "}
                                            Podrás realizar búsquedas avanzadas para dar con la o el
                                            enfermero ideal para ti, tu paciente o tu familia.
                                        </div>
                                        <div className="mt-5 text-sm leading-5">
                                            Además, podrás dar seguimiento a tus solicitudes de servicio,
                                            comunicarte y calificar a proveedores previos de servicio,
                                            consultar tu historial con la plataforma, entre otras.
                                            <br />
                                            <br />
                                            <span className="font-bold">
                                                Agradecemos unirte a esta comunidad.
                                            </span>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="mt-8 leading-6">
                                            <b>Con tu cuenta profesional podrás contactar con clientes</b> en búsqueda de servicio de cuidados enfermeros a domicilio. One to One Nurses te ofrecerá clientes formales y comprometidos contigo.
                                        </div>
                                        <div className="mt-5 text-sm leading-5">
                                            Además, podrás dar seguimiento a tus solicitudes de servicio, comunicarte y calificar a clientes, consultar tu historial con la plataforma, entre otras.
                                            <br />
                                            <br />
                                            <span className="font-bold">
                                                Agradecemos unirte a esta comunidad.
                                            </span>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[64%] max-md:ml-0 max-md:w-full">
                            {item.perfil_id == 1 ?
                                <img
                                    loading="lazy"
                                    srcSet={`${imgUrl}/nurse_cliente.png`}
                                    className="self-stretch my-auto w-full aspect-[0.85] max-md:mt-0 max-md:max-w-full"
                                />
                                :
                                <img
                                    loading="lazy"
                                    src={`${imgUrl}/registro/registro.png`}
                                    className="self-stretch my-auto w-full aspect-[1.85] max-md:-mt-6 max-md:max-w-full"
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10">
                    <button
                        className="grow justify-center px-24 py-5 bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover text-2xl font-bold"
                        onClick={() => storeUser()}
                    >
                        Crear cuenta e ir a Mi Resumen
                    </button>
                </div>
            </div>
        </>
    )
}

export default CrearCuenta;