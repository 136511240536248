import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pacientesResource, getMotivosCancelacion, pacienteDown } from "../../../helpers/configRoutes/backend_helper";
import { useDispatch } from "react-redux";
import { update_loader } from "../../../store/loading/action";
import CustomTabsPerfiles from "../../../components/CustomTabsPerfiles";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import CustomTextArea from "../../../components/inputs/CustomTextArea";
import Respuestas from "./Respuestas";

const CancelacionPaciente = () => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const navigate = useNavigate();
    const [paciente, setPaciente] = useState(null)
    const [motivos, setMotivos] = useState([])
    const [respuestasOptions, setRespuestas] = useState({ status: false, respuesta: 0 })
    const [cancelacion, setCancelacion] = useState({
        especificacion: '',
        motivo_id: null,
        motivo_nombre: 'motivo',
        fatal: false
    })

    useEffect(() => {
        const getDataPaciente = async () => {
            dispatch(update_loader(true))
            const response = await pacientesResource('show', {}, id)
            if (response.status) {
                setPaciente(response.paciente)
                const responseCatalogos = await getMotivosCancelacion({ params: { model: 'paciente' } })
                setMotivos(responseCatalogos.motivos)
                dispatch(update_loader(false))
            } else {
                dispatch(update_loader(false))
                navigate('/perfiles')
            }
        }
        getDataPaciente()
    }, []);

    const tabs = [
        { id: 1, label: 'Administrador', content: '', action: '/perfiles' },
        { id: 2, label: 'Pacientes', content: '', disabled: true },
    ];

    const save = async () => {
        dispatch(update_loader(true))
        const response = await pacienteDown(cancelacion, id)
        if (response.status) {
            if (response.fatal) {
                setRespuestas({ status: true })
                dispatch(update_loader(false))
            } else {
                setRespuestas({ status: true })
                dispatch(update_loader(false))
            }
        }else{
            dispatch(update_loader(false))
        }
    }

    return (
        <>

            {paciente &&
                <>
                    <CustomTabsPerfiles defaultTab={2} tabs={tabs} />
                    <div className="flex flex-col max-w-full ml-4 md:ml-8 lg:ml-[376px] mt-6 md:mt-10 space-y-2">
                        {respuestasOptions.status ? (
                            <Respuestas cancelacion={cancelacion} paciente={paciente} />
                        ) : (
                            <>
                                <h2 className="font-semibold text-lg md:text-xl">Borrar el perfil de {paciente.nombre} {paciente.apellido}</h2>
                                <p className="text-base md:text-lg">¿Seguro que quieres dar de baja a este paciente?</p>
                                <div className="rounded-lg p-5 mb-5 max-w-full md:max-w-[75%] flex items-start border border-gray-300 border-solid w-full flex-col">
                                    <p className="text-base md:text-lg font-bold">Agradeceríamos conocer el motivo de la cancelación</p>
                                    <div className="flex flex-row flex-wrap gap-2 mt-5">
                                        {motivos.map((motivo, index) => (
                                            <CustomButtonOval
                                                key={index}
                                                type={'success'}
                                                onClick={() => setCancelacion({ ...cancelacion, motivo_id: motivo.id, motivo_nombre: motivo.motivo, fatal: motivo.fatal })}
                                                className={`${cancelacion.motivo_id === motivo.id ? 'bg-o2o-gris-arena-button' : ''} text-sm hover:bg-o2o-aqua-hover`}
                                            >
                                                {motivo.motivo}
                                            </CustomButtonOval>
                                        ))}
                                    </div>
                                    <div className="w-full px-2 mb-3">
                                        <div className="text-sm font-bold leading-4 text-black mb-2">
                                            Especifica tus razones
                                        </div>
                                        <div className="justify-center">
                                            <CustomTextArea
                                                value={cancelacion.especificacion}
                                                rows={6}
                                                placeholder='Texto para especificar el motivo de la cancelación del servicio.'
                                                name="descripcionPersonal"
                                                onChange={(e) => setCancelacion({ ...cancelacion, especificacion: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <p className="text-base md:text-lg md:w-3/4">Dar de baja el perfil de un paciente implica la eliminación de la información asociada a éste: datos personales y médicos, así como solicitudes de asistencia e historial de servicios.</p>
                                <p className="text-base md:text-lg md:w-3/4">La búsqueda rápida de cuidados enfermeros está vinculada al perfil del paciente.</p>
                                <div className="flex flex-row flex-wrap gap-2 mt-10 pt-10">
                                    <CustomButtonOval
                                        type={'success'}
                                        onClick={() => save()}
                                        className={`bg-o2o-secondary-rojo text-sm hover:bg-o2o-gris-hover`}
                                    >
                                        Dar de baja
                                    </CustomButtonOval>
                                    <CustomButtonOval
                                        type={'button'}
                                        onClick={() => navigate(`/perfiles`)}
                                        className={`bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover`}
                                    >
                                        Cancelar
                                    </CustomButtonOval>
                                </div>
                            </>
                        )}
                    </div>


                </>
            }
        </>
    )
}
export default CancelacionPaciente;