import React, { useState,useEffect } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { useNavigate } from "react-router-dom";
import { imgUrl } from "../../../helpers/recursosurl";
import { enfermeroResource } from "../../../helpers/configRoutes/backend_helper";
import { useDispatch } from "react-redux";
import {update_loader} from '../../../store/loading/action'

const Respuestas = ({ cancelacion, paciente }) => {
    const navigate = useNavigate()
    const dispatch= useDispatch()
    const [tanatologos,setTanatologos] = useState([])
    const [meta,setMeta] = useState({})
    /*[
        {
            nombre: 'Sara Rodríguez',
            img: 't1.png',
            calificacion: 4.7,
            edad: 24,
            cedula: 123123123,
            especialidad: 'Tanatologia',
            descripcion: 'Enfermera especializada en pediatría, alergias, Inmunología y Neumología. Busco, con mi trabajo, contribuir a la buena salud de las familias mexicanas'
        },
        {
            nombre: 'Ricardo Sesín',
            img: 't3.png',
            calificacion: 4.7,
            edad: 24,
            cedula: 123123123,
            especialidad: 'Tanatologia',
            descripcion: 'Enfermera especializada en pediatría, alergias, Inmunología y Neumología. Busco, con mi trabajo, contribuir a la buena salud de las familias mexicanas'
        },
        {
            nombre: 'Carolina López',
            img: 't2.png',
            calificacion: 4.7,
            edad: 24,
            cedula: 123123123,
            especialidad: 'Tanatologia',
            descripcion: 'Enfermera especializada en pediatría, alergias, Inmunología y Neumología. Busco, con mi trabajo, contribuir a la buena salud de las familias mexicanas'
        }
    ]
    */

    useEffect(() => {
        const getPacientes = async () => {
            dispatch(update_loader(true))
            const response = await enfermeroResource('get', { params: {especialidad_id:14} });
            setMeta(response.meta)
            setTanatologos(response.enfermeros);
            dispatch(update_loader(false))
        }
        getPacientes();
    }, []);

    return (
        <>
            {cancelacion.fatal ?
                <>
                    <h2 className="text-xl font-semibold">Lamentamos profundamente la situación que atraviesas</h2>
                    <p className="text-base max-w-[75%]">
                        <b>Vivir es la experiencia más sublime, bella y enriquecedora.</b> Todas las vidas
                        humanas son valiosas; iluminan a nuestras familias, amigos y seres queridos.
                    </p>
                    <p className="text-base max-w-[75%]">
                        One to One quisiera acompañarte en este momento. Te compartimos los perfiles de algunos profesionistas que podrían ser de ayuda durante esta situación.
                    </p>
                    <div className="flex justify-center space-x-4">
                        {tanatologos.map((t, index) => (
                            <div className="bg-white shadow-md rounded-lg p-4 w-80" key={index}>
                                <img className="w-full h-48 rounded-t-lg object-cover" src={`${imgUrl}/${t.img}`} alt="Image of Sara Rodriguez" />
                                <div className="p-4">
                                    <h3 className="text-xl font-bold text-gray-800">{t.nombre}</h3>
                                    <p className="text-gray-500">{t.edad} años</p>
                                    <p className="text-gray-800 mt-2 font-bold">Enfermera</p>
                                    <p className="text-gray-500">Cédula: {t.cedula}</p>
                                    <p className="text-gray-800 mt-2 font-bold">Con especialidad en:</p>
                                    <p className="text-gray-500">Tanatología</p>
                                    <p className="text-gray-600 mt-4">Enfermera especializada en pediatría, alergias, Inmunología y Neumología. Busco, con mi trabajo, contribuir a la buena salud de las familias mexicanas</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <h2 className="text-xl font-semibold">Adicionalmente, nos gustaría compartirte literatura significativa:</h2>
                    <div className="flex flex-row gap-2 mt-10 pt-10">
                        <CustomButtonOval
                            type={'success'}
                            onClick={() => navigate(`/perfiles`)}
                            className={`${'bg-o2o-gris-arena-button'} text-sm hover:bg-o2o-aqua-hover`}
                        >
                            Regresar a tu central de información
                        </CustomButtonOval>
                        <CustomButtonOval
                            type={'buttpm'}
                            onClick={() => navigate(`/perfiles`)}
                            className={`${'bg-o2o-gris-arena-button'} text-sm hover:bg-o2o-aqua-hover`}
                        >
                            Aviso de privacidad
                        </CustomButtonOval>
                    </div>
                </>
                :
                <>
                    <h2 className="text-xl font-semibold">Hemos dado de baja con exito el perfil de {paciente.nombre} {paciente.apellido}</h2>
                    <p className="text-base">La información relacionada al perfil del paciente será eliminada de acuerdo a nuestros Términos & Condiciones.</p>
                    <div className="rounded-lg p-5 mb-5 max-w-[75%] flex items-start border border-gray-300 border-solid w-full flex-col">
                        <p className="text-base font-bold">El motivo por el cual decidiste dar de baja este perfil:</p>
                        <div className="flex flex-row gap-2 mt-5">
                            <CustomButtonOval
                                type={'success'}
                                className={`${'bg-o2o-gris-arena-button'} text-sm hover:bg-o2o-aqua-hover`}
                            >
                                {cancelacion.motivo_nombre}
                            </CustomButtonOval>
                        </div>
                        <div className="w-full md:w-3/4 px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                {cancelacion.motivo_id == 7 ? '¡Nos entusiasma saber que Guillermina se encuentra mejor!' : ''}
                            </div>
                            <div className="justify-center ">

                            </div>
                        </div>
                    </div>
                    <p className="text-base md:w-3/4">Para usar nuestros servicios a futuro, recomendamos la creación de uno o más perfiles nuevos de paciente. Estaremos para servirte.</p>

                    <div className="flex flex-row gap-2 mt-10 pt-10">
                        <CustomButtonOval
                            type={'success'}
                            onClick={() => navigate(`/perfiles`)}
                            className={`${'bg-o2o-secondary-rojo'} text-sm hover:bg-o2o-gris-hover`}
                        >
                            Entendido
                        </CustomButtonOval>
                        <CustomButtonOval
                            type={'buttpm'}
                            onClick={() => navigate(`/nuevo-paciente`)}
                            className={`${'bg-o2o-gris-arena-button'} text-sm hover:bg-o2o-aqua-hover`}
                        >
                            Crear nuevo perfil de paciente
                        </CustomButtonOval>
                    </div>
                </>
            }
        </>
    )
}
export default Respuestas