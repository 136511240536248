import React from 'react'
import { TextBlock } from '../../../components/TextBlock'
import { imgUrl } from '../../../helpers/recursosurl'

const PoliticaRespaldo = () => {
    return (
        <div className="flex flex-col px-5 mx-auto w-full font-bold text-black ">
          <header>
            <h1 className="w-full text-5xl leading-10">Política de respaldo</h1>
          </header>
          <section>
            <TextBlock tag="p" className="mt-8 w-full text-2xl leading-8 font-normal">
              Nuestro objetivo es salvaguardar la integridad de clientes, pacientes y proveedores de
              servicios.
            </TextBlock>
            <TextBlock tag="p" className="mt-8 w-full text-2xl leading-8 font-normal">
            Hemos desarrollado estrategias para buscar tu satisfacción con nuestros
              servicios. Estos son algunos de nuestros esfuerzos:
            </TextBlock>
            <img
              loading="lazy"
              src={`${imgUrl}/phone.png`}
              alt="Descripción imagen"
              className="mt-20 max-w-full aspect-[0.56] w-[278px]"
            />
            <TextBlock tag="h2" className="w-full text-3xl leading-9 mt-[460px]">
              Juntos nos cuidamos
            </TextBlock>
            <TextBlock tag="p" className="mt-5 w-full text-base leading-6 font-normal">
              Estas regulaciones nos permiten cuidar —entre todos— la integridad de todas las personas
              que conformamos One to One Nurses.
            </TextBlock>
          </section>
        </div>
    )
}

export default PoliticaRespaldo