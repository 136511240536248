import React, { useEffect, useState } from "react";
import ProgressBar from "../../components/ProgressBar";
import { recompenzasResource } from "../../helpers/configRoutes/backend_helper";
import CustomButtonOval from '../../components/buttons/CustomButtonOval'

const Rewards = () => {
    const [currentLevel, setCurrentLevel] = useState(0); // Cambia este valor para simular diferentes niveles
    const [levels, setLavels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({perPage:3});

    useEffect(() => {
        const getRecompenzas = async () => {
            const response = await recompenzasResource('get', {});
            setLavels(response.recompenzas);
            setCurrentLevel(response.nivelActual)
        }
        getRecompenzas()
    }, [filters]);

    const getReclamoRecompenza = async(id) =>{
        setLoading(true)
        let data = {recompenza_id:id}
        const response = await recompenzasResource('post', data);
        if(response.status){
            setFilters({...filters,perPage:filters.perPage})
        }
        setLoading(false)
    }
    return (
        <>
            <div className="flex flex-wrap max-w-full lg:max-w-[75%] mx-auto px-4">
                <div className="flex-container justify-between items-start lg:items-center mb-4 ml-[100px]">
                    <div className="text-[32] font-semibold w-[574px] mt-[84px]">
                        <h2>¡Estás en el nivel {currentLevel}</h2>
                        <h2>de nuestro programa de recompensas!</h2>
                    </div>
                    <div className="text-base w-[574px] mt-[20px] mb-[50px]">
                        <p>Nuestro programa de recompensas te ofrece bonos adicionales al alcanzar distintos niveles dentro de la plataforma.</p>
                    </div>
                    <div className="p-4">
                        <div className="relative w-full">
                            {/* Barra de progreso con círculos */}
                            <div className="flex items-center justify-between">
                                {levels.map((level, index) => (
                                    <React.Fragment key={index}>
                                        {/* Círculo */}
                                        <div
                                            className={`w-6 h-6 rounded-full border-2 z-10 ${currentLevel >= level.nivel ? 'bg-teal-500 border-teal-500' : 'bg-white border-gray-300'
                                                }`}
                                        ></div>

                                        {/* Segmento de la barra de progreso */}
                                        {index < levels.length - 1 && (
                                            <div className={`flex-1 h-2 ${currentLevel > level.nivel ? 'bg-teal-500' : 'bg-gray-200'}`}></div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                            {/* Textos debajo de la barra */}
                            <div className="flex justify-between mt-5"> {/* mt-5 agrega un margen de 20px (aproximadamente) */}
                                {levels.map((level, index) => (
                                    <div key={index} className={`text-center ${level.can?.show ? '' : 'text-o2o-gris-calido'}`}>
                                        <p className="text-sm font-bold">{`Nivel ${level.nombre}`}</p>
                                        <p className="text-sm font-bold">{level.logro}</p>
                                        <p className="text-sm">{level.condicion}</p>
                                        {level.can?.cobrar ?
                                            <CustomButtonOval
                                                className={'bg-o2o-aqua hover:bg-o2o-aqua-hover w-full font-bold text-sm'}
                                                type='sucess'
                                                loading={loading}
                                                textCarga={'Procesando'}
                                                onClick={() => getReclamoRecompenza(level.id)}
                                            >
                                                {'Adquirir Recompenza'}
                                            </CustomButtonOval>
                                            :
                                            <>
                                                <p className="text-sm font-bold">
                                                    {level.enfermeroRecompenza?.estatus_nombre ?? 'No se han completado las condiciones'}
                                                </p>
                                            </>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Rewards;