"use client";
import { imgUrl } from '../../helpers/recursosurl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { scroller } from "react-scroll";
import { getAuthUser } from '../../helpers/configRoutes/backend_helper';

const HeaderAuth = () => {
  const authUser = getAuthUser();
  const [configRoute, setConfigRoute] = useState('perfil');
  const menuList = [
    { label: authUser.nombre, ruta: '/home', route: 'perfil' },
    { label: "Gestionar cuenta", ruta: '/gestionar-cuenta', route: 'perfil' },
    { label: "Cerrar sesión", ruta: '/logout', route: 'perfil' },
    { label: "Mi resumen", ruta: "/home", route: 'responsivo' },
    { label: "Mi perfil", ruta: "/perfiles", perfil_id: 4, route: 'responsivo' },
    { label: "Turnos", ruta: "/turnos", perfil_id: 4, route: 'responsivo' },
    { label: "Mis servicios", ruta: "/servicios", perfil_id: 4, route: 'responsivo' },
    { label: "Mis honorarios", ruta: "/cobros-pagos", perfil_id: 4, route: 'responsivo' },
    { label: "Mis perfiles", ruta: "/perfiles", perfil_id: 5, route: 'responsivo' },
    { label: "Mensajes", ruta: "/mensajes", perfil_id: 5, route: 'responsivo' },
    { label: "Historial de servicios", ruta: "/servicios", perfil_id: 5, route: 'responsivo' },
    { label: "Informacion de cobro", ruta: "/cobros-pagos", perfil_id: 5, route: 'responsivo' },
    { label: "¿Quiénes somos?", ruta: '/quienes-somos', route: 'public' },
    { label: "Manifesto", ruta: '/manifesto', route: 'public' },
    { label: "¿Cómo funciona?", ruta: '/como-funciona', route: 'public' },
    { label: "Seguridad", ruta: '/seguridad', route: 'public' },
    { label: "Costos y membresías", ruta: '/costos-membresias', route: 'public' },
    { label: "Testimoniales", ruta: '/testimoniales', route: 'public' },
    { label: "blog", ruta: '/blog' },
  ];

  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    scroller.scrollTo("inicio", { duration: 0, delay: 0, smooth: true });
  }, [location]);

  useEffect(() => {
    if (location.pathname.startsWith('/buscar-enfermero')) {
      setConfigRoute('public');
    }
  }, [location]);

  const handleMenuClick = (ruta) => {
    navigate(ruta);
    setMenu(false); // Cierra el menú responsivo
  };

  return (
    <>
      {location?.pathname !== '/login' && (
        <div className="flex flex-wrap justify-center item-center">
          <header className="bg-white p-2 flex flex-wrap justify-between items-center fixed top-0 w-full z-20 border-b-2 border-white">
            {/* Logo en el lado izquierdo */}
            <div className="flex items-center">
              <Link to="/">
                <img src={`${imgUrl}/Layer_1.png`} alt="Logo" className="h-8 md:h-16 ml-4 md:mr-2" />
              </Link>
              <span className="text-dark text-lg font-bold"></span>
            </div>
            <button
              className="md:hidden hover:bg-o2o-gris-arena rounded py-2 px-4 shadow-sm"
              onClick={() => setMenu(!menu)}
            >
              {menu ?
                <span className="material-symbols-outlined">
                  menu_open
                </span>
                : <span className="material-symbols-outlined">
                  menu
                </span>}
            </button>
            {/* Menú en el lado derecho */}
            <nav className="hidden md:block">
              {configRoute === 'public' && (
                <ul className="flex flex-col md:flex-row space-x-5">
                  <li>
                    <button className="bg-white hover:bg-gray-200 text-gray-800 py-2 px-4 rounded-full border-o2o-aqua border-solid border-2">
                      Buscador por paciente o diagnóstico
                    </button>
                  </li>
                  <li>
                    <button
                      className="bg-white hover:bg-gray-200 text-gray-800 py-2 px-4 rounded-full border-o2o-aqua border-solid border-2"
                      onClick={() => navigate('/central-atencion')}
                    >
                      Central de atención
                    </button>
                  </li>
                  <li>
                    <button
                      className="bg-white hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded-full border-o2o-aqua border-solid border-2 flex items-center"
                      onClick={() => [setConfigRoute('perfil'), navigate('/home')]}
                    >
                      <span>{authUser.nombre}</span>
                      <img src={authUser.perfilImg} alt="User" className="ml-2 h-6 w-6 rounded-full" />
                    </button>
                  </li>
                </ul>
              )}
              {/* Menu de escritorio */}
              <ul className="flex flex-col md:flex-row space-x-7 mt-2 md:mt-4">
                {menuList.map((data, index) => {
                  if (data.route === configRoute) {
                    return (
                      <li className='bg-white' key={index}>
                        <Link
                          className={`text-black ${location.pathname === data?.ruta ? 'font-bold' : ''}`}
                          to={data?.ruta}
                        >
                          {data?.label}
                        </Link>
                      </li>
                    )
                  }
                })}
              </ul>
            </nav>

            {/* Menu responsivo */}
            {menu && (
              <nav className="w-full md:hidden opacity-transition">
                <ul className="flex flex-col mt-4">
                  {menuList.map((data, index) => {
                    if ((data.route === configRoute || data.route === 'responsivo') && (data.perfil_id === authUser.perfil_id || data.perfil_id === undefined)) {
                      return (
                        <li className='bg-white' key={index}>
                          <Link
                            className="text-gray-800 hover:text-blue-600 hover:bg-o2o-gris-arena font-semibold p-2 flex"
                            to={data?.ruta}
                            onClick={() => handleMenuClick(data?.ruta)}
                          >
                            {data?.label}
                          </Link>
                        </li>
                      )
                    }
                  })}
                </ul>
              </nav>
            )}
          </header>
        </div>
      )}
    </>
  );
};

export default HeaderAuth;
