import React from 'react'
import DescubreMasO2O from './DescubreMasO2O'
import parse from 'html-react-parser';

const CodigoDeContucta = () => {
    const htmlString = `
    <div> </div>
    <p class="wysiwyg-text-align-justify"><span style="white-space: pre-wrap;">Última actualización: 17 de Marzo de 2023<br><br>Contrato de Regulación de Conducta al Proveedor de Servicios celebrado entre:<br>One to One Nurses con Cliente o anfitrión</span></p>
    <p class="wysiwyg-text-align-justify"><span style="white-space: pre-wrap;"><br>El presente Código de Conducta es el instrumento que orienta y da certeza plena a los clientes que contratan los servicios de cuidados enfermeros ofrecidos a través de One 2 One Nurses sobre el comportamiento ético al que deben sujetarse al recibir en su domicilio al profesional médico, para que prevengan conflictos de interés y que delimiten su actuación en situaciones específicas que pueden presentarse conforme a las tareas, funciones o actividades que involucra la operación y el cumplimiento de las actividades descritas en el contrato de servicios, así como las áreas y procesos que involucren riesgos de posibles actos por ambas partes.<br><br>La aplicación de los valores éticos que contiene este Código, en cada una de las actividades asignadas, coadyuva en el cumplimiento eficiente y eficaz de las metas y objetivos de One 2 One Nurses y contribuye a la oferta de un entorno y servicio seguro para clientes y profesionales de la salud por igual.</span></p>
    <p class="wysiwyg-text-align-justify"> </p>
    <p class="wysiwyg-text-align-justify"><span style="white-space: pre-wrap;"><br><strong>Valores contenidos en el Código de Conducta</strong><br><strong>Respeto</strong><br>Los anfitriones se conducen con austeridad y sin ostentación, y otorgan un trato digno y cordial a los profesionistas médicos que acuden a su domicilio, considerando sus derechos de tal manera que propician el diálogo cortés y la aplicación armónica de instrumentos que conduzcan al entendimiento, a través de la colaboración y el trato humano.<br><br><strong>Como anfitrión debo:</strong></span></p>
    <p class="wysiwyg-text-align-justify"> </p>
    <ol class="wysiwyg-text-align-justify">
    <li><span style="white-space: pre-wrap;">Mantener relaciones laborales cordiales y respetuosas que no se basen exclusivamente en niveles jerárquicos o de autoridad.</span></li>
    <li><span style="white-space: pre-wrap;">Dirigirme al profesionista médico con el que interactúo con respeto y dignidad.</span></li>
    <li><span style="white-space: pre-wrap;">Observar una conducta honrada hacia las pertenencias personales del proveedor de servicios.</span></li>
    <li><span style="white-space: pre-wrap;">Conducirme en forma digna sin proferir expresiones, adoptar comportamientos, usar lenguaje o realizar acciones de hostigamiento o acoso sexual. </span></li>
    </ol>
    <p class="wysiwyg-text-align-justify"><strong><span style="white-space-collapse: preserve;">Como anfitrión evito:</span></strong></p>
    <ol class="wysiwyg-text-align-justify">
    <li><span style="white-space: pre-wrap;">Tratar injustamente a cualquier persona haciendo ostentación de mis bienes o situación socioeconómica.</span></li>
    <li><span style="white-space: pre-wrap;">Realizar actos que atenten contra la integridad física y emocional así como de la dignidad humana.</span></li>
    <li><span style="white-space: pre-wrap;">Limitar la libre expresión de ideas o de pensamiento.</span></li>
    <li><span style="white-space: pre-wrap;">Provocar conflictos entre el proveedor de servicios y las demás personas que puedan convivir en determinado tiempo en el domicilio.</span></li>
    <li><span style="white-space: pre-wrap;">Llevar a cabo conductas dominantes, agresivas, intimidatorias u hostiles hacia una persona para que se someta a deseos o intereses sexuales, o al de alguna otra u otras personas.</span></li>
    <li><span style="white-space: pre-wrap;">Condicionar la obtención de un empleo, su permanencia en él o las condiciones del mismo a cambio de aceptar conductas injustas.</span></li>
    <li><span style="white-space: pre-wrap;">Expresar comentarios, burlas, piropos o bromas hacia otra persona referentes a la apariencia o a la anatomía con connotación sexual, bien sea presenciales o a través de algún medio de comunicación.</span></li>
    <li><span style="white-space: pre-wrap;">Expresar insinuaciones, invitaciones, favores o propuestas a citas o encuentros de carácter sexual.</span></li>
    <li><span style="white-space: pre-wrap;">Emitir expresiones o utilizar lenguaje que denigre a las personas.<br></span></li>
    </ol>
    <p class="wysiwyg-text-align-justify"> </p>
    <p class="wysiwyg-text-align-justify"><strong><span style="white-space: pre-wrap;">Igual y no discriminación</span></strong></p>
    <p class="wysiwyg-text-align-justify"><span style="white-space: pre-wrap;">Los anfitriones son receptivos y respetuosos a todas los proveedores de servicios sin distinción, exclusión, restricción, o preferencia basada en el origen étnico o nacional, el color de piel, la cultura, el género, la edad, las discapacidades, la condición social, económica, de salud o jurídica, la religión, la apariencia física, las características genéticas, la situación migratoria, el embarazo, la lengua, las opiniones, las preferencias sexuales, la identidad o filiación política, el estado civil, la situación familiar, las responsabilidades familiares, el idioma o en cualquier otro motivo.<br><br><strong>Como anfitrión debo:</strong><br></span></p>
    <ol class="wysiwyg-text-align-justify">
    <li><span style="white-space: pre-wrap;">Ser justo en el trato con los demás sin importar su color de piel, su nacionalidad, su origen étnico, su género, sus preferencias sexuales, su condición social, económica, de salud o jurídica, su edad, su condición física, sus creencias, su apariencia, su situación migratoria, su idioma, la cultura a la que pertenece, sus opiniones, su filiación política, su estado civil, sus antecedentes penales o cualquier otro motivo.</span></li>
    <li><span style="white-space: pre-wrap;">Reconocer que todos los individuos gozan de los mismos derechos y obligaciones.</span></li>
    <li><span style="white-space: pre-wrap;">Fomentar el acceso a oportunidades de desarrollo sin discriminación de género. <br></span></li>
    </ol>
    <p class="wysiwyg-text-align-justify"><span style="white-space: pre-wrap;"><strong>Como anfitrión evito:</strong></span></p>
    <ol class="wysiwyg-text-align-justify">
    <li><span style="white-space: pre-wrap;">Discriminar a las personas por su origen étnico o nacional, el color de piel, la cultura, el sexo, el género, la edad, las discapacidades, la condición social, económica, de salud o jurídica, la religión, la apariencia física, las características genéticas, la situación migratoria, el embarazo, la lengua, las opiniones, las preferencias sexuales, la identidad o filiación política, el estado civil, la situación familiar, las responsabilidades familiares, el idioma o cualquier otro motivo.</span></li>
    <li><span style="white-space: pre-wrap;">Condicionar el trato en función de la diversidad de género de las personas.</span></li>
    <li><span style="white-space: pre-wrap;">Utilizar un lenguaje que fomente estereotipos o prejuicios en contra de los individuos.</span></li>
    </ol>
    <p class="wysiwyg-text-align-justify"><span style="white-space: pre-wrap;">One 2 One Nurses<br>www.onetoone.mx</span></p>`;
    return (
        <>
        <div className='flex bg-o2o-gris-arena rounded-3xl p-5 mb-10'>
            <div className='w-full font'>
            {parse(htmlString)}
            </div>
        </div>
        <DescubreMasO2O/>
        </>
    )
}

export default CodigoDeContucta