import React from "react";
import ResumenAdmin from "../administrador/ResumenAdmin";
import ResumenEnfermero from "../enfermero/ResumenEnfermero";
import { getAuthUser } from "../../helpers/configRoutes/backend_helper";

const HomeLogin = () => {
    const authUser = getAuthUser();
    return (
        <>
            {authUser.perfil_id == 4 ?
                <ResumenEnfermero authUser={authUser}/>
                :
                <ResumenAdmin authUser={authUser} />
            }

        </>
    );
};

export default HomeLogin;
