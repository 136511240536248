import React, { useEffect, useState } from "react";
import CustomButtonOval from "../../components/buttons/CustomButtonOval";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getMotivosCancelacion, usuarioCancelarCuenta, usuariosResource } from "../../helpers/configRoutes/backend_helper";
import { useDispatch } from "react-redux";


const BajaCuenta = () => {
    const [motivos, setMotivos] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [cancelar, setCancelar] = useState({ motivo_id: null, message: '' })

    useEffect(() => {
        const getMotivos = async () => {
            const response = await getMotivosCancelacion({ params: { model: 'usuario' } });
            setMotivos(response.motivos)
        }
        getMotivos()
    }, []);

    const cancelarCuenta = async () => {
        setLoading(true)
        const response = await usuarioCancelarCuenta(cancelar)
        if (response.status) {
            navigate('/logout')
        }
        setLoading(false)

    }

    return (
        <>
            <div className="flex flex-col items-start mt-[94px] ml-[19%]">
                <div className="p-4 w-full">
                    <b className="block mb-2 text-sm font-bold">Esta es tu central de información.</b>
                    <p className="text-sm">Aquí puedes consultar y editar tus datos para conectar con clientes e instituciones.</p>
                </div>
                <CustomButtonOval className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover mb-[32px]" onClick={() => navigate('/gestionar-cuenta')}>
                    <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                    >
                        <path
                            d="M5.80078 1L1.00078 5L5.80078 9"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Regresar a gestionar cuenta
                </CustomButtonOval>
                <div className="text-[32px] mb-[18px] font-semibold">
                    <h2>Cancelar cuenta profesional</h2>
                </div>
                <div className="text-base">
                    <p>¿Seguro que quieres eliminar tu cuenta dentro de One 2 One Nurses?</p>
                    <p className="mt-[20px] w-[574px]">
                        Eliminar tu cuenta implica que <strong>borraremos tu perfil profesional como proveedor de servicios de
                            cuidados enfermeros.</strong> Por lo tanto, perderás la capacidad de recibir ofertas laborales,
                        tu historial de servicios y el acceso a todas las funciones que One 2 One
                        ofrece a sus colaboradores. Al borrar tu cuenta estás aceptando los
                        <Link style={{ color: 'blue' }}>Términos & Condiciones</Link> respecto a la eliminación de tu información dentro de nuestra plataforma.
                    </p>
                    <p className="mt-[20px] w-[574px]">
                        Te recordamos que puedes consultar nuestro <Link style={{ color: 'blue' }}>Centro de Atención</Link>, en caso de tener cualquier duda o queja
                        relacionada al servicio o la plataforma.
                    </p>
                </div>
                <div className="border rounded-lg p-4 mb-4 w-[95%] mt-[18px]">
                    <div className="text-base font-semibold">
                        Agradeceríamos conocer el motivo de la cancelación de cuenta
                    </div>
                    <div className="flex justify-start space-x-2 mt-[30px]">
                        {motivos.map((m, index) => (
                            <CustomButtonOval
                                key={index}
                                onClick={() => setCancelar({ ...cancelar, motivo_id: m.id })}
                                className={`flex justify-end ${m.id == cancelar.motivo_id ? 'bg-o2o-gris-arena-hover' : 'bg-o2o-gris-arena-button'} text-sm hover:bg-gris-arena-hover`}
                            >
                                {m.motivo}
                            </CustomButtonOval>
                        ))}
                    </div>
                    <div className="border rounded-lg p-4 bg-gray-100  mt-4 w-[90%]">
                        <p className="mb-2 font-semibold text-sm">Especifica tus razones</p>
                        <textarea
                            className="border rounded-lg w-full h-32 p-2 bg-gray-50 resize-none"
                            placeholder="Texto para especificar el motivo de la cancelación o baja de la cuenta"
                        ></textarea>
                    </div>
                </div>
                <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-[95%]">
                    <div className="flex justify-end space-x-2">
                        <CustomButtonOval onClick={() => cancelarCuenta()} className="flex justify-end bg-o2o-secondary-rojo text-sm hover:bg-o2o-secondary-rojo-hover">
                            Si, eliminar cuenta
                        </CustomButtonOval>
                        <CustomButtonOval className="flex justify-end bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover">
                            No, continuar con la cuenta
                        </CustomButtonOval>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BajaCuenta;