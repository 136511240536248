import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Divider from '../../../components/Divider';
import { useIsMobile } from '../../../store/hooks/useIsMobile';

const SlidesSteps = ({ titulo, cards, step }) => {

    const { isMobile } = useIsMobile();

    return (
        <>
            {/* ENCABEZADO */}
            <div className="flex flex-col text-center text-black">
                <div className="flex gap-5 items-center mt-7 max-md:flex-wrap">
                    <div className="justify-center items-center self-stretch px-3.5 text-2xl font-bold text-center text-black whitespace-nowrap bg-cyan-300 rounded-2xl border-b border-solid border-black border-opacity-40 h-[42px] w-[42px]">
                        {step}
                    </div>
                    <div className="flex-auto self-stretch my-auto text-2xl font-bold text-black max-md:max-w-full text-start">
                        {titulo}
                    </div>
                </div>
                <Divider />
            </div>
            {/* CARRUSEL */}
            <div className="flex flex-col  items-center ">
                <div className='container'>
                    <div className='swiperContainer'>
                        <Swiper
                            modules={[Navigation, Pagination]}
                            spaceBetween={20}
                            slidesPerView={!isMobile ? 2.5 : 1}
                            navigation
                            pagination={{ clickable: true }}
                        >
                            {cards.map((data, index) => (

                                <SwiperSlide key={index}>
                                    <div className="flex flex-col m-[12px] text-black max-w-[80%] ">
                                        <img
                                            loading="lazy"
                                            srcSet={data?.img}
                                            className="w-[457px] h-[300px] aspect-[1.52]"
                                        />
                                        <div className="mt-6 w-full text-2xl font-bold">{data?.titulo}</div>
                                        <div className="mt-3 w-full text-base leading-6">
                                            {data?.descripcion}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SlidesSteps