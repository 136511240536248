import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { imgUrl } from "../../../helpers/recursosurl";
import BarraAvance from "./BarraAvance";
import Perfil from "../../../components/registro/Perfil";
import PerfilPaciente from "../../../components/registro/administrador/PerfilPaciente";
import { useSelector } from 'react-redux';
import Cobro from "../../../components/registro/pago/Cobro";
import CrearCuenta from "./CrearCuenta";
import Acreditaciones from "../../../components/registro/enfermero/Acreditaciones";
import Pagos from "../../../components/registro/enfermero/Pagos";
import ContratosLegales from "../../../components/registro/ContratosLegales";

const Registro = () => {
    const { perfil_id } = useParams();
    const [step, setStep] = useState(1);

    const item = useSelector(state => state.Usuario.usuario);
    item.perfil_id = perfil_id;

    const [porcentajebarra, setPorcentajebarra] = useState(parseFloat((step / 5) * 100) + '%')
    useEffect(() => {
        const getPorcentaje = () => {
            setPorcentajebarra(parseFloat((step / 5) * 100) + '%')
        };
        getPorcentaje();
    }, [step]);

    return (
        <>
            <div className='mt-14'></div>
            {step != 5 &&
                <div className="flex flex-col pt-6">
                    <div className="pl-14 mt-6 w-full rounded-none bg-stone-100 max-md:pl-5 max-md:max-w-full">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-[41%] max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col self-stretch my-auto text-black max-md:mt-10 max-md:max-w-full">
                                    {perfil_id == 1 ?
                                        <>
                                            <div className="text-3xl font-bold leading-9 max-md:max-w-full">
                                                Cuidados enfermeros a domicilio
                                            </div>
                                            <div className="mt-1.5 text-base leading-6 max-md:max-w-full">
                                                <span className="font-bold">Completa tu registro</span> para conectar con profesionistas en enfermería de manera fácil y segura.
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="text-3xl font-bold leading-9 max-md:max-w-full">
                                                Trabajo seguro para enfermeros
                                            </div>
                                            <div className="mt-1.5 text-base leading-6 max-md:max-w-full">
                                                <span className="font-bold">Completa tu registro</span> para conectar con clientes que solicitan cuidados enfermeros a domicilio.
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-[59%] max-md:ml-0 max-md:w-full">
                                <img
                                    loading="lazy"
                                    src={`${imgUrl}/registro/${perfil_id == 1 ? 'registro-cliente.png' : 'registro.png'}`}
                                    className="self-stretch my-auto w-full aspect-[1.85] max-md:-mt-6 max-md:max-w-full"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="mr-[60px] ml-[60px] max-md:mr-1 max-md:ml-1">

                {step == 1 &&
                    <>
                        <div className="flex flex-col text-black">
                            <div className="flex flex-col justify-center items-start rounded-2xl shadow-sm bg-stone-100 max-md:pr-5 mt-5">
                                <div className={`shrink-0 h-3 bg-o2o-aqua rounded-2xl w-[20%]`} />
                            </div>
                            <BarraAvance step={step} infoflag={true} perfil_id={perfil_id} setStep={setStep} />
                        </div>
                        <ContratosLegales item={item} setStep={setStep} />
                    </>

                }
                {step == 2 &&
                    <>
                        <div className="flex flex-col text-black">
                            <div className="flex flex-col justify-center items-start rounded-2xl shadow-sm bg-stone-100 max-md:pr-5 mt-5">
                                <div className={`shrink-0 h-3 bg-o2o-aqua rounded-2xl w-[40%]`} />
                            </div>
                            <BarraAvance step={step} infoflag={true} perfil_id={perfil_id} setStep={setStep} />
                        </div>
                        <Perfil item={item} setStep={setStep} />
                    </>
                }
                {(step == 3 && perfil_id == 1) ?
                    <>
                        <div className="flex flex-col text-black">
                            <div className="flex flex-col justify-center items-start rounded-2xl shadow-sm bg-stone-100 max-md:pr-5 mt-5">
                                <div className={`shrink-0 h-3 bg-o2o-aqua rounded-2xl w-[60%]`} />
                            </div>
                            <BarraAvance step={step} infoflag={true} perfil_id={perfil_id} setStep={setStep} />
                        </div>
                        <PerfilPaciente item={item} setStep={setStep} />
                    </>
                    :
                    ''
                }
                {(step == 3 && perfil_id == 2) ?
                    <>
                        <div className="flex flex-col text-black">
                            <div className="flex flex-col justify-center items-start rounded-2xl shadow-sm bg-stone-100 max-md:pr-5 mt-5">
                                <div className={`shrink-0 h-3 bg-o2o-aqua rounded-2xl w-[60%]`} />
                            </div>
                            <BarraAvance step={step} infoflag={true} perfil_id={perfil_id} setStep={setStep} />
                        </div>
                        <Acreditaciones item={item} setStep={setStep} />
                    </>
                    :
                    ''
                }
                {(step == 4 && perfil_id == 1) ?
                    <>
                        <div className="flex flex-col text-black">
                            <div className="flex flex-col justify-center items-start rounded-2xl shadow-sm bg-stone-100 max-md:pr-5 mt-5">
                                <div className={`shrink-0 h-3 bg-o2o-aqua rounded-2xl w-[80%]`} />
                            </div>
                            <BarraAvance step={step} infoflag={true} perfil_id={perfil_id} setStep={setStep} />
                        </div>
                        <Cobro item={item} setStep={setStep} />
                    </>
                    :
                    ''
                }
                {(step == 4 && perfil_id == 2) ?
                    <>
                        <div className="flex flex-col text-black">
                            <div className="flex flex-col justify-center items-start rounded-2xl shadow-sm bg-stone-100 max-md:pr-5 mt-5">
                                <div className={`shrink-0 h-3 bg-o2o-aqua rounded-2xl w-[80%]`} />
                            </div>
                            <BarraAvance step={step} infoflag={true} perfil_id={perfil_id} setStep={setStep} />
                        </div>
                        <Pagos item={item} setStep={setStep} />
                    </>
                    :
                    ''
                }
                {(step == 5) ?
                    <>
                        <div className="flex flex-col text-black">
                            <div className="flex flex-col justify-center items-start rounded-2xl shadow-sm bg-stone-100 max-md:pr-5 mt-5">
                                <div className={`shrink-0 h-3 bg-o2o-aqua rounded-2xl w-[100%]`} />
                            </div>
                            <BarraAvance step={step} infoflag={true} perfil_id={perfil_id} setStep={setStep} />
                        </div>
                        <CrearCuenta item={item} setStep={setStep} />
                    </>
                    :
                    ''
                }
            </div>

        </>
    )
}
export default Registro;