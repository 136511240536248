import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Divider from "../../../components/Divider";
import InformacionPersonal from "../../perfiles/InformacionPersonal";
import InteresesPersonales from "../../../components/registro/InteresesPersonales";
import FormacionAcademica from "../../../components/registro/enfermero/FormacionAcademica";
import ExperienciaLaboral from "../../../components/registro/enfermero/ExperienciaLaboral";
import ExperienciaAcademica from "../../../components/registro/enfermero/ExperienciaAcademica";
import ExperienciaEspecialidad from "../../../components/registro/enfermero/ExperienciaEspecialidad";
import HabilidadesOfertaServicios from "../../../components/registro/enfermero/HabilidadesOfertaServicios";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { getModulo, usuariosResource } from "../../../helpers/configRoutes/backend_helper";
import { validaNumerosTelefonos, validaCurp, validaDates } from "../../../helpers/formularios/Validation";
import { showFormErrors } from "../../../helpers/showErrors";

const PerfilEnfermero = ({ ...props }) => {
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: props.authUser,
        validationSchema: Yup.object({
            nombre: Yup.string().required('Campo requerido'),
            apellidos: Yup.string().required('Campo requerido'),
            fechaNacimiento: validaDates(18),
            movil: validaNumerosTelefonos(true),
            email: Yup.string().email('Correo electrónico inválido').required('Campo requerido'),
            curp: validaCurp(),
            calle: Yup.string().required('Campo requerido'),
            cp: Yup.string().required('Campo requerido'),
            cp_id: Yup.string().required('Campo requerido'),
            numeroExterior: Yup.string().required('Campo requerido'),
            numeroInterior: Yup.string().required('Campo requerido'),
            pais: Yup.string().required('Campo requerido'),
            estado: Yup.string().required('Campo requerido'),
            municipio: Yup.string().required('Campo requerido'),
            colonia: Yup.string().required('Campo requerido'),
            descripcionPersonal: Yup.string().nullable(),
            informacionUnica: Yup.string().nullable(),
            sexo_id: Yup.string().required('Campo requerido'),
            perfilImg: Yup.string().required('Campo requerido'),
        }),
        onSubmit: async (values, { setErrors }) => {

        },
    });

    const validationAcreditacion = useFormik({
        enableReinitialize: true,
        initialValues: props.authUser.acreditaciones,
        validationSchema: Yup.object({
            estudios: Yup.string().required('Campo requerido'),
            casa_estudios: Yup.string().required('Campo requerido'),
            cedula_profesional: Yup.string()
                .required('Campo requerido')
                .matches(/^\d{6,8}$/, 'La cedula debe tener entre 8 y 10 dígitos'),
            graduacion_fecha: validaDates(0, 'Tu graduacion tiene que ser anterior a la fecha de hoy '),
            descripcion_profesional: Yup.string().required('Campo requerido'),
            experiencias_laborales: Yup.array().of(
                Yup.object().shape({
                    cargo: Yup.string().required('Campo requerido'),
                    actividades: Yup.string().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),

                })
            ).min(1, 'Debe haber al menos una formación académica'),

            formacion_academica: Yup.array().of(
                Yup.object().shape({
                    institucion: Yup.string().required('Campo requerido'),
                    especialidad_id: Yup.number().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),
                    formacion: Yup.boolean(),
                })
            ).min(1, 'Debe haber al menos una formación académica'),
            formacion_especialidades: Yup.array().of(
                Yup.object().shape({
                    institucion: Yup.string().required('Campo requerido'),
                    especialidad_id: Yup.number().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),
                    formacion: Yup.boolean(),
                })
            ),
            servicios: Yup.array().min(1)
        }),
        onSubmit: async (values, { setErrors }) => {
            console.log(values)
            //validation.setFieldValue('acreditaciones', values)
            validation.values.acreditaciones = values
            console.log(validation.values,'validacionValues')
            await validation.handleSubmit();
            console.log('ackoksokcs')
            const response = await usuariosResource('put', validation.values, validation.values.id);
    
            if (response.status) {
                let storedData = localStorage.getItem('authUser');
                if (storedData) {
                    // Parsear los datos JSON
                    console.log(response.item, 'item')
                    let userData = JSON.parse(storedData);
                    userData.user = response.item
                    // Actualizar los datos en el almacenamiento local
                    localStorage.setItem('authUser', JSON.stringify(userData));
                }
            } else {
    
            }
            console.log('acreditafionsok')
            
        },
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        await validationAcreditacion.handleSubmit();
        console.log(validationAcreditacion)

    };

    const [modulo, setModulo] = useState(false);
    useEffect(() => {
        const getModulos = async () => {
            const response = await getModulo({ params: { modulo: 'Especialidades' } })
            setModulo(response.modulo)
        }
        getModulos()
    }, []);

    return (
        <>
            <div className="flex flex-wrap max-w-[75%] ml-[19%] max-md:max-w-full max-md:ml-0 mr-[60px]">
                <div className="p-4">
                    <b className="block mb-2 text-sm font-bold">Esta es tu central de información.</b>
                    <p className="text-sm">Aquí puedes consultar y editar tus datos para conectar con clientes e instituciones.</p>
                </div>
            </div>

            <form onSubmit={e => {
                e.preventDefault();
                validationAcreditacion.handleSubmit();
                showFormErrors(validation)
                showFormErrors(validationAcreditacion)
                return false;
            }} className="mr-[60px]">
                <InformacionPersonal validation={validation} />
                <InteresesPersonales validation={validation} categorias_ids={1} />
                <FormacionAcademica validation={validationAcreditacion} update={true} />
                <ExperienciaLaboral validation={validationAcreditacion} />
                <ExperienciaAcademica validation={validationAcreditacion} />
                {modulo.activo ? <ExperienciaEspecialidad validation={validationAcreditacion} is_especialidad={true} />:''}
                <HabilidadesOfertaServicios validation={validationAcreditacion} update={true} />
                <Divider />
                <div className="bg-o2o-gris-calido flex justify-center items-center max-w-[70%] mx-auto rounded-lg p-[18px] pt-2 mt-12 max-md:max-w-full max-md:px-4">
                    <div className="flex">
                        <CustomButtonOval
                            type="success"
                            className="bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover"
                        >
                            Guardar información
                        </CustomButtonOval>
                    </div>
                </div>
            </form>

        </>
    );
};
export default PerfilEnfermero;
