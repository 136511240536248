import {
  UPDATE_LOADER
} from "./actionTypes"

export const update_loader = loader => {
  return {
    type: UPDATE_LOADER,
    payload: loader,
  }
}
