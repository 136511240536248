import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import SaltarPaso from "../SaltarPaso";
import { useDispatch } from "react-redux";
import CustomInput from "../../inputs/CustomInput";
import CustomInputExpiracion from "../../inputs/CustomInputExpiracion";
import CustomSelect from "../../inputs/CustomSelect";
import Facturacion from "./Facturacion";
import { update_usuario } from "../../../store/users/registro/action";
import { getEntidades } from "../../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import { showFormErrors } from "../../../helpers/showErrors";
import CustomInputMaskPerson from "../../inputs/CustomInputMaskPerson";
import { validaTarjeta, validaVencimiento, validaNames, validaRfc } from "../../../helpers/formularios/Validation";
import CancelarModal from "../CancelarModal";

const Cobro = ({ setStep, item, setItem }) => {
    const [modal, setModal] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const is_factura = (input) => {
        if (validation.values.is_factura) {
            return input == "str" ?
                Yup.string().required('Campo requerido')
                :
                (
                    input == "email" ?
                        Yup.string()
                            .email('Correo electrónico inválido')
                            .required('Campo requerido')
                        :
                        (input == 'rfc' ?
                            validaRfc()
                            :
                            Yup.number()
                                .typeError('Debe ser un número')
                                .required('Campo requerido')
                                .test('len', 'Debe tener exactamente 5 dígitos', val => val && val.toString().length === 5)
                        )
                )
        } else {
            return Yup.string();
        }
    }
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: item.bancarios,
        validationSchema: Yup.object({
            tarjeta_nombre: validaNames(),
            tarjeta: validaTarjeta(),
            vencimiento: validaVencimiento(),
            cvv: Yup.string()
                .required('Campo requerido')
                .matches(/^\d{3}$/, 'El código debe tener 3 dígitos'),
            cvvConfirm: Yup.string()
                .oneOf([Yup.ref('cvv'), null], 'Los códigos deben coincidir')
                .required('Campo requerido'),
            entidad_id: Yup.string().required('Campo requerido'),
            entidad_financiera: Yup.string().required('Campo requerido'),
            calle: Yup.lazy(value => {
                return is_factura('str')
            }),
            cp: Yup.lazy(value => {
                return is_factura('str')
            }),
            numeroExterior: Yup.lazy(value => {
                return is_factura('str')
            }),
            numeroInterior: Yup.string(),
            pais: Yup.lazy(value => {
                return is_factura('str')
            }),
            estado: Yup.lazy(value => {
                return is_factura('str')
            }),
            municipio: Yup.lazy(value => {
                return is_factura('str')
            }),
            colonia: Yup.lazy(value => {
                return is_factura('str')
            }),
            razon_social: Yup.lazy(value => {
                return is_factura('str')
            }),
            email: Yup.lazy(value => {
                return is_factura('email')
            }),

            rfc: Yup.lazy(value => {
                return is_factura('rfc')
            }),
        }),
        onSubmit: async (values, { setErrors }) => {
            let newItem = { ...item, bancarios: values }
            dispatch(update_usuario(newItem))
            window.scrollTo(0, 500);
            setStep(5)
        },
    });

    const [bancarias, setBancarias] = useState([])
    const [financieras, setFinancieras] = useState([])


    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getEntidades()
            setBancarias(response.entidadesBancarias)
            setFinancieras(response.entidadesFinancieras)
        }
        getCatalogos()
    }, []);

    const adminDireccion = (e) => {
        if (e.target.checked) {
            validation.setFieldValue('calle', item.calle)
            validation.setFieldValue('cp', item.cp)
            validation.setFieldValue('cp_id', item.cp_id)
            validation.setFieldValue('numeroExterior', item.numeroExterior)
            validation.setFieldValue('numeroInterior', item.numeroInterior)
            validation.setFieldValue('pais', item.pais)
            validation.setFieldValue('estado', item.estado)
            validation.setFieldValue('municipio', item.municipio)
            validation.setFieldValue('colonia', item.colonia)
            validation.setFieldValue('razon_social', item.nombre)
            validation.setFieldValue('email', item.email)
        }
    }

    return (
        <>
            <SaltarPaso
                title={'Sobre el registro de información bancaria y de facturación'}
                text={'Los datos registrados permitirán hacer el cobro y facturación de servicios automáticamente. Posteriormente, es posible editar o crear nuevos métodos de pago.'}
                textBold={'No haremos ningún cobro hasta que solicites una contratación y ésta sea aprobada.'}
                step={3}
                setStep={setStep}
                button={false}
            />
            <form onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                showFormErrors(validation);
                return false;
            }}
            >
                <div className="flex flex-wrap max-w-full px-4 mt-10">
                    <div className="w-full text-base font-bold leading-6 mb-2">
                        Metodo principal de Pago
                    </div>
                    <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black mb-2">
                            Nombre de la tarjeta
                        </div>
                        <CustomInput
                            name="tarjeta_nombre"
                            type={'text'}
                            placeholder={'Nombre'}
                            value={validation.values?.tarjeta_nombre || ''}
                            error={validation.errors?.tarjeta_nombre}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black mb-2">
                            Numero de la tarjeta
                        </div>
                        <CustomInputMaskPerson
                            name="tarjeta"
                            mask={"9999999999999999"}
                            value={validation.values.tarjeta || ''}
                            error={validation.errors?.tarjeta}
                            type={'number'}
                            placeholder={'1231231231231231'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black mb-2">
                            Vencimiento
                        </div>
                        <CustomInputMaskPerson
                            name="vencimiento"
                            mask={"99/99"}
                            value={validation.values.vencimiento || ''}
                            error={validation.errors?.vencimiento}
                            type={'number'}
                            placeholder={'MM/AA'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black mb-2">
                            Código de seguridad
                        </div>
                        <CustomInput
                            type={'password'}
                            placeholder={'***'}
                            name="cvv"
                            value={validation.values?.cvv || ''}
                            error={validation.errors?.cvv}
                            onChange={validation.handleChange}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black mb-2">
                            Confirmación del código de seguridad
                        </div>
                        <CustomInput
                            type={'password'}
                            placeholder={'***'}
                            name="cvvConfirm"
                            value={validation.values?.cvvConfirm || ''}
                            error={validation.errors?.cvvConfirm}
                            onChange={validation.handleChange}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black mb-2">
                            Entidad bancaria{' '}<span className='text-red-600'>*</span>
                        </div>
                        <CustomSelect
                            name="entidad_id"
                            options={[
                                { label: 'Seleccione una opción.', value: 0 },
                                ...bancarias?.map(data => ({
                                    value: data?.id,
                                    label: data?.nombre
                                }))
                            ]}
                            value={validation.values?.entidad_id || ''}
                            error={validation.errors?.entidad_id}
                            onChange={validation.handleChange}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black mb-2">
                            Entidad financiera{' '}<span className='text-red-600'>*</span>
                        </div>
                        <CustomSelect
                            name="entidad_financiera"
                            options={[
                                { label: 'Seleccione una opción.', value: 0 },
                                ...financieras?.map(data => ({
                                    value: data?.id,
                                    label: data?.nombre
                                }))
                            ]}
                            value={validation.values?.entidad_financiera || ''}
                            error={validation.errors?.entidad_financiera}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>

                <Facturacion validation={validation} adminDireccion={adminDireccion} />
                <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10 ">
                    <button
                        className="grow justify-center items-center px-16 py-5 shadow-sm bg-stone-100 rounded-[36px] w-fit max-md:px-5 max-md:max-w-full hover:bg-o2o-gris-hover"
                        onClick={() => {
                            setModal(true);
                        }}
                    >
                        Cancelar registro
                    </button>
                    <button className="grow justify-center px-24 py-5 bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover" type="submit">
                        Guardar información y continuar
                    </button>
                </div>
            </form>
            <CancelarModal modal={modal} setModal={setModal} />
        </>
    )
}
export default Cobro