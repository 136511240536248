import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";

const SinResultados = ({ paciente, setFilters, filters }) => {
    const navigate = useNavigate();

    return (
        <>

            <div className="w-full md:w-3/4 p-8">
                <h1 className="text-2xl font-bold mb-4">Lo sentimos. No encontramos resultados.</h1>
                <div className="bg-white p-6 border rounded mb-4">
                    <h2 className="text-xl font-semibold mb-2">No hemos encontrado perfiles que cumplan con los criterios utilizados en la búsqueda</h2>
                    <p className="mb-4 text-gray-700">Es probable que la búsqueda de profesionistas con características muy específicas arroje pocos o nulos perfiles. Prueba reducir el número de criterios o filtros asignados al enfermero.</p>
                    <ol className="list-decimal list-inside text-gray-700 mb-4">
                        <li>Intenta hacer una búsqueda sólo asignando las características del paciente.</li>
                        <li>En la pantalla de resultados, usa uno a uno los filtros para delimitar tu búsqueda de acuerdo a tus preferencias.</li>
                    </ol>
                    <p className="mb-4 text-gray-700"><strong>¿Requieres ayuda?</strong></p>
                    <CustomButtonOval
                        type={'success'}
                        onClick={() => navigate(`/construccion`)}
                        className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover'
                    >
                        Ir al centro de atención
                    </CustomButtonOval>
                </div>
                <div className="mt-8">
                    <h2 className="text-xl font-bold mb-2">Somos una comunidad segura</h2>
                    <p className="mb-4 text-gray-700 text-base">Consulta nuestros protocolos</p>
                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                        <CustomButtonOval
                            type={'success'}
                            onClick={() => navigate(`/construccion`)}
                            className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover'
                        >
                            Términos y condiciones
                        </CustomButtonOval>
                        <CustomButtonOval
                            type={'success'}
                            onClick={() => navigate(`/construccion`)}
                            className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover'
                        >
                            Código de conducta
                        </CustomButtonOval>
                    </div>
                </div>
            </div>


        </>
    )
}
export default SinResultados;