import React, { useEffect, useState } from 'react';
import HeaderAuth from './HeaderAuth';
import HeaderPublico from './HeaderPublico';
import { getAuthUser } from '../../helpers/configRoutes/backend_helper';
import { useSelector } from 'react-redux';

const Header = () => {
  const isAuth = useSelector((state) => state.Login.auth.isAuth);

  return (
    <>
      {isAuth ?
        <HeaderAuth />
        :
        <HeaderPublico />
      }
    </>
  );
}

export default Header;
