import React, { useEffect, useState } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { enfermeroTurnosResource, getTiempos } from "../../../helpers/configRoutes/backend_helper";
import AddUpdTurno from "./AddUpdTurno";
import * as Yup from "yup";
import { useFormik } from "formik";
import ConfirmModal from "../../../components/modals/ConfirmDeleteModal";

const Turnos = () => {
  const [modal, setModal] = useState({ show: false })
  const [modalDelete, setModalDelete] = useState({ show: false, textButton: 'Eliminar' })
  const dias = [
    { id: 2, nombre: 'Lunes' },
    { id: 3, nombre: 'Martes' },
    { id: 4, nombre: 'Miércoles' },
    { id: 5, nombre: 'Jueves' },
    { id: 6, nombre: 'Viernes' },
    { id: 7, nombre: 'Sábado' },
    { id: 1, nombre: 'Domingo' },
  ];

  const [c_turnos, setCTurnos] = useState([])
  const [turnos, setTurnos] = useState([])

  const [c_horarios, setCHorarios] = useState([])
  const [arregloFechas, setArregloFechas] = useState([]);
  useEffect(() => {
    const getCatalogos = async () => {
      const response = await getTiempos()
      setCTurnos(response.turnos)
      setCHorarios(response.tiempos)
    }
    getCatalogos()
  }, []);

  useEffect(() => {
    getEnfTurnos()
  }, []);

  const getEnfTurnos = async () => {
    const response = await enfermeroTurnosResource('get', {})
    setTurnos(response.turnos)
  }

  const [initialValues, setInitialValues] = useState({ id: null, turno_id: null, tiempo_id: null, dias: [] });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      turno_id: Yup.number().required('Campo requerido'),
      tiempo_id: Yup.number().required('Campo requerido'),
      dias: Yup.array().of(Yup.number()).required('Campo requerido').min(1),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      setModal({ ...modal, loading: true, textCarga: 'Guardando turno...' })
      const response = await enfermeroTurnosResource(modal.update ? 'put' : 'post', values, values.id)
      if (response.status) {
        setModal({ ...modal, show: false, loading: false })
        getEnfTurnos()
        resetForm();
      } else {
        setModal({ ...modal, loading: false })
      }
    },
  });

  const deleteItem = async (id) => {
    console.log(id, 'plsl')
    const response = await enfermeroTurnosResource('del', {}, id)
    if (response.status) {
      setModalDelete({ ...modalDelete, show: false, loading: false })
      getEnfTurnos()
    } else {
      setModalDelete({ ...modalDelete, loading: false })
    }
  }

  return (
    <>
      <div className="flex flex-wrap max-w-[75%] ml-[19%] max-md:max-w-full max-md:ml-0 mr-[60px] px-4">
        <div className="w-full mb-4">
          <b className="block mb-2 text-sm font-bold">Esta es tu central de información.</b>
          <p className="text-sm">Aquí puedes consultar y editar tus datos para conectar con clientes e instituciones.</p>
        </div>
        <p className="text-sm mt-4">Como mínimo, tus horas disponibles deben cumplir un turno de 8 horas. Debe existir un horario mínimo de descanso de 12 horas cada 24 horas.</p>
      </div>


      <div className="flex flex-col p-5 max-w-full md:max-w-[75%] md:ml-[19%] md:mr-[60px] mt-8 bg-o2o-gris-arena">
        <div className="flex flex-col md:flex-row w-full justify-between items-center border-b border-solid border-black border-opacity-50">
          <p className="text-base font-bold">Horas Base</p>
          <CustomButtonOval className="bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover mt-4 md:mt-0" onClick={() => setModal({ show: true, title: 'Nuevo Horario' })}>
            Añadir turno
          </CustomButtonOval>
        </div>
        <table className="w-full mt-4">
          <tbody>
            {turnos.map((t, i) => (
              <tr key={i}>
                <th>
                  {t.diasCollect?.map((diaC, i) => (
                    <span key={i}>{diaC.dia + ', '}</span>
                  ))}
                </th>
                <th>{t.tiempo?.nombre}</th>
                <th>{t.turno?.nombre}</th>
                <th className="w-2">
                  <CustomButtonOval className="bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover" onClick={() => [setInitialValues(t), setModal({ show: true, update: true, title: 'Editar Horario' })]}>
                    Editar
                  </CustomButtonOval>
                </th>
                <th className="w-2">
                  <CustomButtonOval isDisabled={!t.can?.delete} className="bg-o2o-secondary-rojo text-sm hover:bg-o2o-secondary-rojo-hover" onClick={() => setModalDelete({ ...modalDelete, show: true, title: 'Eliminar Horario', text: '¿Estas seguro de eliminar el horario?', item: t })}>
                    Eliminar
                  </CustomButtonOval>
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col p-5 max-w-full md:max-w-[75%] md:ml-[19%] md:mr-[60px] mt-8">
        <div className="flex flex-col md:flex-row w-full justify-between items-center border-b border-solid border-black border-opacity-50 mb-4">
          <p className="text-base font-bold">Resumen</p>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full min-w-[600px]">
            <thead>
              <tr>
                <th colSpan={2}></th>
                {dias.map((d, i) => (
                  <th className="text-sm" key={i}>{d.nombre}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {c_turnos.map((t, i) => (
                <React.Fragment key={i}>
                  <tr className="border-t border-solid border-gray-300 border-opacity-50" rowSpan={t.no_horas}>
                    <th className="text-sm" rowSpan={1}>{t.nombre}</th>
                  </tr>
                  {t.horas?.map((h, i) => (
                    <tr className="text-sm" key={i}>
                      <th></th>
                      <th>{h}</th>
                      {dias.map((d, i) => {
                        // Filtrar los turnos para el día actual
                        const turnosFiltrados = turnos.filter(tur => {
                          if (tur.general[d.nombre]?.length > 0) {
                            if (tur.general[d.nombre].includes(h)) {
                              return tur;
                            }
                          }
                          tur.diasCollect.map((dc) => {
                            if (dc.dia_no == d.id) {
                              if (dc.can.deleteUpdate) {
                                d.ocupado = false;
                              } else {
                                d.ocupado = true;
                              }
                            }
                            return dc;
                          });
                        });
                        return (
                          <th key={i} className="relative">
                            <span className={`absolute inset-0 m-auto h-full ${turnosFiltrados.length > 0 ? (d.ocupado ? 'bg-o2o-secondary-rojo w-2/5' : 'bg-o2o-gris-arena w-2/5') : ''}`}></span>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {modal.show &&
        <AddUpdTurno validation={validation} modal={modal} setModal={setModal} c_turnos={c_turnos} c_horarios={c_horarios} dias={dias} />
      }
      {modalDelete.show &&
        <ConfirmModal modal={modalDelete} setModal={setModalDelete} action={deleteItem} />
      }
    </>
  )
}

export default Turnos