import React from "react";
import CustomButtonOval from "../buttons/CustomButtonOval";

export const Title = ({ estatus_id, serviciosCant }) => {
    return (
        <>
            <div className="mb-[23px] mt-[37px]">
                {serviciosCant ? (
                    <>
                        <h3>
                            {estatus_id == 5 ? `Has recibido varias solicitudes de servicio`
                                : estatus_id == 9 ? 'Servicios en Curso'
                                    : estatus_id == 6 ? 'Servicios en Concluidos '
                                        : 'Servicios en curso'}
                        </h3>
                        <p>
                            {estatus_id == 5 ? 'Recuerda que una solicitud tiene vigencia de 24 horas y se atribuye al primer profesionista que acepte.'
                                : estatus_id == 9 ? ''
                                    : estatus_id == 6 ? ''
                                        : ''}
                        </p>
                    </>
                )
                    :
                    <h3>
                        {estatus_id == 5 ? 'De momento no has recibido solicitudes nuevas'
                            : estatus_id == 9 ? 'De momento no cuentas con solicitudes activas'
                                : estatus_id == 6 ? ''
                                    : 'De momento no cuentas con solicitudes activas'}
                    </h3>
                }
            </div>
        </>
    )
}

export const TitleAdmin = ({ estatus_id, serviciosCant }) => {
    return (
        <>
            <div className="text-[24px]  mb-[24px] mt-[37px]">
                {estatus_id == 9 ? (
                    <>
                        <h3 className="font-semibold">Servicios en curso</h3>
                    </>
                ) : estatus_id == 5 ? (
                    <>
                        <h3 className="font-semibold">Estamos buscando a tu enfermero.</h3>
                        <p className="text-base">En breve recibirás confirmación y la información del profesional que te atenderá.</p>
                    </>
                ) : estatus_id == 6 ? (
                    <>
                        <h3 className="font-semibold">Historial</h3>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </>
    )
}

export const HeaderCardAdmin = (estatus_id, setComponent, ser, navigate) => {
    return (
        <>
            {console.log(ser)}
            <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                <div className="text-base font-semibold mb-2 md:mb-0">
                    Servicio para {ser?.paciente?.nombre} {ser?.paciente?.apellidos}
                </div>
                <p className="mb-2 md:mb-0">{ser?.folio}</p>
                <div className="flex flex-wrap gap-2">
                    {estatus_id == 9 ? (
                        <>
                            <CustomButtonOval
                                onClick={() => navigate(`/factura-enfermero/${ser?.id}`)}
                                className="flex justify-center bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover"
                            >
                                Facturación
                            </CustomButtonOval>
                            <CustomButtonOval
                                onClick={() => navigate(`/servicios-calificacion/${ser?.id}`)}
                                className="flex justify-center bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover"
                            >
                                Calificar primer día de servicio
                            </CustomButtonOval>
                            <CustomButtonOval
                                className="flex justify-center bg-o2o-secondary-rojo text-sm hover:bg-o2o-secondary-rojo-hover"
                            >
                                Cancelar servicio
                            </CustomButtonOval>
                        </>
                    ) : estatus_id == 5 ? (
                        <>
                            <CustomButtonOval
                                onClick={() => navigate(`/factura-enfermero/${ser?.id}`)}
                                className="flex justify-center bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover"
                            >
                                Facturación
                            </CustomButtonOval>
                            <CustomButtonOval
                                className="flex justify-center text-sm bg-o2o-gris-arena hover:bg-o2o-gris-arena-hover"
                            >
                                Estamos buscando a tu enfermero.
                            </CustomButtonOval>
                            <CustomButtonOval
                                className="flex justify-center bg-o2o-gris-arena text-sm hover:bg-o2o-gris-arena-hover"
                            >
                                Cancelar solicitud
                            </CustomButtonOval>
                        </>
                    ) : (
                        <>
                            <CustomButtonOval
                                isDisabled={!ser?.cita_activa?.can?.calificar}
                                className="flex justify-center bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover"
                            >
                                Pendiente de calificar
                            </CustomButtonOval>
                            <CustomButtonOval
                                className="flex justify-center bg-o2o-gris-arena text-sm hover:bg-o2o-gris-arena-hover"
                            >
                                Cancelar solicitud
                            </CustomButtonOval>
                        </>
                    )}
                </div>
            </div>
            <hr className="border-gray-300 my-4" />

        </>
    )
}