import React, { useState, useEffect } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { pacientesResource } from "../../../helpers/configRoutes/backend_helper";
import { link, useNavigate } from "react-router-dom";
import { imgUrl } from "../../../helpers/recursosurl";
import { update_loader } from "../../../store/loading/action";
import { useDispatch } from "react-redux";
import Paginacion from "../../../components/Paginacion";

const ListadoPacientes = () => {
    const dispatch = useDispatch()
    const [pacientes, setPacientes] = useState([]);
    const navigate = useNavigate();
    const [filters, setFilters] = useState({ perPage: 5, page: 1 })
    const [meta, setMeta] = useState({ total: 0, current_page: 0 })
    useEffect(() => {
        const getPacientes = async () => {
            dispatch(update_loader(true))
            const response = await pacientesResource('get', { params: filters });
            setMeta(response.meta)
            setPacientes(response.pacientes);
            dispatch(update_loader(false))
        }
        getPacientes();
    }, [filters]);

    const ProfileCard = ({ paciente }) => (
        <div className="p-5 mb-5 flex flex-col md:flex-row items-start w-full border-t-4 border-b-4 rounded-[18px] border-o2o-gris-arena">
            <div className="bg-[#f1ece9] rounded-full w-[164px] h-[164px] flex items-center justify-center mr-0 md:mr-5 mb-5 md:mb-0">
                <img
                    loading="lazy"
                    srcSet={paciente?.perfilImg || `${imgUrl}/user.png`}
                    className="rounded-full shrink-0 max-w-full aspect-square w-[164px]"
                />
            </div>
            <div className="flex-1 mb-5 md:mb-0">
                <h2 className="text-xl font-semibold">{paciente.nombre} {paciente.apellidos}</h2>
                <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena border-solid"></div>
                <p>{paciente.edad} años</p>
                <p><strong>Enfermero con especialidad en:</strong><br />{paciente.necesidad?.especialidad.nombre}</p>
                <p><strong>Diagnóstico</strong><br />{paciente.diagnostico}</p>
                <p><strong>Condiciones especiales</strong><br />{paciente.condiciones_especiales}</p>
            </div>
            <div className="flex flex-col md:flex-row gap-2">
                <CustomButtonOval
                    type={'success'}
                    onClick={() => navigate(`/editar-paciente/${paciente.id}`)}
                    className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover'
                >
                    Editar perfil
                </CustomButtonOval>
                <CustomButtonOval
                    type={'success'}
                    onClick={() => navigate(`/baja-paciente/${paciente.id}`)}
                    className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover'
                >
                    Eliminar perfil
                </CustomButtonOval>
                {paciente.can.sendSolicitud &&
                <CustomButtonOval
                    type={'success'}
                    onClick={() => navigate(`/buscar-enfermero/paciente/${paciente.id}`)}
                    className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover'
                >
                    Busqueda de enfermero
                </CustomButtonOval>
                }
            </div>
        </div>

    );


    return (
        <div className="w-full px-4 ml-[19%] max-w-[75%]">
            <div className="flex justify-end mt-4">
                <CustomButtonOval
                    type={'success'}
                    onClick={() => navigate('/nuevo-paciente')}
                    className='bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover'
                >
                    Agregar Paciente
                </CustomButtonOval>
            </div>
            {pacientes.map((profile, index) => <ProfileCard key={index} paciente={profile} />)}
            {pacientes.length &&
                <Paginacion items={pacientes} filters={filters} setFilters={setFilters} meta={meta} />
            }
        </div>
    );
}

export default ListadoPacientes;
