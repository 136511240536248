import React from 'react'

const ToogleButton = ({ checked, onChange, label, name }) => {
    return (
        <label htmlFor={name} className="flex items-center cursor-pointer ">
            <div className="ml-3 text-gray-700 font-medium mr-4 ">{label}</div>
            <div className="relative">
                <input
                    type="checkbox"
                    id={name}
                    name={name}
                    className="sr-only"
                    checked={checked}
                    onChange={onChange}
                />
                <div className={`w-10 h-6 ${checked ? 'bg-blue-300' : 'bg-o2o-gris-arena'} rounded-full shadow-inner transition-colors duration-300`}></div>
                <div
                    className={`absolute w-6 h-6 bg-black rounded-full shadow inset-y-0 left-0 ${checked ? 'transform translate-x-full' : ''} text-sm text-center text-white self-center`}
                >{checked ? 'Si' : 'No'}</div>
            </div>
            
        </label>
    )
}

export default ToogleButton