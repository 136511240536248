import React, { useState } from 'react'

const AccordionItem = ({ title, content, index = '' }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className="border-b border-gray-200">
        <button
          className="flex justify-between items-center w-full px-4 py-3 text-left focus:outline-none"
          onClick={toggleAccordion}
        >
            <span className="text-2xl font-bold font-serif">
                <span className='bg-o2o-violeta px-4 py-1 rounded-full'>{index}</span> {title}
            </span>
            {isOpen ? (
                <span className="material-symbols-outlined">
                remove
                </span>
            ) : (
                <span className="material-symbols-outlined">
                add
                </span>
            )}
            </button>
            {isOpen && <div className="px-4 py-3">{content}</div>}
      </div>
    );
};

const CustomAcordion = ({items}) => {
    return (
        <div className="divide-y divide-gray-200">
            {items.map((item, index) => (
            <AccordionItem key={index} title={item.title} content={item.content} index={index+1} />
            ))}
        </div>
    )
}

export default CustomAcordion