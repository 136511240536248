import React, { useState } from 'react'

const CustomInput = ({ type, placeholder, error = '', ...props }) => {
  const ErrorMensaje = () => {
    if (error) {
      return (
        <span className="text-red-600">
          {error}
        </span>
      )
    }
    return
  }
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleMouseDown = () => {
    setIsPasswordVisible(true);
  };

  const handleMouseUp = () => {
    setIsPasswordVisible(false);
  };
  return (
    <div className="flex flex-col w-full py-1">
      <input
        type={isPasswordVisible ? "text" : type}
        placeholder={placeholder}
        className={`w-full py-1.5 px-2.5 rounded border focus:outline-none focus:border-o2o-aqua-hover bg-stone-100 text-ellipsis  ${error ? 'border-red-600' : 'border-gray-300'}`}
        {...props}
      />
      {type === "password" && (
        <button
          type="button"
          className="relative ml-[92%] w-10 inset-y-[-30px] justify-end px-3 flex items-center focus:outline-none"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {isPasswordVisible ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.522 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.478 0-8.268-2.943-9.542-7z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.229C2.629 9.696 1.666 11.73 1.458 12c1.274 4.057 5.064 7 9.542 7 1.787 0 3.482-.443 4.958-1.229m2.804-1.966C20.55 14.051 21.474 13.056 22 12c-1.274-4.057-5.064-7-9.542-7-1.1 0-2.162.178-3.162.508M9.878 9.878a3 3 0 104.243 4.243m-6.364 0L5.636 5.636m8.486 8.486L18.364 18.364" />
            </svg>
          )}
        </button>
      )}
      <ErrorMensaje />

    </div>
  )
}

export default CustomInput