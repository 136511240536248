import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const CustomTabsPerfiles = ({ defaultTab, tabs }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(defaultTab);

    const handleClick = (tab) => {
        if(tab.action){
            navigate(tab.action)
        }else{
            setActiveTab(tab.id);
        }
        
    };

    return (
        <div className="border-b border-gray-200">
  <div className="flex border-b-2 border-black flex-wrap max-w-full ml-0 sm:ml-[5%] md:ml-[10%] lg:ml-[15%] xl:ml-[19%]">
    {tabs.map((tab, index) => (
      <button
        disabled={tab.disabled}
        key={tab.id}
        className={`${
          activeTab === tab.id
            ? 'border-black text-black'
            : 'border-transparent text-gray-500 hover:text-gray-700 bg-o2o-gris-arena'
        } py-2 px-4 sm:px-6 block border-t-2 border-l-2 border-r-2 rounded-t-lg font-medium focus:outline-none mx-1 sm:mx-2`}
        onClick={() => handleClick(tab)}
      >
        {tab.label}
      </button>
    ))}
  </div>
  
  {tabs.map((tab) => (
    <div key={tab.id} className={`${activeTab === tab.id ? 'block' : 'hidden'}`}>
      {tab.content}
    </div>
  ))}
</div>

    )
}

export default CustomTabsPerfiles