import React, { useState, useEffect } from "react";
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import { cita_inicio, serv, serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import { Link } from "react-router-dom";
import { imgUrl } from "../../../helpers/recursosurl";
import { FormatCurrency } from "../../../helpers/CurencyFormat";
import Paginacion from "../../../components/Paginacion";
import QrServicios from "../../../components/QrServicios";
import { useNavigate } from "react-router-dom";
import { Title } from "../../../components/solicitudes/SolicitudElements";
import AceptarServicio from "./AceptarServicio";
import DeclinarServicio from "./DeclinarServicio";
import { getAuthUser } from "../../../helpers/configRoutes/backend_helper";
import QrScanner from "../../../components/QrScanner";
import ConfirmModal from "../../../components/modals/ConfirmDeleteModal";
import Calificaciones from '../../servicios/calificaciones/Calificaciones'
import QrModal from "./solicitudes/QrModal"
import { warningToast } from "../../../helpers/Toast";
import StarRating from "../../../components/CalificacionEstrellas";

const TabsListados = ({ estatus_id, setSolicitud, solicitud, component, setComponent }) => {
    const authUser = getAuthUser();
    const qrvalue = `url del back`;
    const navigate = useNavigate();
    const [filters, setFilters] = useState({ perPage: 5, page: 1, estatus_id: estatus_id })
    const [meta, setMeta] = useState({ total: 0, current_page: 0 })
    const [servicios, setServicios] = useState([])
    const [leerQr, setLeerQr] = useState({ show: false, cita_id: null, solicitud_id: null })
    const [urlQR, setUrlQr] = useState('')
    const [modalCita, setModalCita] = useState({ show: false, title: 'Inicio de turno', text: '¿Estas seguro de iniciar con el turno?' })
    useEffect(() => {
        const getServicio = async () => {
            const response = await serviciosResource('get', { params: filters });
            setServicios(response.servicios);
            setMeta(response.meta)
        }
        getServicio();
    }, [filters]);

    useEffect(() => {
        const getCita = async () => {
            setModalCita({ ...modalCita, show: true, url: urlQR });
        }
        console.log(urlQR, 'urlss')
        if (urlQR) {
            getCita();
        }
    }, [urlQR]);

    const postCita = async () => {
        setModalCita({ ...modalCita, loading: true, textCarga: 'Iniciando cita...' })
        let data = {}
        let response = { status: false, message: 'Error al consultarQR' }

        if (leerQr.manualToken) {
            console.log(leerQr)
            data = {
                servicio_id: leerQr.solicitud_id,
                cita_id: leerQr.cita_id,
                token: urlQR,
            }
            response = await cita_inicio(data, leerQr.solicitud_id, leerQr.cita_id, urlQR)
        } else {
            console.log(urlQR, 'urlssss')
            // Crear un objeto URL
            const parsedUrl = new URL(urlQR);
            // Obtener el pathname de la URL
            const pathname = parsedUrl.pathname;
            // Dividir el pathname en partes
            const pathParts = pathname.split('/').filter(part => part);
            // Asegúrate de que la URL tenga la cantidad esperada de parámetros
            if (pathParts.length === 4) {
                console.log(urlQR, 'qrUrl')
                const [, param0, param1, param2, param3] = pathParts;
                data = {
                    servicio_id: param0,
                    cita_id: param1,
                    token: param2,
                }
                response = await cita_inicio(data, param0, param1, param2)
            }

        }
        if (response.status) {
            setModalCita({ ...modalCita, show: false, loading: false })
            setFilters({ ...filters })
        } else {
            setModalCita({ ...modalCita, loading: false })
        }

    }
    return (
        <div className="flex flex-wrap max-w-full lg:max-w-[75%] ml-auto mr-auto px-4 lg:ml-[19%] lg:mr-[60px]">
            <Title estatus_id={estatus_id} serviciosCant={servicios.length} />
            {servicios.map((ser, index) => (
                <div key={index} className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full">
                    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-4">
                        <div className="text-base font-semibold">
                            Servicio de {ser.administrador.nombre} {ser.administrador.apellidos} para:
                        </div>
                        <p>{ser.folio}</p>
                        {estatus_id == 5 && <p>{ser.created_at}</p>}
                        <div className="flex space-x-2 mt-4 lg:mt-0">
                            {estatus_id == 5 ? (
                                <>
                                    <CustomButtonOval onClick={() => setComponent(<AceptarServicio setFilters={setFilters} filters={filters} servicio={ser} setComponent={setComponent} />)} className="bg-o2o-aqua-hover text-sm">Aceptar solicitud</CustomButtonOval>
                                    <CustomButtonOval onClick={() => setComponent(<DeclinarServicio setFilters={setFilters} filters={filters} servicio={ser} setComponent={setComponent} />)} className="bg--gris-arena-button text-sm hover:bg-gris-arena-hover">Cancelar solicitud</CustomButtonOval>
                                </>
                            ) : estatus_id == 9 ? (
                                <>
                                    <CustomButtonOval
                                        isDisabled={!ser.montoAfacturar}
                                        onClick={() => navigate(`/factura-enfermero/${ser?.id}`)}
                                        className="flex justify-center bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover"
                                    >
                                        Facturación
                                    </CustomButtonOval>
                                    <CustomButtonOval isDisabled={!ser.cita_activa?.can?.calificar} onClick={() => setComponent(<Calificaciones id={ser.cita_activa.id} servicio={ser} action={'Cita'} setComponent={setComponent} />)} className="bg-o2o-aqua-hover text-sm">{ser.cita_activa?.primer_cita ? 'Calificar primer día de servicio':'Calificar cita'}</CustomButtonOval>
                                    <CustomButtonOval className="bg-o2o-secondary-rojo text-sm hover:bg-o2o-secondary-rojo-hover" onClick={() => setComponent(<DeclinarServicio setFilters={setFilters} filters={filters} servicio={ser} setComponent={setComponent} />)}>Cancelar servicio</CustomButtonOval>
                                </>
                            ) : (
                                <>
                                    <CustomButtonOval
                                        isDisabled={!ser.montoAfacturar}
                                        onClick={() => navigate(`/factura-enfermero/${ser?.id}`)}
                                        className="flex justify-center bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover"
                                    >
                                        Facturación
                                    </CustomButtonOval>
                                    {ser.calificacion == 'n/a' ?
                                        <CustomButtonOval onClick={() => setComponent(<Calificaciones id={ser.id} servicio={ser} action={'Servicio'} setComponent={setComponent} />)} className="bg-o2o-aqua-hover text-sm">Pendiente de calificar</CustomButtonOval>
                                        :
                                        <CustomButtonOval className="bg-o2o-gris-calido-hover text-sm">
                                            Calificado
                                            <StarRating rating={ser.calificacion} color={'#FFA000'}  button={true} estatico={true} />
                                        </CustomButtonOval>
                                    }

                                    <CustomButtonOval className="bg--gris-arena-button text-sm">Servicio concluido</CustomButtonOval>
                                </>
                            )}
                        </div>
                    </div>

                    <hr className="border-gray-300 my-4" />
                    <div className="flex flex-col lg:flex-row justify-between">
                        <div className={`${estatus_id == 5 ? 'lg:w-1/2' : 'lg:w-full'} flex flex-col lg:flex-row lg:mr-4`}>
                            <div className="flex flex-col">
                                <div className="bg-[#f1ece9] rounded-full w-[72px] h-[72px] flex items-center justify-center mr-5 mb-5 lg:mb-0">
                                    <img
                                        loading="lazy"
                                        srcSet={ser.paciente?.perfilImg || `${imgUrl}/user.png`}
                                        className="rounded-full shrink-0 max-w-full aspect-square w-[164px]"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="text-sm font-semibold mb-3 flex items-center space-x-2">
                                    <p className="mr-2">{ser.paciente.nombre} {ser.paciente.apellidos}</p>
                                    <p>{ser.paciente.edad} años</p>
                                </div>
                                <div className="w-full mb-3 border-t border-o2o-gris-arena">
                                    <div className="text-sm mb-[18px]">{ser.paciente.diagnosticoM}</div>
                                    <div className="text-sm mb-2">Enfermero con especialidad en</div>
                                    <div>
                                        <button className="justify-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 bg-o2o-gris-arena-button text-sm mb-[18px]">{ser.enfermero?.especialidad}</button>
                                    </div>
                                    <div className="flex space-x-2">
                                        <Link className="justify-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 mb-2 bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover" to={`/paciente-solicitud/${ser.paciente.id}/${ser.id}`}>Ver perfil completo</Link>
                                        {estatus_id != 5 && estatus_id != 6 && (
                                            <CustomButtonOval className="flex justify-end bg--gris-arena-button text-sm hover:bg-gris-arena-hover">Reportar perfil</CustomButtonOval>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {estatus_id == 5 || estatus_id == 6 ? (
                            <div className="lg:w-1/2 flex flex-col items-center lg:items-start lg:mr-4 mb-4 lg:mb-0">
                                <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena">
                                    <div className="text-sm font-semibold">Condiciones especiales</div>
                                    <div className="text-sm">{ser.paciente.condicionEsp}</div>
                                </div>
                            </div>
                        ) : (
                            <div className="lg:w-1/2 flex flex-col items-center lg:items-start lg:mr-4 mb-4 lg:mb-0">
                                <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena">
                                    {authUser.perfil_id == 4 ? (
                                        <>
                                            <p>Fecha de tu proximo turno: {ser.cita_proxima?.fecha_inicial}</p>
                                            <div className="flex justify-center mt-10">
                                                <CustomButtonOval isDisabled={!ser.cita_proxima?.can?.startCita} onClick={() => [setUrlQr(false), setLeerQr({ show: true, cita_id: ser.cita_proxima.id, solicitud_id: ser.id })]} className="bg--gris-arena-button text-sm hover:bg-gris-arena-hover">Escanear QR</CustomButtonOval>
                                            </div>
                                            {/*leerQr && !urlQR && <QrScanner setUrl={setUrlQr} />*/}
                                        </>
                                    ) : (
                                        <QrServicios value={qrvalue} />
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="lg:w-1/2 flex flex-col items-center lg:items-start">
                            <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena">
                                <div className="text-sm font-semibold">Periodo del Servicio</div>
                                <div className="text-sm">
                                    <p>{ser.fecha_inicial} al {ser.fecha_fin}</p>
                                    <p>
                                        {ser.dias_names.map((d, index) => (
                                            <span key={index}>{d.dia}, </span>
                                        ))}
                                    </p>
                                    <p>Turno {ser.tiempo?.nombre} {ser.turno?.nombre}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="border-gray-300 my-4" />
                    <div className="flex flex-col lg:flex-row justify-between mt-4 space-y-4 lg:space-y-0 lg:space-x-4">
                        <div className="flex flex-col flex-grow items-center lg:items-start ml-[18px] mr-[12px]">
                            <div className="text-sm font">Costo total del servicio</div>
                            <div className="text-sm">{FormatCurrency(ser.pagos?.costo_total)}</div>
                        </div>
                        <div className="flex flex-col flex-grow items-center lg:items-start mr-[8px]">
                            <div className="text-sm font">Pago servicios One 2 One</div>
                            <div className="text-sm">{FormatCurrency(ser.pagos?.comision)}</div>
                        </div>
                        <div className="flex flex-col flex-grow items-center lg:items-start lg:mr-[18px]">
                            <div className="text-base font-semibold">Tus honorarios</div>
                            <div className="text-base font-semibold">{FormatCurrency(ser.pagos?.sin_comision)}</div>
                        </div>
                    </div>

                    {estatus_id == 9 && (
                        <div className="w-full rounded grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                            <div className="flex items-start">
                                <img src={`${imgUrl}/Frame 51.png`} alt="Alert Icon" className="h-[24px] w-[24px] object-contain" />
                            </div>
                            <div className="col-span-1 lg:col-span-2">
                                <p className="text-base">
                                    <strong>Calificar el primer día de servicio es un requisito disponible hasta 24 horas después de concluido el primer turno.</strong>
                                    Con tu retroalimentación podemos ajustar el servicio de acuerdo a tus necesidades. De no haber realizado la evaluación, el cliente y sus pacientes pierden las garantías que One 2 One ofrece para este contrato.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            ))}
            {servicios.length > 0 && (
                <div className="flex justify-center items-center h-full w-full mt-4">
                    <Paginacion items={servicios} filters={filters} setFilters={setFilters} meta={meta} />
                </div>
            )}
            {modalCita.show && (
                <ConfirmModal modal={modalCita} setModal={setModalCita} action={postCita} />
            )}
            {(leerQr.show && !urlQR) &&
                <QrModal modal={leerQr} setModal={setLeerQr} urlQR={urlQR} setUrlQr={setUrlQr} />
            }
        </div>


    )
}

export default TabsListados