import React, { useState, useEffect } from "react";
import TabsListados from "./TabsListados";

const MisServicios = ({ estatus_id }) => {
    const [component, setComponent] = useState(false)

    return (
        <>
            {component ?
                <>
                    {component}
                </>
                :
                <TabsListados estatus_id={estatus_id} setComponent={setComponent}/>
            }
        </>
    );
}

export default MisServicios;