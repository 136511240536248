import React,{useState} from 'react'
import CustomButtonOval from '../../components/buttons/CustomButtonOval';
import { Button, scroller } from 'react-scroll';
import { useRegistroStore } from '../../store/hooks/useRegistroStore';
import { warningToast } from '../../helpers/Toast';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { update_usuario } from "../../store/users/registro/action";
import { useNavigate } from 'react-router-dom';
import CancelarModal from "./CancelarModal";

const ContratosLegales = ({ item, setStep }) => {
    const [modal, setModal] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: item.contratos,
        validationSchema: Yup.object({
            prestacion: Yup.boolean()
                .oneOf([true], 'Prestación de Servicios, tiene que ser firmado para continuar'),
            codigo: Yup.boolean()
                .oneOf([true], 'Código de Conducta, tiene que ser firmado para continuar'),
            privacidad: Yup.boolean()
                .oneOf([true], 'Aviso de Privacidad, tiene que ser firmado para continuar'),
        }),
        onSubmit: async (values, { setErrors }) => {
            let newItem = { ...item, contratos: values }
            dispatch(update_usuario(newItem))
            window.scrollTo(0, 500);
            setStep(2)
        },
    });

    /*const validarCondiciones = () => {
        if (
            data?.contrato &&
            data?.codigo &&
            data?.privacidad
        ) {
            setStep(2);
            scroller.scrollTo("inicio", { duration: 1000, delay: 100, smooth: true })
        } else {
            warningToast('Por favor, acepte todos contratos legales.');
            scroller.scrollTo("inicio", { duration: 1000, delay: 100, smooth: true })
        }

    }*/

    const showFormErrors = () => {
        let errors = validation.errors;
        for (const key in errors) {
            if (errors[key]) {
                warningToast(errors[key]);
            }
        }
    };

    return (
        <>
            <div className="flex flex-wrap justify px-5 py-5 text-black rounded-2xl border border-solid border-stone-200 max-md:px-5 mt-10">
                <div className="w-full sm:w-1/3  justify-center p-3 text-sm leading-5">
                    <div className='rounded-xl border border-violet-700 border-solid px-3 py-3'>
                        <span className="font-bold">Última actualización: </span>07 de Julio de
                        2023
                    </div>
                </div>
                <div className="w-full sm:w-2/3 text-base leading-6">
                    <div className="text-3xl leading-9 max-md:max-w-full">
                        Contrato de Prestación de Servicios
                    </div>
                    <div className="max-md:max-w-full font-normal">
                        Contrato de Regulación de Servicios y Responsabilidades celebrado
                        entre:
                        <br />
                        One to One Nurses con Nombre del cliente
                    </div>
                    <div className="mt-8 max-md:max-w-full">
                        <span className="font-bold">REUNIDOS:</span>
                        <br />
                        De una parte,
                        <br />
                        <span className="font-bold">Nombre del Cliente</span>
                        <br />
                        Privada de la 23 sur #3701 B-103. Ex-Hacienda La Noria. C.P. 72410.
                        Puebla, México.
                        <br />
                        Que se denominará en el presente contrato a partir de ahora:{" "}
                        <span className="font-bold">EL PROVEEDOR</span>
                        <br />
                        <br />
                        De la otra,
                        <br />
                        <span className="font-bold">
                            One to One Nurses. Enfermería Online S.A. de C.V.
                        </span>
                        <br />
                        Como [Relación contractual como autónomo] En adelante:{" "}
                        <span className="font-bold">EL CONTRATANTE</span>
                        <br />
                        <br />
                        EXPONEN celebrar el siguiente contrato de servicios, dejando de
                        manifiesto que:
                        <br />
                        <ol className='list-decimal p-6'>
                            <li>
                                Que EL CLIENTE es una persona física que desarrolla su actividad
                                en el sector{" "}
                            </li>
                            <li>
                                Que EL CLIENTE está interesado en dar a conocer de forma adecuada
                                sus servicios, y a estos efectos desea contratar la realización de
                                determinados servicios digitales a desarrollar por parte de EL
                                PROVEEDOR, tanto en relación con la marca EL CLIENTE como con
                                otros productos y servicios desarrollados por EL CLIENTE.
                            </li>
                            <li>
                                Que EL PROVEEDOR demuestra amplia experiencia en el sector del
                                MARKETING DIGITAL interesado en prestar sus servicios
                                profesionales para el desarrollo de las campañas de publicidad en
                                internet que EL CLIENTE le encomiende.
                            </li>
                        </ol>
                        <br />
                        Ambas partes al objeto de alcanzar los objetivos y finalidades
                        anteriormente expresados, reconociéndose mutua y recíprocamente plena
                        capacidad para contratar, acuerdan suscribir el presente contrato de
                        marketing online para prestar servicios publicitarios en internet, con
                        sujeción a los pactos y condiciones establecidos en las siguientes.
                    </div>
                </div>

            </div>
            <div className="flex flex-wrap gap-5 justify-center p-5 text-black rounded-xl shadow-sm bg-stone-100 mt-10 mb-10">
                <div className="flex-auto text-base font-bold leading-6 text-right max-md:max-w-full">
                    He leído, acepto y doy mi consentimiento expreso de los términos
                    descritos.
                </div>
                {!validation.values?.prestacion && (
                    <CustomButtonOval
                        className={'bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm'}
                        onClick={() => validation.setFieldValue('prestacion', true)}
                        type='button'
                    >
                        Firmar el Contrato de Prestación de Servicios
                    </CustomButtonOval>
                )}

            </div>
            {/* ---------------------------------------------------------------------- */}
            <div className="flex flex-wrap px-5 py-5 text-black rounded-2xl border border-solid border-stone-200 max-md:px-5">
                <div className="w-full sm:w-1/3  justify-center p-3 text-sm leading-5">
                    <div className='rounded-xl border border-violet-700 border-solid px-3 py-3'>
                        <span className="font-bold">Última actualización: </span>07 de Julio de
                        2023
                    </div>
                </div>
                <div className="w-full sm:w-2/3 text-base leading-6">
                    <div className="text-3xl leading-9 max-md:max-w-full">
                        Código de Conducta
                    </div>
                    <div className="max-md:max-w-full">
                        Contrato de Regulación de Servicios y Responsabilidades celebrado
                        entre:
                        <br />
                        One to One Nurses con Nombre del cliente
                    </div>
                    <div className="mt-8 max-md:max-w-full">
                        <span className="font-bold">REUNIDOS:</span>
                        <br />
                        De una parte,
                        <br />
                        <span className="font-bold">Nombre del Cliente</span>
                        <br />
                        Privada de la 23 sur #3701 B-103. Ex-Hacienda La Noria. C.P. 72410.
                        Puebla, México.
                        <br />
                        Que se denominará en el presente contrato a partir de ahora:{" "}
                        <span className="font-bold">EL PROVEEDOR</span>
                        <br />
                        <br />
                        De la otra,
                        <br />
                        <span className="font-bold">
                            One to One Nurses. Enfermería Online S.A. de C.V.
                        </span>
                        <br />
                        Como [Relación contractual como autónomo] En adelante:{" "}
                        <span className="font-bold">EL CONTRATANTE</span>
                        <br />
                        <br />
                        EXPONEN celebrar el siguiente contrato de servicios, dejando de
                        manifiesto que:
                        <br />
                        <ol className='list-decimal p-6'>
                            <li>
                                Que EL CLIENTE es una persona física que desarrolla su actividad
                                en el sector{" "}
                            </li>
                            <li>
                                Que EL CLIENTE está interesado en dar a conocer de forma adecuada
                                sus servicios, y a estos efectos desea contratar la realización de
                                determinados servicios digitales a desarrollar por parte de EL
                                PROVEEDOR, tanto en relación con la marca EL CLIENTE como con
                                otros productos y servicios desarrollados por EL CLIENTE.
                            </li>
                            <li>
                                Que EL PROVEEDOR demuestra amplia experiencia en el sector del
                                MARKETING DIGITAL interesado en prestar sus servicios
                                profesionales para el desarrollo de las campañas de publicidad en
                                internet que EL CLIENTE le encomiende.
                            </li>
                        </ol>
                        <br />
                        Ambas partes al objeto de alcanzar los objetivos y finalidades
                        anteriormente expresados, reconociéndose mutua y recíprocamente plena
                        capacidad para contratar, acuerdan suscribir el presente contrato de
                        marketing online para prestar servicios publicitarios en internet, con
                        sujeción a los pactos y condiciones establecidos en las siguientes.
                    </div>
                </div>

            </div>
            <div className="flex flex-wrap gap-5 justify-center p-5 text-black rounded-xl shadow-sm bg-stone-100 mt-10 mb-10">
                <div className="flex-auto text-base font-bold leading-6 text-right max-md:max-w-full">
                    He leído, acepto y doy mi consentimiento expreso de los términos
                    descritos.
                </div>
                {!validation.values?.codigo && (
                    <CustomButtonOval
                        className={'bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm'}
                        onClick={() => validation.setFieldValue('codigo', true)}
                        type='button'
                    >
                        Firmar el Código de Conducta
                    </CustomButtonOval>
                )}

            </div>
            {/* ----------------------------------------------------------------------------------------------------------------------- */}

            <div className="flex flex-wrap px-5 py-5 text-black rounded-2xl border border-solid border-stone-200 max-md:px-5">
                <div className="w-full sm:w-1/3  justify-center p-3 text-sm leading-5 ">
                    <div className='rounded-xl border border-violet-700 border-solid px-3 py-3'>
                        <span className="font-bold">Última actualización: </span>07 de Julio de
                        2023
                    </div>
                </div>
                <div className="w-full sm:w-2/3 text-base leading-6">
                    <div className="text-3xl leading-9 max-md:max-w-full">
                        Aviso de Privacidad
                    </div>
                    <div className="max-md:max-w-full">
                        Contrato de Regulación de Servicios y Responsabilidades celebrado
                        entre:
                        <br />
                        One to One Nurses con Nombre del cliente
                    </div>
                    <div className="mt-8 max-md:max-w-full">
                        Proveedora de Productos y Servicios Roxima SA de CV con domicilio en
                        avenida Constituyentes, número 161, piso 4, colonia San Miguel
                        Chapultepec II sección, demarcación territorial Miguel Hidalgo,
                        México, código postal 11850, en la Ciudad de México, es el sujeto
                        obligado y responsable del tratamiento de los datos personales que se
                        recaban de forma general a través de one2onenurses.mx, los cuales
                        serán protegidos conforme a lo dispuesto por la Ley General de
                        Protección de Datos Personales en Posesión de Sujetos Obligados, y
                        demás normatividad que resulte aplicable; dichos datos son los
                        listados en el apartado siguiente. Los datos personales recolectados
                        por las dependencias y entidades de la Administración Pública Federal,
                        y empresas productivas del Estado, los cuales consolidan los sitios,
                        micrositios y redes sociales a través de los portales institucionales
                        alojados dentros de one2onenurses.mx, o aquellos datos recabados por
                        cualquier otro sujeto obligado que utilice one2onenurses.mx para la
                        prestación de algún servicio digital, serán tratados bajo su
                        responsabilidad, conforme a sus atribuciones legales y el aviso de
                        privacidad correspondiente.
                        <br></br>
                        <br></br>
                        <span className="font-bold">
                            Datos personales que se recolectan en one2onenurses.mx y la
                            finalidad del tratamiento
                        </span>
                        <br></br>
                        El único dato personal que se recaba a través de one2onenurses.mx, es:
                        correo electrónico. one2onenurses.mx no está condicionado a
                        proporcionar el correo electrónico, este dato personal se recopila
                        como dato opcional, para que el usuario reciba mayor y mejor
                        información. Se informa que no se recabarán datos personales
                        sensibles. Serán utilizados para las siguiente finalidades: Única:
                        hacer llegar el boletín de one2onenurses.mx.
                        <br></br><br></br>
                        <span className="font-bold">Uso de Cookies</span><br />
                        one2onenurses.mx
                        utiliza cookies para ayudar a obtener información que nos permite
                        mejorar la experiencia de usuario y brindar un mejor servicio. En
                        cualquier momento, dichas cookies pueden ser eliminadas accediendo a
                        las preferencias de configuración del navegador en uso.
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap gap-5 justify-center p-5 text-black rounded-xl shadow-sm bg-stone-100 mb-10 mt-10">
                <div className="flex-auto text-base font-bold leading-6 text-right max-md:max-w-full">
                    He leído, acepto y doy mi consentimiento expreso de los términos
                    descritos.
                </div>
                {!validation.values?.privacidad && (
                    <CustomButtonOval
                        className={'bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm'}
                        type='button'
                        onClick={
                            () => validation.setFieldValue('privacidad', true)
                        }
                    >
                        Firmar el Aviso de Privacidad
                    </CustomButtonOval>
                )}
            </div>
            <form onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                showFormErrors()
                return false;
            }}
            >
                <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10 ">
                    <button
                        className="grow justify-center items-center px-16 py-5 shadow-sm bg-stone-100 rounded-[36px] w-fit max-md:px-5 max-md:max-w-full hover:bg-o2o-gris-hover"
                        type='button'
                        onClick={() => {
                            setModal(true);
                        }}
                    >
                        Cancelar registro
                    </button>
                    <button className="grow justify-center px-24 py-5 bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover" type="submit">
                        Guardar información y continuar
                    </button>
                </div>
            </form>
            <CancelarModal modal={modal} setModal={setModal}/>
        </>
    )
}

export default ContratosLegales;