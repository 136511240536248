import {
  SET_CONTADOR,
} from "./actionTypes"

export const setContador = data => {
  return {
    type: SET_CONTADOR,
    payload: data,
  }
}
