import React, { useState } from "react";
import PropTypes from 'prop-types';
import Divider from "../Divider";
import CustomInput from "../inputs/CustomInput";
import CustomInputMaskPerson from "../../components/inputs/CustomInputMaskPerson"

const InformacionCuenta = ({ validation }) => {
    
    return (
        <>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] max-lg:ml-0">
                <div className="mt-12 w-full text-xl font-bold text-black max-md:max-w-full">
                    Información de la cuenta
                </div>
                <div className='w-full'>
                    <Divider />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Nombre(s)
                    </div>
                    <CustomInput
                        name="nombre"
                        value={validation.values.nombre || ''}
                        error={validation.errors?.nombre}
                        placeholder={'Nombre(s)'}
                        type={'text'}
                        onChange={validation.handleChange}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Apellidos
                    </div>
                    <CustomInput
                        name="apellidos"
                        value={validation.values.apellidos || ''}
                        error={validation.errors?.apellidos}
                        placeholder={'Apellidos'}
                        type={'text'}
                        onChange={validation.handleChange}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Fecha de nacimiento
                    </div>
                    <CustomInput
                        name="fechaNacimiento"
                        value={validation.values.fechaNacimiento || ''}
                        error={validation.errors?.fechaNacimiento}
                        type={'date'}
                        onChange={validation.handleChange}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Móvil
                    </div>
                    <CustomInput
                        name="movil"
                        placeholder={'1234567890'}
                        value={validation.values.movil || ''}
                        error={validation.errors?.movil}
                        type={'text'}
                        onChange={(e) => validation.setFieldValue('movil',parseInt(e.target.value))}
                        onBlur={validation.handleBlur}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Teléfono
                    </div>
                    <CustomInput
                        name="telefono"
                        placeholder={'1234567890'}
                        value={validation.values.telefono || ''}
                        error={validation.errors?.telefono}
                        type={'text'}
                        onChange={(e) => validation.setFieldValue('telefono',parseInt(e.target.value))}
                        onBlur={validation.handleBlur}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        E-mail
                    </div>
                    <CustomInput
                        name="email"
                        placeholder={'example@mail.com'}
                        value={validation.values.email || ''}
                        error={validation.errors?.email}
                        type={'email'}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-3">
                        Contraseña
                    </div>
                    <CustomInput
                        name="contrasena"
                        placeholder={'*******'}
                        value={validation.values.contrasena || ''}
                        error={validation.errors?.contrasena}
                        type={'password'}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-3">
                        Confirmar contraseña
                    </div>
                    <CustomInput
                        name="confirmacionContrasena"
                        placeholder={'*******'}
                        value={validation.values.confirmacionContrasena || ''}
                        error={validation.errors?.confirmacionContrasena}
                        type={'password'}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                    />
                </div>
                <div className="w-full mb-3">
                    <div className="justify-center items-center w-6 h-6 text-base font-bold leading-6 text-center text-white whitespace-nowrap bg-rose-600 rounded-2xl shadow-sm">
                        !
                    </div>
                    <div className="flex-auto my-auto text-sm leading-4 text-black max-md:max-w-full">
                        La información de creación de cuenta tomará en automático algunos
                        datos para popular el perfil de cliente y/o administrador.
                    </div>
                </div>
            </div>

        </>
    )
}

InformacionCuenta.propTypes = {
    validation: PropTypes.object
}

export default InformacionCuenta;