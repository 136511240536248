import React from "react";
import InputMask from 'react-input-mask';

const CustomInputMaskPerson = ({ type, placeholder,mask, error='', ...props }) => {
    const ErrorMensaje = () => {
        if(error){
            return (
                <span className="text-red-600">
                    {error}
                </span>
            )
        }
        return
      }

    return (
        <>
            <div className="flex flex-col w-full py-1">
                <InputMask
                    mask={mask}
                    placeholder={placeholder}
                    className={`w-full py-1.5 px-2.5 rounded border focus:outline-none focus:border-o2o-aqua-hover bg-stone-100 text-ellipsis  ${error ? 'border-red-600' : 'border-gray-300'}`}
                    {...props}
                />
                <ErrorMensaje />
            </div>
        </>
    )
}

export default CustomInputMaskPerson;
