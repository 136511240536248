import { imgUrl } from "../helpers/recursosurl";
import Divider from "./Divider";

export const InfoBlock = ({ imgSrc, imgAlt, title, text }) => (
    <section className="flex flex-col md:w-full px-2">
      <div className="flex flex-col grow text-black mt-5">
        {imgSrc && <img src={`${imgUrl}/${imgSrc}`} alt={imgAlt} loading="lazy" className="max-w-full rounded-full aspect-square w-[105px]" />}
        <h3 className="mt-6 text-xl font-bold">{title}</h3>
        <p className="mt-3 text-sm leading-5">{text}</p>
      </div>
      {/* <Divider/> */}
    </section>
);