import React, { useEffect, useState } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { getCotizacion } from "../../../helpers/configRoutes/backend_helper"
import { FormatCurrency } from '../../../helpers/CurencyFormat'


const ConfirmacionSolicitudModal = ({ setModal, modal, solicitudServicio }) => {
    const [costoTotal, setCostoTotal] = useState('')
    useEffect(() => {
        const cotizacion = async () => {
            const response = await getCotizacion({ enfermero_id: modal?.id_enfermero, paciente_id: modal?.paciente_id });
            if (response.status) {
                setCostoTotal(FormatCurrency(response.costoTotal))
            }
        }
        if (modal?.id_enfermero && modal?.paciente_id) {
            cotizacion()
        }
    }, []);
    return (

        <>
            {modal.show ? (
                <>
                    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                        <div className="relative w-[90%] sm:w-[70%] md:w-[50%] lg:w-[34%] max-w-3xl mx-auto my-6">
                            {/* Contenido del modal */}
                            <div className="relative flex flex-col w-full bg-o2o-gris-arena border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                                {/* Encabezado del modal */}
                                <div className="flex items-start justify-between p-1">
                                    <div className="absolute top-[-28px] left-1/2 transform -translate-x-1/2 bg-white rounded-full p-2 shadow-md">
                                        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="23.5" cy="23.5" r="23" fill="white" stroke="#EBE1DC" />
                                            <path d="M23.5055 25.176C23.2495 25.176 23.0335 25.088 22.8575 24.912C22.6815 24.72 22.5935 24.496 22.5935 24.24C22.5775 23.312 22.5215 22.464 22.4255 21.696C22.3295 20.912 22.2255 20.16 22.1135 19.44C22.0015 18.72 21.8975 18 21.8015 17.28C21.7215 16.56 21.6815 15.784 21.6815 14.952C21.6815 14.44 21.8575 14.016 22.2095 13.68C22.5775 13.328 23.0175 13.152 23.5295 13.152C24.0255 13.152 24.4495 13.328 24.8015 13.68C25.1695 14.016 25.3535 14.44 25.3535 14.952C25.3535 15.768 25.3055 16.536 25.2095 17.256C25.1135 17.976 25.0095 18.696 24.8975 19.416C24.7855 20.136 24.6735 20.888 24.5615 21.672C24.4655 22.456 24.4175 23.312 24.4175 24.24C24.4175 24.496 24.3295 24.72 24.1535 24.912C23.9775 25.088 23.7615 25.176 23.5055 25.176ZM25.4015 28.392C25.4015 28.92 25.2175 29.368 24.8495 29.736C24.4815 30.104 24.0335 30.288 23.5055 30.288C22.9775 30.288 22.5295 30.104 22.1615 29.736C21.7935 29.368 21.6095 28.92 21.6095 28.392C21.6095 27.88 21.7935 27.44 22.1615 27.072C22.5295 26.688 22.9775 26.496 23.5055 26.496C24.0335 26.496 24.4815 26.688 24.8495 27.072C25.2175 27.44 25.4015 27.88 25.4015 28.392Z" fill="#EBE1DC" />
                                        </svg>
                                    </div>
                                </div>
                                {/* Cuerpo del modal */}
                                <div className="relative flex-auto p-1 mt-5">
                                    <p className="my-2 text-base text-center font-bold leading-relaxed">
                                        ¿Estás seguro de enviar la solicitud de servicio?
                                    </p>
                                    <p className="my-2 text-base text-center font-bold leading-relaxed">
                                        El costo total del servicio será: {costoTotal}
                                    </p>
                                </div>
                                {/* Pie del modal */}
                                <div className="flex items-center justify-center p-6 rounded-b border-slate-200">


                                    <CustomButtonOval
                                        loading={modal.loading}
                                        textCarga={'Guardando servicio'}
                                        type={'success'}
                                        onClick={() => [solicitudServicio(modal?.id_enfermero)]}
                                        className='text-sm bg-o2o-aqua hover:bg-o2o-aqua-hover w-full lg:w-[30%]'
                                    >
                                        Mandar Solicitud
                                    </CustomButtonOval>
                                    <CustomButtonOval
                                        isDisabled={modal.loading}
                                        textCarga={'Cerrar'}
                                        type={'success'}
                                        onClick={() => setModal({ show: false, id: null })}
                                        className=''
                                    >
                                        Cerrar
                                    </CustomButtonOval>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="fixed inset-0 z-40 bg-white opacity-[90%]"></div>

                </>
            ) : null}
        </>
    )
}

export default ConfirmacionSolicitudModal;