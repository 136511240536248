import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { enfermeroResource, favoritos, getAuthUser, serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import { imgUrl } from "../../../helpers/recursosurl";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { useNavigate } from "react-router-dom";
import ConfirmacionSolicitudModal from "../../administrador/historial_servicios/ConfirmacionSolicitudModal";
import { useDispatch } from "react-redux";
import { back_resultados } from "../../../store/busquedas/action";

const PerfilDetalladoEnfermero = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id, id_paciente } = useParams()
    const [enfermero, setEnfermero] = useState({})
    const usuario = getAuthUser()
    const [authUser, setAuthUser] = useState(getAuthUser())
    const [modal, setModal] = useState({ show: false, id_enfermero: null, paciente_id: null, loading: false });

    useEffect(() => {
        const getDataEnfermero = async () => {
            if (id) {
                const response = await enfermeroResource('show', {}, id)
                if (response.status) {
                    setEnfermero(response.enfermero)
                }
            }
        }
        getDataEnfermero()
    }, []);


    const sendFavorito = async (id_enfermero) => {
        const response = await favoritos({ id_enfermero: id_enfermero });
        if (response.status) {
            let storedData = localStorage.getItem('authUser');
            console.log(storedData, '1')
            if (storedData) {
                console.log(storedData, '2')
                let userData = JSON.parse(storedData);
                userData.user = response.administrador
                localStorage.setItem('authUser', JSON.stringify(userData));
                setAuthUser(getAuthUser())
            }
        }
    }

    const solicitudServicio = async (id_enfermero) => {
        setModal({ ...modal, loading: true })
        const response = await serviciosResource('post', { enfermero_id: enfermero.id, paciente_id: id_paciente, device_session_id: deviceSessionId });
        if (response.status) {
            setModal({ ...modal, loading: false })
            navigate('/servicios')
        }else{
            if(response.action == 'add_payment'){
                navigate('/construccion')
            }
        }
        setModal({ ...modal, loading: false })
    }

    const [deviceSessionId, setDeviceSessionId] = useState('');


    const back_resultadosGet = () => {
        dispatch(back_resultados(true))
        navigate(`/buscar-enfermero/paciente/${id_paciente}`)
    }

    return (
        <>
            <div className="flex flex-wrap max-w-[85%] mt-[144px] ml-[10%] mr-auto">
                <div className="relative w-full h-64 bg-gray-100 flex items-center">
                    <img
                        src={`${imgUrl}/cuidadosEnfermeros.png`}
                        alt="Header"
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                    <div className="relative z-10 p-6 mx-auto md:ml-[59px] md:mr-[632px] text-center md:text-left">
                        <h2 className="text-[24px] md:text-[32px] font-bold text-black">Cuidados enfermeros con el mismo amor que tú sientes</h2>
                        <p className="text-sm md:text-base text-black mt-2">Nuestros profesionistas son expertos de la salud con un gran corazón.</p>
                    </div>
                </div>
            </div>

            <div className="w-full rounded-none max-w-[96%] mr-auto ml-auto">
                <div className="flex flex-col mt-[60px] lg:flex-row">
                    <div className="w-full md:w-1/5 mt-[60px] lg:mt-[290px] flex flex-col items-center">
                        <div className="flex justify-between items-center w-full px-6 md:px-[120px]">
                            <span className="text-gray-600 flex items-center">
                                <svg
                                    className="w-6 h-6 text-o2o-secondary-mostaza ms-1"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 20"
                                >
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                </svg>
                                &nbsp;&nbsp;{enfermero.enfermero?.calificacion}
                            </span>
                        </div>



                        <div className="flex flex-col items-center mt-4">
                            <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => sendFavorito(id)}
                            >
                                <circle cx="20" cy="20" r="19.5" fill="white" stroke="#EBE1DC" />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M20.2727 18.0256C20.2727 15.3333 18.1605 13 15.6963 13C13.2321 13 12 14.9744 12 16.7692C12 22.1538 18.5126 26.1026 20.2727 27C22.0329 26.1026 28.5455 22.1538 28.5455 16.7692C28.5455 14.9744 27.3133 13 24.8491 13C22.3849 13 20.2727 15.3333 20.2727 18.0256Z"
                                    fill={`${authUser.favoritos?.filter((res) => res?.enfermero_id == id).length > 0
                                        ? '#FF0041'
                                        : '#EBE1DC'
                                        }`}
                                />
                            </svg>
                            <p className="text-sm text-center">Añadir a favoritos</p>
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-4">
                                <circle cx="25" cy="25" r="24.5" fill="white" stroke="#EBE1DC" />
                                <path d="M18 26L22.6552 30L33 18" stroke="#EBE1DC" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                            <p className="text-sm text-center">Recomendado por muchos</p>
                        </div>

                    </div>

                    <div className="w-full md:w-4/5">
    <div className="flex justify-between items-center mb-4">
        <CustomButtonOval className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover" onClick={() => (id_paciente ? back_resultadosGet():navigate('/servicios'))}>
            <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.80078 1L1.00078 5L5.80078 9"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            &nbsp;&nbsp;{id_paciente ? 'Regresar a los resultados':'Regresar a servicios'}
        </CustomButtonOval>
    </div>

    <hr className="border-t border-gray-300 w-full mb-2" />

    <div className="flex w-full justify-center">
        <div className="bg-[#f1ece9] rounded-full w-40 h-40 flex items-center justify-center mt-10 mb-4">
            <img
                loading="lazy"
                srcSet={enfermero?.perfilImg || `${imgUrl}/user.png`}
                className="rounded-full shrink-0 max-w-full aspect-square w-40"
            />
        </div>
    </div>
    {id_paciente &&
        <div className="flex items-center bg-white border-b border-gray-200 py-4">
            <h3>{enfermero?.nombre} {enfermero?.apellidos}</h3>
            <div className="ml-auto">
                <CustomButtonOval className="bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover ml-4 mb-4" onClick={() => setModal({ show: true, id_enfermero: id, paciente_id: id_paciente })}>
                    Solicitar servicio
                </CustomButtonOval>
            </div>
        </div>
    }
    <div className="flex flex-col lg:flex-row mt-4 gap-4">
        <div className="flex flex-col w-full lg:w-1/2">
            <div className="text-base font-semibold mt-4 mr-0">
                <h3>{enfermero.acreditaciones?.estudios_nombre} en Enfermería</h3>
            </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/2">
            <div className="text-base font-semibold mt-4 mr-0">
                <h3>{enfermero.edad} años</h3>
            </div>
        </div>
    </div>

    <div className="w-full max-w-md mt-4">
        <div className="text-base mt-1">
            Cédula {enfermero?.acreditaciones?.cedula_profesional}
        </div>
        <div className="text-base mt-4">
            {enfermero?.acreditaciones?.descripcion_profesional}
        </div>
    </div>

    <div className="flex flex-col lg:flex-row mt-4 gap-4">
        <div className="flex flex-col">
            <div className="text-base font-semibold mt-4 mr-0">
                Datos de contacto
            </div>
            <div className="text-base mt-1 mr-0">
                Información visible una vez que la solicitud de servicio sea aprobada
            </div>
        </div>
        <div className="flex flex-col">
            <div className="text-base font-semibold mt-4 mr-0">
                Domicilio
            </div>
            <div className="text-base mt-1 mr-0">
                Información visible una vez que la solicitud de servicio sea aprobada
            </div>
        </div>
    </div>

    <div className="flex flex-col mt-4 w-full">
        <div className="text-base font-semibold mt-1 mr-0">
            Intereses personales
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
            {enfermero?.interesesPersonales?.map((interes, index) => (
                <CustomButtonOval
                    key={index}
                    className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50"
                >
                    {interes.nombre}
                </CustomButtonOval>
            ))}
        </div>
    </div>

    <div className="flex flex-col lg:flex-row mt-4 gap-4 w-full">
        <div className="flex flex-col w-full lg:w-1/2">
            <div className="text-base font-semibold mt-4 mr-0">
                Descriptivo personal
            </div>
            <div className="text-base mt-1 mr-0">
                {enfermero?.descripcionPersonal}
            </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/2">
            <div className="text-base font-semibold mt-4 mr-0">
                Me hace único
            </div>
            <div className="text-base mt-1 mr-0">
                {enfermero?.informacionUnica}
            </div>
        </div>
    </div>

    <div className="flex items-center font-semibold mt-4">
        <h3>Servicios de enfermería</h3>
    </div>
    <hr className="border-t border-gray-300 w-full mb-2" />

    <div className="flex flex-col mt-4 w-full">
        <div className="text-base font-semibold mt-1 mr-0">
            Áreas de la salud en las que se especializa
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
            {enfermero?.acreditaciones?.formacion_especialidades?.map((interes, index) => (
                <CustomButtonOval
                    key={index}
                    className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50"
                >
                    {interes.especialidad_nombre}
                </CustomButtonOval>
            ))}
        </div>
    </div>

    <div className="flex flex-col mt-4 w-full">
        <div className="text-base font-semibold mt-1 mr-0">
            Perfil de paciente con mayor experiencia
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
            {enfermero?.acreditaciones?.servicios?.map((interes, index) => (
                <CustomButtonOval
                    key={index}
                    className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50"
                >
                    {interes.nombre}
                </CustomButtonOval>
            ))}
        </div>
    </div>

    <div className="flex flex-col mt-4 w-full">
        <div className="text-base font-semibold mt-1 mr-0">
            Habilidades profesionales
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
            {enfermero?.acreditaciones?.habilidades?.map((interes, index) => (
                <CustomButtonOval
                    key={index}
                    className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50"
                >
                    {interes.nombre}
                </CustomButtonOval>
            ))}
        </div>
    </div>

    <div className="flex items-center font-semibold mt-4">
        <h3>Formación & Experiencia profesional</h3>
    </div>
    <hr className="border-t border-gray-300 w-full mb-2" />

    <div className="flex flex-col mt-4 w-full">
        <div className="text-base font-semibold mt-1 mr-0">
            Formación académica
        </div>
        <div className="flex flex-wrap gap-2 w-full mt-2">
            {enfermero?.acreditaciones?.formacion_academica?.map((interes, index) => (
                <div key={index} className="flex flex-col mt-4 w-full">
                    <div className="flex text-base gap-4">
                        <p>{interes.fecha_inicio} - {interes.fecha_fin}</p>
                        <p className="text-base font-semibold">{interes.formacion}</p>
                        <p>{interes.institucion}</p>
                    </div>
                    <hr className="border-t border-gray-300 w-full mb-2" />
                </div>
            ))}
        </div>
    </div>

    <div className="flex flex-col mt-4 w-full">
        <div className="text-base font-semibold mt-1 mr-0">
            Experiencia laboral
        </div>
        <div className="flex flex-wrap gap-2 w-full mt-2">
            {enfermero?.acreditaciones?.experiencias_laborales?.map((interes, index) => (
                <div key={index} className="flex flex-col mt-4 w-full">
                    <div className="flex text-base gap-4">
                        <p>{interes.fecha_inicio} - {interes.fecha_fin}</p>
                        <p className="text-base font-semibold">Lugar</p>
                        <p>{interes.cargo}</p>
                        <p>{interes.actividades}</p>
                    </div>
                    <hr className="border-t border-gray-300 w-full mb-2" />
                </div>
            ))}
        </div>
    </div>

    <div className="flex flex-col mt-4 w-full">
        <h3 className="text-xl font-semibold mt-1 mr-0">
            Testimoniales
        </h3>
        <hr className="border-t border-gray-300 w-full mb-2" />
    </div>

    <div className="flex flex-col mt-4 w-full">
        <h3 className="text-xl font-semibold mt-1 mr-0">
            Insignias
        </h3>
        <hr className="border-t border-gray-300 w-full mb-2" />
    </div>

    <div className="flex flex-col items-center justify-center w-full">
        <h2 className="text-xl font-semibold mt-1 mr-0">
            ¿Requieres ayuda o más información?
        </h2>
        <p className="text-sm mt-2">
            Consulta nuestros protocolos de seguridad para ti y nuestros profesionistas
        </p>
        <div className="flex justify-center gap-4 mt-6 mb-4">
            <CustomButtonOval
                className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover"
                onClick={() => window.open('https://escueladeenfermeriangelopolis.zendesk.com/hc/es-419/articles/30663420807827-Contrato-de-Prestaci%C3%B3n-de-Servicios', '_blank', 'noopener,noreferrer')}
            >
                Conoce los términos y condiciones
            </CustomButtonOval>
            <CustomButtonOval className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover"
                onClick={() => window.open('https://escueladeenfermeriangelopolis.zendesk.com/hc/es-419/articles/30664415940755-C%C3%B3digo-de-Conducta-Cliente', '_blank', 'noopener,noreferrer')}
            >
                Lee nuestro código de conducta
            </CustomButtonOval>
        </div>
    </div>
</div>

                </div>
            </div>
            {modal.show &&
                <ConfirmacionSolicitudModal modal={modal} setModal={setModal} solicitudServicio={solicitudServicio} />
            }
        </>
    );
}
export default PerfilDetalladoEnfermero;