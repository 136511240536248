import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { isUserAuthenticated } from '../helpers/configRoutes/backend_helper';
import Menu from '../components/menuNav/Menu';
import { logoutUserSuccess } from '../store/auth/login/action';
import { useDispatch } from 'react-redux';

export const PrivateRoute = ({ element, menu }) => {
    const isAuth = isUserAuthenticated();
    return isAuth ? (
        <>
            {menu==1 &&
                <Menu />
            }

            {element}

        </>
    ) : <Navigate to="/login" />;
};

export const LogoutRoute = ({ element }) => {
    const dispatch = useDispatch();
    const isAuth = isUserAuthenticated();

    useEffect(() => {
        if (isAuth) {
            dispatch(logoutUserSuccess());
        }
    }, [isAuth, dispatch]);

    return !isAuth ? (
        <Navigate to="/login" />
    ) : (
        <Navigate to="/login" />
    );
};

export const NotAuthRoute = ({ element }) => {
    const isAuth = isUserAuthenticated();
    return !isAuth ?
        element
        : <Navigate to="/home" />;
};
