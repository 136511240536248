import React, { useState } from "react";
import CustomButtonOval from "../../components/buttons/CustomButtonOval";
import { imgUrl } from "../../helpers/recursosurl";
import { useFormik } from "formik";
import * as Yup from "yup";
import { validaCurp, validaDates, validaNames, validaNumerosTelefonos } from "../../helpers/formularios/Validation";
import { gestionarCuenta, gestionarEmail, getAuthUser } from "../../helpers/configRoutes/backend_helper";
import CustomInput from "../../components/inputs/CustomInput";
import { showFormErrors } from "../../helpers/showErrors";
import { useNavigate } from "react-router-dom";


const GestionarCuenta = () => {
    const [loading, setLoading] = useState(false);
    const authUser = getAuthUser();
    const navigate = useNavigate();
    const [editar, setEditar] = useState(false);
    //el valor inicial del estado false... set en react se usa para cambiar o mutar el valor de la constante o estado 
    const [validarToken, setValidarToken] = useState(false);

    //objeto formik para validar guardar y mutar datos
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: authUser,
        validationSchema: Yup.object({
            nombre: validaNames(),
            apellidos: validaNames(),
            fechaNacimiento: validaDates(0),
            email: Yup.string()
                .nullable()
                .email('Correo electrónico inválido'),
            telefono: validaNumerosTelefonos(false),
            movil: validaNumerosTelefonos(true),
            contrasena: Yup.string()
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_+=])[A-Za-z\d!@#$%^&*()-_+=]{8,}$/,
                    'La contraseña debe contener al menos una letra mayúscula, un carácter especial, un número, y tener una longitud mínima de 8 caracteres'
                )
                .required('Campo requerido'),
            confirmacionContrasena: Yup.string()
                .oneOf([Yup.ref('contrasena'), null], 'Las contraseñas deben coincidir')
                .required('Campo requerido'),

        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true);
            let response = {}
            if (validarToken) {
                //Validar token y enviar datos de formulario al back
                response = await gestionarCuenta(values);

                //despues de validar hauy que cambiar el localstorage de authuser o llamar la funcion de readux para actualizar esos datos
                if (response.status) {
                    let storedData = localStorage.getItem('authUser');
                    if (storedData) {
                        let userData = JSON.parse(storedData);
                        userData.user = response.item;
                        localStorage.setItem('authUser', JSON.stringify(userData));
                    }
                    setValidarToken(false);
                    setEditar(false);
                } else {
                    setErrors(response.response.data.errors)
                }

            } else {

                //Tu logica para enviar el correo llamas el endpoint del correo....
                response = await gestionarEmail(values);

                if (response.status) {
                    setValidarToken(true);
                } else {
                    setErrors(response.response.data.errors)
                }
            }
            setLoading(false);
        },
    });

    return (
        <>
            <div className="flex ml-[19%] flex-wrap max-w-full lg:max-w-[75%] mx-auto px-4">
                <div className="p-4 w-full">
                    <b className="block mb-2 text-sm font-bold">Esta es tu central de información.</b>
                    <p className="text-sm">Aquí puedes consultar y editar tus datos para conectar con clientes e instituciones.</p>
                </div>
                <div className="text-[24px] font-semibold mt-[23px] w-full">
                    <h2>
                        Información de la cuenta
                    </h2>
                </div>
                <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full mt-[24px]">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-4">
                            <div className="text-base font-semibold">
                                Tipo de cliente: persona física
                            </div>
                            <div className="text-base">
                                cuenta creada {authUser.created_at}
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            <input type="text" className="border rounded px-2 py-1 text-base" placeholder="Ultima modificacion:" value={authUser.updated_at} readOnly />
                            <CustomButtonOval className="bg--gris-arena-button text-sm hover:bg-gris-arena-hover" onClick={() => [setEditar(!editar), validation.resetForm()]}>{editar ? 'Cancelar' : 'Editar'}</CustomButtonOval>
                        </div>
                    </div>
                    <hr className="border-gray-300 my-4" />
                    <div className="grid grid-cols-4 gap-4">
                        {editar ?
                            <>
                                <div className="col-span-1">
                                    <div className="text-base font-semibold">
                                        Nombre(s)
                                    </div>
                                    <div className="text-base">
                                        <CustomInput
                                            name="nombre"
                                            value={validation.values.nombre || ''}
                                            error={validation.errors?.nombre}
                                            placeholder={'Nombre(s)'}
                                            type={'text'}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                    <div className="text-base font-semibold mt-[18px]">
                                        Email
                                    </div>
                                    <div className="text-base">
                                        <CustomInput
                                            name="email"
                                            value={validation.values.email || ''}
                                            error={validation.errors?.email}
                                            placeholder={'Email'}
                                            type={'text'}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-base font-semibold">
                                        Apellidos
                                    </div>
                                    <div className="text-base">
                                        <CustomInput
                                            name="apellidos"
                                            value={validation.values.apellidos || ''}
                                            error={validation.errors?.apellidos}
                                            placeholder={'Apellidos'}
                                            type={'text'}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                    <div className="text-base font-semibold mt-[18px]">
                                        Teléfono
                                    </div>
                                    <div className="text-base">
                                        <CustomInput
                                            name="telefono"
                                            placeholder={'1234567890'}
                                            value={validation.values.telefono || ''}
                                            error={validation.errors?.telefono}
                                            type={'text'}
                                            onChange={(e) => validation.setFieldValue('telefono', parseInt(e.target.value))}
                                            onBlur={validation.handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-base font-semibold">
                                        Fecha de nacimiento
                                    </div>
                                    <div className="text-base">
                                        <CustomInput
                                            name="fechaNacimiento"
                                            value={validation.values.fechaNacimiento || ''}
                                            error={validation.errors?.fechaNacimiento}
                                            type={'date'}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                    <div className="text-base font-semibold mt-[18px]">
                                        Contraseña
                                    </div>
                                    <div className="text-base">
                                        <CustomInput
                                            name="contrasena"
                                            placeholder={'*******'}
                                            value={validation.values.contrasena || ''}
                                            error={validation.errors?.contrasena}
                                            type={'password'}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-base font-semibold">
                                        Móvil
                                    </div>
                                    <div className="text-base">
                                        <CustomInput
                                            name="movil"
                                            placeholder={'1234567890'}
                                            value={validation.values.movil || ''}
                                            error={validation.errors?.movil}
                                            type={'text'}
                                            onChange={(e) => validation.setFieldValue('movil', parseInt(e.target.value))}
                                            onBlur={validation.handleBlur}
                                        />
                                    </div>
                                    <div className="text-base font-semibold mt-[18px]">
                                        Confirmar contraseña
                                    </div>
                                    <div className="text-base">
                                        <CustomInput
                                            name="confirmacionContrasena"
                                            placeholder={'*******'}
                                            value={validation.values.confirmacionContrasena || ''}
                                            error={validation.errors?.confirmacionContrasena}
                                            type={'password'}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        />
                                    </div>
                                </div>

                            </>
                            : <>
                                <div className="col-span-1">
                                    <div className="text-base font-semibold">
                                        Nombre(s)
                                    </div>
                                    <div className="text-base">
                                        {authUser.nombre}
                                    </div>
                                    <div className="text-base font-semibold mt-[18px]">
                                        Email
                                    </div>
                                    <div className="text-base">
                                        {authUser.email}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-base font-semibold">
                                        Apellidos
                                    </div>
                                    <div className="text-base">
                                        {authUser.apellidos}
                                    </div>
                                    <div className="text-base font-semibold mt-[18px]">
                                        Teléfono
                                    </div>
                                    <div className="text-base">
                                        {authUser.telefono}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-base font-semibold">
                                        Fecha de nacimiento
                                    </div>
                                    <div className="text-base">
                                        {authUser.fechaNacimiento}
                                    </div>
                                    <div className="text-base font-semibold mt-[18px]">
                                        Contraseña
                                    </div>
                                    <div className="text-base">
                                        ********
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-base font-semibold">
                                        Móvil
                                    </div>
                                    <div className="text-base">
                                        {authUser.movil}
                                    </div>
                                    <div className="text-base font-semibold mt-[18px]">
                                        Confirmar contraseña
                                    </div>
                                    <div className="text-base">
                                        ********
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="flex items-start">
                    <img src={`${imgUrl}/Frame 51.png`} alt="Alert Icon" className="h-[24px] w-[24px] object-contain" />

                    <p className="text-base">
                        Por seguridad, el cambio de estos datos <strong>requiere confirmación vía correo electrónico.</strong>
                    </p>
                </div>
                <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full mt-[24px] flex justify-center space-x-4">
                    {(validarToken && editar) && (
                        <>
                            <div className="text-base font-semibold">Token</div>
                            <div className="text-base">
                                <CustomInput
                                    name="token"
                                    value={validation.values.token || ''}
                                    error={validation.errors?.token}
                                    type={'text'}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </>
                    )}
                    {editar ? (
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                showFormErrors(validation);
                                return false;
                            }}
                        >
                            <CustomButtonOval textCarga={"Enviando codigo de seguridad"} loading={loading} type="submit" className="bg--gris-arena-button text-sm hover:bg-gris-arena-hover">
                                Actualizar datos del perfil {authUser.perfil_id == 4 ? ' profesional' : ' administrador'}
                            </CustomButtonOval>
                        </form>
                    ) : (
                        <CustomButtonOval
                            className="bg-o2o-secondary-rojo text-sm hover:bg-o2o-secondary-rojo-hover"
                            onClick={() => navigate(`/baja-cuenta`)}
                        >
                            Dar de baja la cuenta
                        </CustomButtonOval>
                    )}

                </div>




            </div>
        </>
    )

}
export default GestionarCuenta;