"use client"
import React from 'react'
import Buscador from '../home/Buscador'
import RegistrateCliente from '../home/RegistrateCliente'
import { useNavigate } from 'react-router-dom'
import { imgUrl } from '../../../helpers/recursosurl'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'

const ComoFunciona = () => {

    const navigate = useNavigate();

    return (
      <>
      <Buscador/>
      <div className="flex flex-col items-center px-5 text-black mb-10 font">
        <div className="text-5xl leading-10 text-center max-md:max-w-full max-md:text-4xl font mt-[72px]">
        ¿Cómo usar One 2 One Nurses?
        </div>
        <div className="mt-9 text-2xl leading-8 text-center max-w-[808px] w-[808px] max-md:max-w-full font">
          Nuestro objetivo es ofrecer una plataforma tecnológica que fácilmente conecte a las personas con profesionistas en enfermería. 
          Creando{' '} 
          <span className="font-bold">
          un espacio digno y seguro para ambos.
          </span>
          
        </div>
        <div className="mt-20 text-2xl leading-8 text-center max-w-[808px] w-[808px] max-md:max-w-full font font-bold">
        Conoce cómo funciona la plataforma paso a paso:          
        </div>
      </div>
      <div className="flex flex-col items-center font mb-5">
        <div className="p-12 bg-white rounded-2xl shadow-lg max-md:px-5">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[42%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col text-2xl font-bold text-black max-md:mt-10">
                <div className="justify-center items-center px-3.5 text-center whitespace-nowrap bg-cyan-400 rounded-2xl border-b border-solid border-black border-opacity-40 h-[42px] w-[42px]">
                  1
                </div>
                <div className="mt-16 leading-8 max-md:mt-10">
                  Regístrate en el sitio, crea tu perfil <br />y el de uno o varios
                  pacientes.
                </div>
                <div className="mt-3.5 text-base font-normal leading-6">
                  Al crear un perfil, se te solicitarán datos generales tanto del
                  administrador de la cuenta como del o los pacientes.
                </div>
                <div className="justify-center py-1.5 mt-16 text-sm leading-4 text-start max-md:mt-10">
                  <CustomButtonOval
                    className='bg-o2o-gris hover:bg-o2o-gris-hover'
                  >
                    Consulta los Términos y Condiciones
                  </CustomButtonOval>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[58%] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/usaro2o_1.png`}
                className="grow w-full aspect-[1.59] max-md:mt-10 max-md:max-w-full"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center font mb-5">
        <div className="p-12 bg-white rounded-2xl shadow-lg max-md:px-5">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[42%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col text-2xl font-bold text-black max-md:mt-10">
                <div className="justify-center items-center px-3.5 text-center whitespace-nowrap bg-cyan-400 rounded-2xl border-b border-solid border-black border-opacity-40 h-[42px] w-[42px]">
                  2
                </div>
                <div className="mt-16 leading-8 max-md:mt-10">
                  Realiza una búsqueda. La plataforma elige a los mejores
                  profesionistas para el diagnóstico de tu paciente
                </div>
                <div className="mt-3.5 text-base font-normal leading-6">
                  Cuando has iniciado sesión y tu perfil (y/o el del paciente) han
                  sido completados, la búsqueda es casi automática. Nuestro
                  algoritmo de búsqueda elige a las mejores opciones para tu
                  paciente y sus necesidades.
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[58%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center w-full bg-white rounded-2xl max-md:mt-10 max-md:max-w-full">
                <img
                  loading="lazy"
                  srcSet={`${imgUrl}/usaro2o_2.png`}
                  className="w-full aspect-[1.59] max-md:max-w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center font mb-5">
        <div className="p-12 bg-white rounded-2xl shadow-lg max-md:px-5">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[42%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col text-2xl font-bold text-black max-md:mt-10">
                <div className="justify-center items-center px-3.5 text-center whitespace-nowrap bg-cyan-400 rounded-2xl border-b border-solid border-black border-opacity-40 h-[42px] w-[42px]">
                  3
                </div>
                <div className="mt-16 leading-8 max-md:mt-10">
                  Espera la confirmación <br />
                  de un profesionista.
                </div>
                <div className="mt-3.5 text-base font-normal leading-6">
                  En un lapso de 24 horas recibirás confirmación del enfermero.
                  Mientras tanto, es posible navegar los perfiles de los enfermeros
                  que pueden atenderte.
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[58%] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/usaro2o_3.png`}
                className="grow w-full aspect-[1.69] max-md:mt-10 max-md:max-w-full"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center font mb-5">
        <div className="p-12 bg-white rounded-2xl shadow-lg max-md:px-5">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[42%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow pb-4 text-2xl font-bold text-black max-md:mt-10">
                <div className="justify-center items-center px-3.5 text-center whitespace-nowrap bg-cyan-400 rounded-2xl border-b border-solid border-black border-opacity-40 h-[42px] w-[42px]">
                  4
                </div>
                <div className="mt-10 leading-8">
                  El profesional de salud acude al domicilio de acuerdo a las
                  características contratadas.
                </div>
                <div className="mt-3.5 text-base font-normal leading-6">
                  Tanto profesionistas como clientes son regulados por el código de
                  conducta que busca garantizar espacios seguros para todos.
                </div>
                  
                <div className="justify-center py-1.5 mt-16 text-sm leading-4 text-start max-md:mt-10">
                  <CustomButtonOval
                    className='bg-o2o-gris hover:bg-o2o-gris-hover'
                  >
                    Lee el Código de Conducta
                  </CustomButtonOval>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[58%] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/usaro2o_4.png`}
                className="grow w-full aspect-[1.69] max-md:mt-10 max-md:max-w-full"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center font mb-5">
        <div className="p-12 bg-white rounded-2xl shadow-lg max-md:px-5">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[42%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col text-2xl font-bold text-black max-md:mt-10">
                <div className="justify-center items-center px-3.5 text-center whitespace-nowrap bg-cyan-400 rounded-2xl border-b border-solid border-black border-opacity-40 h-[42px] w-[42px]">
                  5
                </div>
                <div className="mt-16 leading-8 max-md:mt-10">
                  La plataforma solicitará a clientes y profesionistas calificarse
                  uno a otro.
                </div>
                <div className="mt-3.5 text-base font-normal leading-6">
                  Para cerrar el servicio —y con la finalidad de robustecer una
                  comunidad basada en el buen trato— la plataforma requerirá
                  calificar la experiencia y el servicio.
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[58%] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/usaro2o_5.png`}
                className="grow w-full aspect-[1.69] max-md:mt-10 max-md:max-w-full"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center text-center text-black mt-10 font mb-10">
        <div className="w-full text-5xl font-bold leading-10 max-md:max-w-full max-md:text-4xl">
          ¡Listo! One-to-One
        </div>
        <div className="mt-5 w-full text-2xl leading-8 max-md:max-w-full">
          One to One Nurses se encargará de todos los procesos administrativos,
          logísticos, de cobro y pago de servicios.
        </div>
      </div>
      
      <RegistrateCliente/>

      <div className="flex flex-col px-5 justify-center text-center mb-10 font ">
        
        <div className="mt-6 w-full text-2xl leading-8 max-md:max-w-full font-bold">
          One to One Nurses es un servicio respaldado
          por la Escuela de Enfermería Angelópolis
        </div>
        
        <div className="flex justify-center items-center px-16 mt-11 w-full text-sm leading-4 whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5">
            <div className="grow justify-center">
              <CustomButtonOval
                className='bg-o2o-gris hover:bg-o2o-gris-hover'
                onClick={() => navigate('/quienes-somos')}
              >
                Conoce más sobre nosotros
              </CustomButtonOval>
            </div>
          </div>
        </div>
      </div>
      </>
    )
}

export default ComoFunciona