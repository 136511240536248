import React, { useState, useEffect } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { useNavigate } from "react-router-dom";
import { declinar_solicitud, getMotivosCancelacion, cancelar_servicio, getAuthUser } from "../../../helpers/configRoutes/backend_helper";
import ConfirmacionServicio from "./ConfirmacionServicio";

const DeclinarServicio = ({ servicio, setComponent }) => {
    const authUser = getAuthUser()
    const navigate = useNavigate();
    const [motivos, setMotivos] = useState([])
    const [declinarItem, setDeclinarItem] = useState({ solicitud_id: servicio.id, motivo_id: null, message: '' })

    useEffect(() => {
        const getServicio = async () => {
            const response = await getMotivosCancelacion({ params: { model: (servicio.estatus_id == 9 ? 'Servicio' : 'Solicitud') } });
            setMotivos(response.motivos);
        }
        getServicio();
    }, []);

    const declinar = async () => {
        if (servicio.estatus_id == 9) {
            const response = await cancelar_servicio(declinarItem)
            if (response.status) {
                setComponent(<ConfirmacionServicio servicio={servicio} declinar={declinar} setComponent={setComponent} />)
            }
        } else {
            const response = await declinar_solicitud(declinarItem)
            if (response.status) {
                setComponent(<ConfirmacionServicio servicio={servicio} declinar={declinar} setComponent={setComponent} />)
            }
        }
    }

    return (
        <>
            <div className="flex flex-col items-start mt-[46px] mx-auto lg:ml-[19%] lg:max-w-[75%] px-4">
                <CustomButtonOval
                    className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover mb-[20px]"
                    onClick={() => setComponent(false)}
                >
                    <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                    >
                        <path
                            d="M5.80078 1L1.00078 5L5.80078 9"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Regresar a solicitudes de servicio
                </CustomButtonOval>
                <div className="flex flex-col items-start w-full lg:w-auto">
                    <div className="border-0 rounded-lg p-4 mb-4 w-full lg:w-[574px]">
                        <div className="text-[32px] mb-[18px] font-semibold">
                            <h2>{servicio.estatus_id == 9 ? 'Cancelar' : `Declinar`} el servicio de {servicio.administrador.nombre} {servicio.administrador.apellidos} para {servicio.paciente.nombre} {servicio.paciente.apellidos}</h2>
                        </div>
                        <div className="text-base">
                            {authUser.perfil_id == 4 ?
                                <p>
                                    {servicio.estatus_id == 9 ? '¿Seguro que deseas terminar anticipadamente este servicio?' : '¿Seguro que deseas declinar los cuidados enfermeros para este paciente?'}
                                </p>
                                :
                                <p>
                                    {servicio.estatus_id == 9 ? '¿Seguro que deseas terminar anticipadamente este servicio?' : '¿Seguro que deseas terminar anticipadamente este servicio?'}
                                </p>
                            }

                            {authUser.perfil_id == 4 ?
                                <p className="mt-[20px]">
                                    {servicio.estatus_id == 9 ? 'De acuerdo a los Términos & Condiciones, al cancelar anticipadamente el servicio activo; terminaremos tus obligaciones y remuneraremos el monto equivalente sin penalización al tratarse de una falta incurrida por el cliente.' : 'Al declinar esta solicitud de servicio pierdes la oportunidad —incluyendo tus derechos y obligaciones— de atender al paciente y cliente que lo solicita. Recuerda que no existe ninguna penalización, pero que apreciamos enormemente a las y los enfermeros comprometidos con One 2 One Nurses.'}
                                </p>
                                :
                                <p className="mt-[20px]">
                                    {servicio.estatus_id == 9 ? 'De acuerdo a los Términos & Condiciones del contrato con One 2 One Nurses, al cancelar el servicio con el actual perfil enfermero; enviaremos sin costo adicional un nuevo profesionista a tu domicilio para concluir exitosamente tu contratación.' : 'De acuerdo a los Términos & Condiciones del contrato con One 2 One Nurses, al cancelar el servicio con el actual perfil enfermero; enviaremos sin costo adicional un nuevo profesionista a tu domicilio para concluir exitosamente tu contratación.'}
                                </p>
                            }

                        </div>
                    </div>
                    <div className="border rounded-lg p-4 mb-4 w-full">
                        <div className="text-base font-semibold">
                            Agradeceríamos conocer el motivo  {servicio.estatus_id == 9 ? 'de cancelación' : ' para declinar'} la solicitud
                        </div>
                        <div className="flex flex-wrap justify-start space-x-2 mt-[30px]">
                            {motivos.map((m, index) => (
                                <CustomButtonOval
                                    key={index}
                                    onClick={() => setDeclinarItem({ ...declinarItem, motivo_id: m.id })}
                                    className={`flex justify-end ${m.id == declinarItem.motivo_id ? 'bg-o2o-gris-arena-hover' : 'bg-o2o-gris-arena-button'} text-sm hover:bg-gris-arena-hover`}
                                >
                                    {m.motivo}
                                </CustomButtonOval>
                            ))}
                        </div>
                        <div className="rounded-lg p-4 mt-4 w-full lg:w-[547px]">
                            <p className="mb-2 font-semibold text-sm">Especifica tus razones</p>
                            <textarea
                                className="rounded-lg w-full h-32 p-2 bg-o2o-gris-calido resize-none"
                                placeholder="Escribe aquí..."
                                value={declinarItem.message}
                                onChange={(e) => setDeclinarItem({ ...declinarItem, message: e.target.value })}
                            ></textarea>
                        </div>
                    </div>
                    <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full lg:w-[925px]">
                        <div className="flex justify-end space-x-2">
                            <CustomButtonOval onClick={() => declinar()} className="flex justify-end bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover">
                                continuar y declinar
                            </CustomButtonOval>
                            <CustomButtonOval className="flex justify-end bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover">
                                Aceptar el servicio
                            </CustomButtonOval>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DeclinarServicio;