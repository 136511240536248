import React, { useState, useEffect } from "react";
import CustomCalendar from "../../CustomCalendar";
import CustomSelect from "../../inputs/CustomSelect";
import { getTiempos } from "../../../helpers/configRoutes/backend_helper";

const Calendarizado = ({ validation }) => {
    const [c_turnos, setCTurnos] = useState([])
    const [c_horarios, setCHorarios] = useState([])

    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getTiempos()
            setCTurnos(response.turnos)
            setCHorarios(response.tiempos)
        }
        getCatalogos()
    }, []);

    return (
        <>
            <div className="flex flex-wrap max-w-[90%] mx-auto md:ml-[19%]">
                <div className="w-full px-2 mb-3 border shadow-lg rounded-lg">
                    <CustomCalendar
                        fechaInicio={validation.values?.fecha_inicio}
                        fechaFin={validation.values?.fecha_fin}
                        setFechaInicio={(e) => console.log(e, 'sdokkd')}
                        setFechaFin={(e) => console.log(e, 'riemp final')}
                        setDiasSeleccionados={(e) => console.log(e, 'dias')}
                        diasSeleccionados={validation.values?.dias}
                        validation={validation}
                    />
                </div>
            </div>
            <div className="flex flex-wrap max-w-[90%] mx-auto md:ml-[19%]">
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="self-start text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Turno<span className="text-red-600">*</span>
                    </div>
                    <CustomSelect
                        name='tiempo_id'
                        value={validation.values?.tiempo_id || ''}
                        options={[
                            { label: 'Seleccione una opción', value: 0 },
                            ...c_horarios?.map(data => {
                                return { value: data?.id, label: data?.nombre }
                            })
                        ]}
                        onChange={validation.handleChange}
                        error={validation.errors?.tiempo_id}
                    />
                </div>
            </div>
            <div className="flex flex-wrap max-w-[90%] mx-auto md:ml-[19%]">
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="flex flex-wrap justify-start items-center gap-3">
                        {c_turnos?.map((data, index) => {
                            return (
                                <button
                                    type="button"
                                    key={index}
                                    className={`justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover ${validation.values?.turno_id === data?.id ? 'bg-o2o-gris-hover' : ''}`}
                                    onClick={() => {
                                        validation.setFieldValue('turno_id', data.id);
                                    }}
                                >
                                    {data?.nombre}
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}
export default Calendarizado