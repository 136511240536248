import React, { useState, useEffect } from "react";
import BaseModalAnimated from "../../../components/modals/BaseModalAnimated";
import { showFormErrors } from "../../../helpers/showErrors";
import CustomSelect from "../../../components/inputs/CustomSelect"
import CustomButtonSquare from "../../../components/buttons/CustomButtonSquare";


const AddUpdTurno = ({ modal, setModal, c_turnos, c_horarios, dias, validation }) => {
    const agregarDias = (id) => {
        const diasActuales = validation.values.dias || [];
        let nuevosDias = [];
        if (diasActuales.includes(id)) {
            nuevosDias = diasActuales.filter(diaId => diaId !== id);
        } else {
            nuevosDias = [...diasActuales, id];
        }
        validation.setFieldValue('dias', nuevosDias);
    };

    return (
        <>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    showFormErrors(validation);
                    return false;
                }}
            >
                <BaseModalAnimated modal={modal} setModal={setModal}>
                    <div className="relative flex-auto p-2 mt-5">
                        <div className="w-full px-2 mb-3 border-t border-black border-solid">

                            <div className="flex flex-wrap px-2 mb-3 mt-3">
                                <div className="w-full md:w-1/2 px-2 mb-3">
                                    <div className="text-sm font-bold leading-4 text-black mb-2">
                                        Turno
                                    </div>
                                    <div className="justify-center">
                                        <CustomSelect
                                            name="turno_id"
                                            options={[
                                                { label: 'Seleccione una opción', value: 0 },
                                                ...c_turnos?.map(tipo => ({
                                                    value: tipo?.id, label: tipo?.nombre
                                                }))
                                            ]}
                                            value={validation.values.turno_id || ''}
                                            error={validation.errors?.turno_id}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2 px-2 mb-3">
                                    <div className="text-sm font-bold leading-4 text-black mb-2">
                                        Tiempo
                                    </div>
                                    <div className="justify-center">
                                        <CustomSelect
                                            name="tiempo_id"
                                            options={[
                                                { label: 'Seleccione una opción', value: 0 },
                                                ...c_horarios?.map(tipo => ({
                                                    value: tipo?.id, label: tipo?.nombre
                                                }))
                                            ]}
                                            value={validation.values.tiempo_id || ''}
                                            error={validation.errors?.tiempo_id}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='w-full flex flex-wrap'>
                                {dias.map((dia, index) => (
                                    <React.Fragment key={index}>
                                        {dia.ocupado ?
                                            <CustomButtonSquare
                                                type='button'
                                                className={`flex-1 text-xs hover:bg-o2o-secondary-rojo-hover bg-o2o-secondary-rojo`}
                                            >
                                                {dia.nombre}
                                            </CustomButtonSquare>
                                            :
                                            <CustomButtonSquare
                                                type='button'
                                                className={`flex-1 text-xs hover:bg-o2o-gris-arena ${validation.values.dias?.includes(dia.id) ? 'bg-o2o-gris-hover' : ''}`}
                                                onClick={() => agregarDias(dia.id)}
                                            >
                                                {dia.nombre}
                                            </CustomButtonSquare>
                                        }
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </BaseModalAnimated>
            </form>

        </>
    );
};

export default AddUpdTurno;
