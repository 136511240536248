import React from 'react'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import { useNavigate } from 'react-router-dom'

const DescubreMasO2O = () => {

    const navigate = useNavigate()

    return (
        <div className='flex flex-wrap justify-center items-center'>
            <div className='w-full font text-center mb-10'>
                <span className='text-4xl font-bold'>Descubre más sobre One 2 One</span>
            </div>
            <div className='w-full font text-center'>
                <CustomButtonOval
                    className={'bg-o2o-gris hover:bg-o2o-aqua-hover'}
                    onClick={() => navigate('/quienes-somos')}
                >
                    ¿Quiénes somos?
                </CustomButtonOval>
                <CustomButtonOval
                    className={'bg-o2o-gris hover:bg-o2o-aqua-hover'}
                    onClick={() => navigate('/como-funciona')}
                >
                    ¿Cómo funciona?
                </CustomButtonOval>
            </div>
        </div>
    )
}

export default DescubreMasO2O