import React, { useEffect, useState } from "react";
import { dangerToast, warningToast } from '../../helpers/Toast'
import { getIntereses } from "../../helpers/configRoutes/backend_helper";
import { getIn } from "yup";

const InteresesPersonales = ({ validation, categorias_ids }) => {
    const [categorias, setCategorias] = useState([])

    useEffect(() => {
        const getInteresesP = async () => {
            const response = await getIntereses(categorias_ids)
            setCategorias(response.categorias)
        }
        getInteresesP()
    }, []);

    const agregarInteresesPersonales = (id, categoria) => {
        const interesesActuales = validation.values.interesesPersonales || [];

        let nuevosIntereses = [];
        if (interesesActuales.filter(interes => interes.id == id).length > 0) {
            nuevosIntereses = interesesActuales.filter(interes => interes.id !== id)
            validation.setFieldValue('interesesPersonales', nuevosIntereses);
        } else {
            const interesesActualesCategory = interesesActuales.filter(intereses => intereses.categoria_id == categoria)
            if (interesesActualesCategory.length < 5) {
                nuevosIntereses = [...interesesActuales, { id: id, categoria_id: categoria }];
                validation.setFieldValue('interesesPersonales', nuevosIntereses);
            } else {
                dangerToast('Solo puedes seleccionar maximo 5 intereses personales');
            }
        }
    }

    const datos = {}

    return (
        <>
            <div className="flex flex-wrap max-w-full md:max-w-[90%] ml-4 md:ml-[19%]">
                <div className="w-full text-base font-bold leading-6">
                    Intereses personales
                </div>
                {categorias.map((categoria, index) => (
                    <div className="flex flex-col px-2 sm:px-4 pt-3 mt-3 w-full border-t border-black border-solid" key={index}>
                        <div className="w-full font-bold">
                            {categoria.nombre}
                        </div>
                        <div className="flex flex-wrap justify-start items-center mt-3 sm:mt-6 gap-2 sm:gap-3">
                            {categoria.intereses.map((data, index) => {
                                return (
                                    <button
                                        type="button"
                                        key={index}
                                        className={`justify-center px-2 sm:px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover ${validation.values.interesesPersonales?.filter(res => res?.id === data?.id).length > 0 ? 'bg-o2o-gris-hover' : ''}`}
                                        onClick={() => agregarInteresesPersonales(data?.id, data.categoria_id)}
                                    >
                                        {data.nombre}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                ))}
            </div>

        </>
    )
}
export default InteresesPersonales;