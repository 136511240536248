import React, { useState, useEffect } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ServicioAceptado from "./ServicioAceptadoRechazado";
import Servicios from "../Servicios";
import { serviciosResource } from "../../../helpers/configRoutes/backend_helper";



const AceptarSOlicitud = () => {
    const {id,id_cita} = useParams();
    const navigate = useNavigate();
    const [servicio, setServicio] = useState({});

    useEffect(() => {
        const getServicio = async () => {
            const response = await serviciosResource('show', {},id);

            setServicio(response.servicio);
        }
        getServicio();
    }, []);


    return (
        <>
            <div className="flex flex-col items-start mt-[94px] ml-[19%]">
                <CustomButtonOval className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover mb-[32px]" onClick={() => navigate('/servicios')}>
                    <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                    >
                        <path
                            d="M5.80078 1L1.00078 5L5.80078 9"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Regresar a solicitudes de servicio
                </CustomButtonOval>
                {/* {servicio == 1 ? */}
                    {/* <> */}
                        <div className="border-0 rounded-lg p-4 mb-4 w-[547px]">
                            <div className="text-[32px] mb-[18px] font-semibold">
                                <h2>Aceptar el servicio de nombre administrado{/*nombre de administrador*/} para nombre paciente{/*nombre de paciente*/}</h2>
                            </div>
                            <div className="text-base">
                                <p>¿Deseas ofrecer los cuidados enfermeros para este paciente?</p>
                                <p className="mt-[20px]">
                                    Al aceptar la solicitud de servicio se hacen válidos los <Link style={{ color: 'blue' }}>Términos y Condiciones</Link> descritos en el contrato con One2One Nurses; incluyendo tus derechos y obligaciones como proveedor de servicios.
                                </p>

                                <p className="mt-[20px]">
                                    Recuerda que durante el servicio se deben respetar los puntos descritos en el <Link style={{ color: 'blue' }}>Código de Conducta;</Link> por lo tanto, es indispensable conocer y comprender cada uno de ellos. Una vez aceptado el servicio, la cancelación o incumplimiento es acreedora a una sanción.
                                </p>
                            </div>
                            <div className="flex justify-start space-x-2 mt-[30px]">
                                <CustomButtonOval className="flex justify-end bg-o2o-gris-arena-button text-sm hover:bg-gris-arena-hover">
                                    Leer los Términos y Condiciones
                                </CustomButtonOval>
                                <CustomButtonOval className="flex justify-end bg-o2o-gris-arena-button text-sm hover:bg-gris-arena-hover">
                                    Leer el Código de Conducta
                                </CustomButtonOval>
                            </div>
                        </div>
                        <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-[925px]">
                            <div className="flex justify-end space-x-2">
                                <CustomButtonOval onClick={() => navigate(`/servicio-aceptado`)} className="flex justify-end bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover">
                                    Si, aceptar el servicio
                                </CustomButtonOval>
                                <CustomButtonOval className="flex justify-end bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover">
                                    No, declinar el servicio
                                </CustomButtonOval>
                            </div>
                        </div>

                    {/* </> */}
                    {/* : */}
                    {/* <> */}
                        <div className="flex flex-col items-start">
                            <div className="border-0 rounded-lg p-4 mb-4 w-[547px]">
                                <div className="text-[32px] mb-[18px] font-semibold">
                                    <h2>Declinar el servicio de nombre administrado{/*nombre de administrador*/} para nombre paciente{/*nombre de paciente*/}</h2>
                                </div>
                                <div className="text-base">
                                    <p>¿Seguro que deseas declinar los cuidados enfermeros para este paciente?</p>
                                    <p className="mt-[20px]">
                                        Al declinar esta solicitud de servicio pierdes la oportunidad —incluyendo tus derechos y obligaciones— de atender al paciente y cliente que lo solicita. Recuerda que no existe ninguna penalización, pero que apreciamos enormemente a las y los enfermeros comprometidos con One 2 One Nurses.
                                    </p>
                                </div>
                            </div>
                            <div className="border rounded-lg p-4 mb-4 w-[100%]">
                                <div className="text-base font-semibold">
                                    Agradeceríamos conocer el motivo para declinar la solicitud
                                </div>
                                <div className="flex justify-start space-x-2 mt-[30px]">
                                    <CustomButtonOval className="flex justify-end bg-o2o-gris-arena-button text-sm hover:bg-gris-arena-hover">
                                        No me interesa el perfil
                                    </CustomButtonOval>
                                    <CustomButtonOval className="flex justify-end bg-o2o-gris-arena-button text-sm hover:bg-gris-arena-hover">
                                        Me queda lejos
                                    </CustomButtonOval>
                                    <CustomButtonOval className="flex justify-end bg-o2o-gris-arena-button text-sm hover:bg-gris-arena-hover">
                                        Monto poco atractivo
                                    </CustomButtonOval>
                                    <CustomButtonOval className="flex justify-end bg-o2o-gris-arena-button text-sm hover:bg-gris-arena-hover">
                                        Complicaciones personales
                                    </CustomButtonOval>
                                    <CustomButtonOval className="flex justify-end bg-o2o-gris-arena-button text-sm hover:bg-gris-arena-hover">
                                        Otro. Especifica
                                    </CustomButtonOval>
                                </div>
                                <div className="border rounded-lg p-4 bg-gray-100  mt-4 w-[547px]">
                                    <p className="mb-2 font-semibold text-sm">Especifica tus razones</p>
                                    <textarea
                                        className="border rounded-lg w-full h-32 p-2 bg-gray-50 resize-none"
                                        placeholder="Escribe aquí..."
                                    ></textarea>
                                </div>
                            </div>
                            <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-[925px]">
                                <div className="flex justify-end space-x-2">
                                    <CustomButtonOval onClick={() => navigate(`/servicio-aceptado`)} className="flex justify-end bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover">
                                        continuar y declinar
                                    </CustomButtonOval>
                                    <CustomButtonOval className="flex justify-end bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover">
                                        Aceptar el servicio
                                    </CustomButtonOval>
                                </div>
                            </div>
                        </div>
                    {/* </>} */}
            </div>
        </>
    );
}

export default AceptarSOlicitud;