//REGISTER
export const POST_LOGIN_TOKEN = "/login"
export const RESET_ACCOUNT = "/reset-account"
export const FIRST_AUTH = '/login-code-verify'
export const RESET_AUTH = '/verify-reset-code'
//catalogos
//USUARIOS
export const USUARIOS_RESOURCE = '/operacion-perfiles/usuarios'
export const GESTIONAR_EMAIL = '/operacion-perfiles/usuario-token-editar'
export const GESTIONAR_CUENTA = '/operacion-perfiles/usuario-gestionar-editar'
export const USUARIO_CANCELAR_CUENTA= '/operacion-perfiles/usuario-cancelar-cuenta'
//FAVORITOS
export const FAVORITOS = '/operacion-perfiles/administrador-favoritos'
//facturas
export const FACTURAS_RESOURCE = '/operacion-perfiles/facturas-servicios'
//catalogos
export const PAISES = '/catalogos-registro/paises'
export const MUNICIPIOS = '/catalogos-registro/municipios'
export const ESTADOS = '/catalogos-registro/estados'
export const CPS = '/catalogos-registro/cps'
export const GET_INTERESES = '/catalogos-registro/intereses'
export const GET_ESPECIALIDADES = '/catalogos-registro/especialidades'
export const GET_CUIDADOS = '/catalogos-registro/cuidados'
export const GET_ENTIDADES = '/catalogos-registro/entidades'
export const GET_HABILIDADES = '/catalogos-registro/habilidades'
export const GET_OFERTA_SERVICIOS = '/catalogos-registro/oferta-servicios'
export const GET_TIEMPOS = '/catalogos-registro/turnos-tiempos'
export const GET_MOTIVOS_CANCELACION = '/catalogos-perfiles/motivos-cancelacion'
export const NIVELES_RESOURCE = '/catalogos-registro/niveles'
export const SEXOS_GENEROS =  '/catalogos-registro/generos-sexos'
export const GET_MODULO = '/catalogos-registro/modulos'
//registro de usuarios 
export const REGISTRO = '/registro'
export const RESET_PASSWORD = '/reset'

//PACIENTES
export const PACIENTES_RESOURCE = 'operacion-perfiles/pacientes'
export const PACIENTE_DOWN = 'operacion-perfiles/paciente-down'

//solicitudes
export const SERVICIO_RESOURCE = 'operacion-perfiles/servicios'
export const GET_COTIZACION = 'operacion-perfiles/servicio-cotizacion'
export const ACCION_SOLICITUD = 'operacion-perfiles/servicio-accion'
export const DECLINAR_SOLICITUD= 'operacion-perfiles/servicio-declinar'
export const CANCELAR_SERVICIO='operacion-perfiles/servicio-cancelar'
//CITAS
export const CITA_INICIO = 'operacion-perfiles/cita-inicio'
//cALIFICACIONES
export const CALIFICACIONES_RESOURCE = 'operacion-perfiles/calificaciones'
//ENFERMEROS
export const ENFERMERO_RESOURCE = 'operacion-perfiles/enfermeros'

//sucursales
export const SUCURSALES_RESOURCE = '/sucursales'
//Codigos de verificacion
export const CODE_RESOURCE = '/codes'
export const PASSWORD_CONFIRM = '/confirm-password'
//COLABORADOR
export const COLABORADOR_RESOURCE = '/colaboradores'
//ENFERMEROS
export const ENFERMERO_TURNOS_RESOURCE = 'operacion-perfiles/enfermero-turnos'

//iMAGEN QR
export const SET_IMAGE_DINAMICA = 'operacion-perfiles/image-dinamica'
//ENTRADAS DE BLOG
export const ENTRADAS_BLOG = 'publica/blog'
export const CONTRATOS = 'publica/contratos'
//CONVERSACIONES
export const CONVERSACIONES_RESOURCE = '/operacion-perfiles/conversaciones'
//RECOMPENZAS
export const RECOMPENZAS_RESOURCE = '/operacion-perfiles/recompenzas'