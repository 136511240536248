import { SET_RESULTADOS,BACK_RESULTADOS } from "./actionTypes";

const initialState = {
    back_resultados: false,
    resultados: [],
    paciente_id: null
};

const busquedas = (state = initialState, action) => {
    switch (action.type) {
        case SET_RESULTADOS:
            // Actualizar el estado
            const newState = {
                ...state,
                paciente_id: action.payload.paciente_id,
                resultados: action.payload.enfermeros
            };
            return newState;
        case BACK_RESULTADOS:
            const newSt = {
                ...state,
                back_resultados: action.payload
            };
            return newSt;
        default:
            return state;
    }
};

export default busquedas;
