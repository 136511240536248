import React, { useEffect, useState, useRef } from "react";
import CustomButtonOval from "../../components/buttons/CustomButtonOval";
import CustomInput from "../../components/inputs/CustomInput";
import CustomButtonSquare from "../../components/buttons/CustomButtonSquare";
import { conversacionesResource } from "../../helpers/configRoutes/backend_helper";
import { useDispatch, useSelector } from "react-redux";
import { setContador } from "../../store/conversaciones/action";

const Mensajes = () => {
    const sinLeer = useSelector((state) => state.mensajesContador.sin_leer);
    const [buttonActivo, setButtonActivo] = useState(1);
    const [message, setMessage] = useState({ message: '' });
    const [conversacionActivaId, setConversacionActivaId] = useState(null);
    const [conversaciones, setConversaciones] = useState([]);
    const [filters, setFilters] = useState({ perPage: 5, page: 1, estatus_id: 9 });
    const [mensajes, setMensajes] = useState([]);
    const dispath = useDispatch()

    const messageEndRef = useRef(null);

    const handleConversacionClick = (id) => {
        setConversacionActivaId(id);
    };

    useEffect(() => {
        const getConversaciones = async () => {
            const response = await conversacionesResource('get', { params: filters });
            setConversaciones(response.conversaciones);
            dispath(setContador(response.totalSinLeer)) 
        }
        getConversaciones();
    }, [filters,sinLeer]);

    useEffect(() => {
        const getMessages = async () => {
            const response = await conversacionesResource('show', { params: filters }, conversacionActivaId);
            setMensajes(response.messages);
            setFilters({...filters,perPage:5})
        }
        if (conversacionActivaId) {
            getMessages();
        }
    }, [conversacionActivaId,sinLeer]);

    useEffect(() => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [mensajes]);

    const send = async () => {
        const response = await conversacionesResource('post', message);
        if (!response.status) {
            return false;
        }
        
        if (conversacionActivaId) {
            setMensajes([...mensajes, response.messageItem]);
            setMessage({ message: '' });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            send();
        }
    };

    const conversacionActiva = conversaciones.find(c => c.id === conversacionActivaId);

    return (
        <>
            <div className="flex flex-wrap max-w-full lg:max-w-[75%] mx-auto px-4 mr-[24px] ml-[300px]">
                <h3>Tus conversaciones</h3>
                <div className="border-t border-gray-300 my-4 w-full"></div>
                <div className="flex flex-row flex-wrap gap-2 mt-[10px] mb-[10px]">
                    <CustomButtonOval
                        type={'success'}
                        onClick={() => setFilters({ ...filters, estatus_id: 9 })}
                        className={filters.estatus_id === 9 ? `bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover` : `bg-o2o-gris-arena-button text-sm hover:bg-o2o-gris-calido-hover`}
                    >
                        Servicios activos
                    </CustomButtonOval>
                    <CustomButtonOval
                        type={'button'}
                        onClick={() => setFilters({ ...filters, estatus_id: 6 })}
                        className={filters.estatus_id === 6 ? `bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover` : `bg-o2o-gris-arena-button text-sm hover:bg-o2o-gris-calido-hover`}
                    >
                        Archivo
                    </CustomButtonOval>
                </div>
                <div className="border-t border-b border-gray-300 my-4 w-full h-[470px] flex bg-white shadow-lg rounded-lg">

                    <div className="border-r border-gray-300 w-1/3 overflow-y-auto p-4">
                        <div className="space-y-4">
                            {conversaciones.map((c) => (
                                <div
                                    key={c.id}
                                    className={`flex justify-between items-center p-2 hover:bg-o2o-gris-calido-hover rounded-lg cursor-pointer ${conversacionActivaId === c.id ? 'bg-o2o-gris-calido' : ''}`}
                                    onClick={() => handleConversacionClick(c.id)}
                                >
                                    <div className="flex items-center">
                                        <img src={c.usuarioImg || "https://placehold.co/50x50"} alt={`Profile picture of ${c.usuarioConversacion}`} className="w-12 h-12 rounded-full" />
                                        <div className="ml-3">
                                            <p className="text-gray-900 font-semibold">{c.usuarioConversacion}</p>
                                            <p className="text-gray-500 text-sm">{c.last.length > 20 ? `${c.last.substring(0, 20)}...` : c.last}</p>
                                        </div>
                                    </div>
                                    <div className="text-base bg-o2o-aqua rounded-lg p-1 pl-3 pr-3"> { ` ${c.sinLeer==false ? 0:c.sinLeer} `}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="w-2/3 flex flex-col p-4">
                        <div className="border-b border-gray-300 pb-2">
                            <h2 className="text-lg font-semibold text-teal-600">
                                {conversacionActiva ? `Perfil de ${conversacionActiva.usuarioChat}` : 'Selecciona una conversación'}
                            </h2>
                        </div>
                        <div className="flex-grow overflow-y-auto py-4">
                            <div className="space-y-4">
                                {conversacionActiva && mensajes.map((msg, index) => (
                                    <div
                                        ref={messageEndRef}
                                        key={index}
                                        className={`relative flex ${msg.isFromAuth ? 'justify-end' : 'justify-start'} transition-all duration-300 ${msg.animate ? 'animate-message' : ''}`}
                                    >
                                        <div className={`bg-${msg.isFromAuth ? 'gray-100' : 'o2o-aqua'} text-${msg.isFromAuth ? 'gray-900' : 'teal-900'} p-3 rounded-lg max-w-md relative`}>
                                            <p className="absolute -bottom-6 left-0 text-xs text-gray-500 opacity-0 hover:opacity-100 transition-opacity">
                                                {msg.fecha}
                                            </p>
                                            <p>{msg.mensaje}</p>
                                        </div>
                                        {msg.isFromAuth && (
                                            <img src={msg.perfilImg || "https://placehold.co/40x40"} alt="Profile picture of the user" className="w-10 h-10 rounded-full ml-3" />
                                        )}
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                        {conversacionActiva &&
                            <div className="w-full flex items-center mt-auto">
                                <CustomInput
                                    name="message"
                                    value={message.message}
                                    error={false}
                                    placeholder={'Escribe tu mensaje...'}
                                    type={'text'}
                                    onChange={(e) => setMessage({ ...message, message: e.target.value, servicio_id: conversacionActiva.id })}
                                    onKeyDown={handleKeyDown}
                                />
                                <CustomButtonSquare className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover ml-2" onClick={send}>
                                    <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.6001 14.5L9.4001 8L1.6001 1.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </CustomButtonSquare>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Mensajes;
