import React from "react";
import CustomTabsPerfiles from '../../../components/CustomTabsPerfiles'
import PerfilAdministrador from "./PerfilAdministrador";
import ListadoPacientes from "../pacientes/ListadoPacientes";

const MisPerfiles = ({ authUser }) => {
    const tabs = [
        { id: 1, label: 'Administrador', content: <PerfilAdministrador authUser={authUser} /> },
        { id: 2, label: 'Pacientes', content: <ListadoPacientes authUser={authUser} /> },
    ];

    return (
        <>
            <div className="flex flex-wrap max-w-[75%] ml-[19%]">
                <div className="p-4">
                    <b className="block mb-2 text-sm font-bold">Esta es tu central de información.</b>
                    <p className='text-sm'>Aquí puedes consultar y editar tus datos para conectar con especialistas en enfermería.</p>
                </div>
            </div>
            <CustomTabsPerfiles defaultTab={1} tabs={tabs} />
        </>
    )
}
export default MisPerfiles