"use client"
import { useLocation } from 'react-router-dom'
import SearchForm from '../../../components/SearchForm'
import { imgUrl } from '../../../helpers/recursosurl'
import React, { useEffect, useState } from 'react'
import Card from '../../../components/Card'
import { getEspecialidades } from '../../../helpers/configRoutes/backend_helper'

const Buscador = () => {

    const [img, setImg] = useState('');
    const [selectEspecialidad, setSelectEspecialidad] = useState(null);
    const location = useLocation();
    const [catEspecialidades, setCatEspecialidades] = useState([])
    const [showBuscador,setShowBuscador] = useState(false)
    useEffect(() => {
        const getServicio = async () => {
            const response = await getEspecialidades();
            console.log(response, 'especialidad')

            setCatEspecialidades(response.especialidades);

        }
        getServicio();
    }, []);

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setImg('home-search.png');
                break;
            case '/quienes-somos':
                setImg('quienes-search.png');
                break;
            case '/como-funciona':
                setImg('comofunciona-search.png');
                break;
            case '/central-atencion':
                setImg('centro-atencion.svg');
                break;
            case '/documentacion-informacion':
                setImg('centro-atencion.svg');
                break;
            default:
                setImg('quienes-search.png');
                break;
        }
    }, [])


    return (
        <div className="flex flex-col mt-[68px] justify-center max-w-o2o mx-auto" id="buscar-enfermero">
            <div className="w-full">
                <div className="relative items-center">
                    {location.pathname === '/' &&
                        <div className='absolute flex flex-col max-w-[293px] top-1/2 left-28 transform  -translate-y-1/2'>
                            <h1 className='mb-[18px]'>Cuidados de enfermería a domicilio</h1>
                            <p>Conecta con el especialista ideal para ti, tus seres queridos o tu negocio</p>
                        </div>
                    }
                    <img src={`${imgUrl}/${img}`} alt="Imagen" className="w-full h-auto hidden sm:block rounded-b-[18px]" />
                    <img src={`${imgUrl}/${img}`} alt="Imagen" className="w-full h-auto sm:hidden rounded-b-[18px]" />
                    <SearchForm showBuscador={showBuscador} setShowBuscador={setShowBuscador}/>
                    <div className='flex justify-center item-center w-auto'>
                        <div className='absolute items-center justify-center pt-3 top-28 hidden lg:block w-full md:w-[750px]'>
                            {showBuscador &&
                            <Card
                                title={<>
                                    <span className='text-gray-400'>Con especialidad en</span>
                                    <div className="border-t border-gray-300 mt-2"></div>
                                </>}
                                content={
                                    <>
                                        <div className='w-[750px] mx-auto'>
                                            <div className='flex'>
                                                <div className='lg:w-[60%] w-full'>
                                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 overflow-x-auto">
                                                        {catEspecialidades.map((item, index) => (
                                                            <button
                                                                key={index}
                                                                className={`hover:text-o2o-morado hover:font-bold text-start text-menuBusqueda ${selectEspecialidad?.nombre === item.nombre ? 'text-o2o-morado font-bold' : ''}`}
                                                                index={index}
                                                                onMouseEnter={() => setSelectEspecialidad(item)}
                                                            >
                                                                {item.nombre}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='lg:w-[30%] w-full ml-[18px]'>
                                                    <h3 className='text-black'>{selectEspecialidad?.nombre}</h3>
                                                    <p className='text-sm'>{selectEspecialidad?.descripcion}</p>
                                                </div>
                                                <div className='lg:w-[10%] w-full ml-[18px]'>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                tags={[]}
                            />
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Buscador