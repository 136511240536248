import React, { useState, useEffect } from "react";
import CustomCalendarBusquedas from "../../CustomCalendarBusquedas";
import CustomSelect from "../../inputs/CustomSelect";
import { getTiempos } from "../../../helpers/configRoutes/backend_helper";
import CustomButtonSquare from "../../buttons/CustomButtonSquare";
import { getCotizacion } from "../../../helpers/configRoutes/backend_helper";

const CalendarizadoBusquedas = ({ validation }) => {
    const [c_turnos, setCTurnos] = useState([])
    const [c_horarios, setCHorarios] = useState([])
    const [arregloFechas, setArregloFechas] = useState([]);
    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getTiempos()
            setCTurnos(response.turnos)
            setCHorarios(response.tiempos)
        }
        getCatalogos()
    }, []);

    useEffect(() => {
        const getSelectedDates = () => {
            const dayNames = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
            let start = new Date(validation.values.fecha_inicio);
            let end = new Date(validation.values.fecha_fin);
            let dates = [];

            while (start <= end) {
                let dayIndex = start.getDay(); // Obtiene el índice del día de la semana (0 para domingo, 1 para lunes, etc.)
                if (validation.values?.dias.includes(dayIndex + 1)) {
                    dates.push({
                        fecha: start.toISOString().split('T')[0],
                        dia: dayNames[dayIndex]
                    });
                }
                start.setDate(start.getDate() + 1); // Incrementa la fecha en un día
            }
            setArregloFechas(dates)
        }
        if (validation.values?.fecha_fin && validation.values?.fecha_inicio && validation.values?.dias) {
            getSelectedDates()
        }

    }, [validation.values?.fecha_fin, validation.values?.fecha_inicio, validation.values?.dias]);

    return (
        <>
            <div className="flex flex-wrap ml-12 mt-3">
                <div className="w-full lg:w-[70%] max-w-[70%] bg-o2o-gris-arena rounded-xl p-3 mb-3 lg:mb-0 mr-0 lg:mr-1">
                    <div className="w-full px-2 mb-3 border shadow-lg rounded-lg">
                        <CustomCalendarBusquedas
                            arregloFechas={arregloFechas}
                            fechaInicio={validation.values?.fecha_inicio}
                            fechaFin={validation.values?.fecha_fin}
                            setFechaInicio={(e) => console.log(e, 'sdokkd')}
                            setFechaFin={(e) => console.log(e, 'riemp final')}
                            setDiasSeleccionados={(e) => console.log(e, 'dias')}
                            diasSeleccionados={validation.values?.dias}
                            validation={validation}
                            arregloFechas={arregloFechas}
                            setArregloFechas={setArregloFechas}
                        />
                    </div>
                </div>
                <div className="w-full lg:w-[28%] max-w-[30%] bg-o2o-gris-arena rounded-xl p-3">
                    <div className="text-base font-bold leading-4 text-black mb-3">
                        Turno
                    </div>
                    <div className='w-full mb-3 lg:mb-0'>
                        {c_horarios?.map((data, index) => (
                            <CustomButtonSquare
                                type='button'
                                key={index}
                                className={`self-start text-xs hover:bg-o2o-gris-arena ${validation.values?.tiempo_id == data?.id ? 'bg-o2o-gris-hover' : ''}`}
                                onClick={() => validation.setFieldValue('tiempo_id', data.id)}
                            >
                                {data.nombre}
                            </CustomButtonSquare>
                        ))}
                    </div>
                    <p className="text-xs mt-4 mb-4">Los turnos de 24 hrs son cotizados como dos turnos de doce horas.</p>
                    <div className="text-base font-bold leading-4 text-black mb-2 mt-3">
                        Horario
                    </div>
                    <div className='w-full mb-3 lg:mb-0'>
                        {c_turnos?.map((data, index) => (
                            <CustomButtonSquare
                                type='button'
                                key={index}
                                className={`self-start text-xs hover:bg-o2o-gris-arena ${validation.values?.turno_id == data?.id ? 'bg-o2o-gris-hover' : ''}`}
                                onClick={() => validation.setFieldValue('turno_id', data.id)}
                            >
                                {data.nombre}
                            </CustomButtonSquare>
                        ))}
                    </div>
                    <p className="text-xs mt-3 mb-2">
                        {
                            c_turnos.filter(t => t.id === validation.values?.turno_id)[0]
                                ? `El turno ${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].nombre} comprende un horario de ${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].hora_inicio} a ${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].hora_fin}`
                                : 'Turno no encontrado'
                        }
                    </p>
                    <div className="text-base font-bold leading-4 text-black mb-2 mt-3">
                        Servicio prorgamado para
                    </div>
                    <p className="text-base mt-3 mb-2">
                        {arregloFechas.map((date, index) => (
                            <span key={index}>
                                {date.dia} {date.fecha}{index < arregloFechas.length - 1 ? ' | ' : ''}
                            </span>
                        ))}
                    </p>
                    <p className="text-base mt-3 mb-2">
                        <span>
                            {
                                c_horarios.filter(t => t.id === validation.values?.tiempo_id)[0]
                                    ? `${c_horarios.filter(t => t.id === validation.values?.tiempo_id)[0].nombre}`
                                    : 'Turno no encontrado'
                            }
                        </span>
                        <span>
                            | {
                                c_turnos.filter(t => t.id === validation.values?.turno_id)[0]
                                    ? `${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].nombre} [${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].hora_inicio} a ${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].hora_fin}]`
                                    : 'Turno no encontrado'
                            }
                        </span>
                    </p>
                </div>
            </div>

        </>
    )
}
export default CalendarizadoBusquedas