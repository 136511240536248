import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import jsQR from 'jsqr';
import CustomButtonOval from './buttons/CustomButtonOval';

const QrScanner = ({ setUrl }) => {
  const [data, setData] = useState('No result');
  const [facingMode, setFacingMode] = useState('user');
  const webcamRef = useRef(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      decodeQR(imageSrc);
    }
  };

  const decodeQR = (imageSrc) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        setUrl(code.data);
        setData(code.data);
      } else {
        setData('No QR code found.');
      }
    };
  };

  const handleSwitchCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      capture();
    }, 1000); // Captura cada segundo
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width="100%"
        videoConstraints={{ facingMode }}
      />
      <CustomButtonOval 
      onClick={handleSwitchCamera} className="bg--gris-arena-button text-sm hover:bg-gris-arena-hover">
        Cambio de camara
        </CustomButtonOval>
      
      <p>{data}</p>
    </div>
  );
};

export default QrScanner;
