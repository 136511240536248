import React, { useState } from "react";
import BaseModalAnimated from "../../../../components/modals/BaseModalAnimated";
import QrScanner from "../../../../components/QrScanner";
import CustomInput from "../../../../components/inputs/CustomInput";
import { warningToast } from "../../../../helpers/Toast";

const QrModal = ({ modal, setModal, setUrlQr, urlQr }) => {
    const [copUrlQr, setCopUrlQr] = useState(urlQr)

    const save = () => {
        if (copUrlQr) {
            setUrlQr(copUrlQr);
            setModal({ ...modal, manualToken: true })
        }else{
            warningToast('Estanea tu token o ingresa el codigo manualmente');
        }
    }
    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal} action={save}>
                <QrScanner setUrl={setUrlQr} />
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Numero de seguridad Token
                    </div>
                    <CustomInput
                        name="url"
                        type={'text'}
                        placeholder={'token'}
                        onChange={(e) => setCopUrlQr(e.target.value)}
                    />
                </div>
            </BaseModalAnimated>
        </>
    )
}

export default QrModal