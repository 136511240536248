import { combineReducers } from "redux"
// Authentication
//import Login from "./auth/login/reducer"
//sucursales
import Usuario from "./users/registro/reducer"
import Login from "./auth/login/reducer"
import Loader from "./loading/reducer"
import Busquedas from "./busquedas/reducer"
import mensajesContador from "./conversaciones/reducer"
//Configuracion de acciones de titulos y titulo
//import Titles from "./titles/reducer"

const rootReducer = combineReducers({
  Login,
  Usuario,
  Loader,
  Busquedas,
  mensajesContador
  //Titles
})

export default rootReducer
