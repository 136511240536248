import React from 'react'
import { imgUrl } from '../../../helpers/recursosurl'
import { useIsMobile } from '../../../store/hooks/useIsMobile'
import { useLocation } from 'react-router-dom'
import CardImg from '../../../components/CardImg'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import SearchForm from '../../../components/SearchForm'

const Buscardor2 = () => {

  const { isMobile } = useIsMobile();
  const location = useLocation();

  const dataList = [
    {
      img: `${imgUrl}/seguridad.png`,
      path: '/seguridad',
      titulo: 'Tu salud y seguridad siempre serán nuestra prioridad',
      descripcion: <><span className="font-bold">
        One to One Nurses es un servicio que invita a las personas a sanar
        en el ambiente más seguro: su hogar.
      </span>{" "}
        Nuestra plataforma fue construida sobre estrictos procesos que
        protegen la integridad de clientes y enfermeros.</>
    },
    {
      img: `${imgUrl}/costos-membresias.png`,
      path: '/costos-membresias',
      titulo: (<div className="titulo">
        Asequible & digno:<br />así es nuestro servicio
      </div>),
      descripcion: <><span className='font-bold'>One to One Nurses es un servicio de alta calidad a un precio justo</span>, tanto para pacientes,
        familias o empresas; como para los expertos en salud que los ayudan a sanar. </>
    },
    {
      img: `${imgUrl}/testimoniales.png`,
      path: '/testimoniales',
      titulo: 'La voz de clientes y enfermeros respalda nuestro prestigio',
      descripcion: <>No hay mejor respaldo que los <span className='font-bold'>testimoniales de clientes y profesionistas</span> que validan el servicio de One to One Nurses</>
    },
    {
      img: ``,
      path: '/blog',
      titulo: '',
      descripcion: '',
      cardInfo: {
        img: `${imgUrl}/blog-header.png`,
        titulo: '',
        descripcion: ''
      }
    },
  ];

  return (
    <>
      <div className="relative justify-center">
        <div className="absolute inset-0 rounded-lg bg-stone-300 -z-10" style={{ height: isMobile ? '45em' : '50em' }}></div>

        <div className='flex items-start justify-center p-4 w-full mt-[65px]'>

        </div>

        <div className='flex items-center justify-center p-1 w-full'>
          <SearchForm />
        </div>
        {dataList.map((data, index) => {
          if (data?.path === location.pathname)
            return (
              <div className="flex flex-col text-center text-black" key={index}>
                <div className="flex flex-col items-center p-20 w-full max-md:px-5 max-md:max-w-full font">
                  <div className="text-5xl font-bold leading-10 w-[832px] max-md:mt-10 max-md:max-w-full max-md:text-4xl max-md:leading-10">
                    {data?.titulo}
                  </div>
                  <div className="mt-5 text-base leading-6 w-[832px] max-md:mb-2 max-md:max-w-full">
                    {data?.descripcion}
                  </div>
                </div>
                <div className="flex flex-col mb-20">

                  {data?.cardInfo?.img ? (
                    <div className='self-center mb-8 mt-0 w-full lg:w-2/3 aspect-[1.85] max-w-[1160px] max-md:mt-0 max-md:max-w-full h-auto hidden md:block'>
                      <CardImg
                        imageUrl={data?.cardInfo?.img}
                        title={<>
                          <div className='text-start text-4xl font mb-5 '><h1>Recomendaciones generales para recibir a un enfermero en tu domicilio</h1></div>
                          <div className='text-start text-base font font-bold ml-[47px] mr-[290px]'>Por Lic. en enfermería María Martínez</div>
                          <div className='text-start text-base font font-normal mb-5 ml-[47px] mr-[290px]'>Marzo 17 de 2023</div>
                        </>}
                        content={<>
                          <div className='text-start  text-[24px] font mb-5 ml-[47px] mr-[290px]'>
                            El servicio de cuidados enfermeros a domicilio puede ser gran experiencia.
                            El hogar es el mejor entorno para recuperar la salud, rodeado de personas amadas,
                            en un entorno seguro y cómodo. Extender esta experiencia al proveedor de servicios
                            médicos es la mejor forma de crear el entorno adecuado para sanar.
                          </div>
                          <div className='text-end font'>
                            <CustomButtonOval
                              className={'bg-o2o-aqua hover:bg-o2o-aqua-hover'}
                            >
                              Seguir leyendo
                            </CustomButtonOval>
                          </div>
                        </>}
                      />
                    </div>
                  ) : (
                    <img
                      loading="lazy"
                      srcSet={data?.img}
                      className="self-center mt-0 w-full lg:w-2/3 aspect-[1.85] max-w-[1160px] max-md:mt-0 max-md:max-w-full h-auto hidden md:block"
                    />
                  )}
                </div>
              </div>
            )
        })}

      </div>
    </>
  )
}

export default Buscardor2