import React from 'react'
import DescubreMasO2O from './DescubreMasO2O'

const ContratoServicios = () => {
    return (
        <>
        <div className='flex bg-o2o-gris-arena rounded-3xl p-5 mb-10'>
            <div className='w-full font'>
                <p className='mb-10'>
                    <span className='bg-o2o-morado text-white rounded-lg p-2'>
                        Última actualización: 17 de MArzo de 2023
                    </span>
                </p>
                <p className='font-bold text-4xl mb-2'>Contrato de Prestación de Servicios</p>
                <p className='text-xl mb-14'>
                    <span className='font-bold '>Contrato de Prestación de Servicios de Enfermería celebrado entre:</span><br></br>
                    One to One Nurses con Cliente
                </p>
                <p className='text-3xl mb-3'>Reunidos:</p>
                <p className='text-xl mb-5'>
                    De una parte,<br></br>
                    <strong>Nombre del Cliente</strong><br></br>
                    Privada de la 23 sur #3701 B-103. Ex-Hacienda La Noria. C.P. 72410. Puebla, México.
                    Que se denominará en el presente contrato a partir de ahora: <strong>EL PROVEEDOR</strong>
                </p>
                <p className='text-xl mb-5'>
                    De la otra,<br></br>
                    <strong>One to One Nurses. Enfermería Online S.A. de C.V.</strong><br></br>
                    Como [Relación contractual como autónomo] En adelante: <strong>EL CONTRATANTE</strong>
                </p>
                <p className='text-xl mb-5'>EXPONEN celebrar el siguiente contrato de servicios, dejando de manifiesto que:</p>
                <p className='text-xl mb-5'>
                    <ul className='list-decimal px-12'>
                        <li>Que EL CLIENTE es una persona física que desarrolla su actividad en el sector </li>
                        <li>
                            Que EL CLIENTE está interesado en dar a conocer de forma adecuada sus servicios, y 
                            a estos efectos desea contratar la realización de determinados servicios digitales a 
                            desarrollar por parte de EL PROVEEDOR, tanto en relación con la marca EL CLIENTE como 
                            con otros productos y servicios desarrollados por EL CLIENTE.
                        </li>
                        <li>
                            Que EL PROVEEDOR demuestra amplia experiencia en el sector del MARKETING DIGITAL 
                            interesado en prestar sus servicios profesionales para el desarrollo de las campañas 
                            de publicidad en internet que EL CLIENTE le encomiende.  
                        </li>
                    </ul>
                </p>
                <p className='text-xl mb-10'>
                    Ambas partes al objeto de alcanzar los objetivos y finalidades anteriormente expresados, 
                    reconociéndose mutua y recíprocamente plena capacidad para contratar, acuerdan suscribir 
                    el presente contrato de marketing online para prestar servicios publicitarios en internet, 
                    con sujeción a los pactos y condiciones establecidos en las siguientes.
                </p>

                <p className='text-3xl mb-10 font-bold'>Cláusulas</p>
                <p className='text-xl mb-8'>
                    <strong>PRIMERA. -</strong> El presente contrato lo celebran las partes por tiempo INDETERMINADO a partir de lxxxxxxxxxxxxxxxxx.
                </p>
                <p className='text-xl mb-8'>
                    <strong>SEGUNDA.-</strong> El trabajador prestará sus servicios al patrón en el puesto de xxxxxxxxxxxxxxxxxx con las funciones de 
                    confianza establecidas de acuerdo al Manual de Organización vigente en el domicilio del patrón señalado en la declaración I de este 
                    contrato, o en el lugar donde se consideren necesarios sus servicios y con un salario mensual de $xxxxxxxxxxxxxxxxx.
                </p>
                <p className='text-xl mb-8'>
                    <strong>TERCERA. -</strong> El trabajador se obliga a prestar sus servicios con el carácter señalado en la inteligencia de que 
                    la determinación de estos servicios se hace en forma enunciativa y no limitativa y, por tanto, el trabajador deberá desempeñar 
                    todas las labores anexas y conexas con su obligación principal. Igualmente, previo su conocimiento, se obliga a realizar cualquier 
                    trabajo que le ordene el patrón o sus representantes, sin detrimento del salario pactado en este contrato.
                </p>
                <p className='text-xl mb-8'>
                    <strong>CUARTA. -</strong> Las partes convienen en que el trabajador prestará sus servicios al patrón de lunes a viernes iniciando
                     su jornada a las 8:30 terminando a las 16:30, disfrutando durante su jornada de 30 minutos, de descanso para tomar sus alimentos, 
                     pudiéndolo hacer dentro o fuera de la empresa a su elección. Dicho horario podrá ser modificado si las necesidades del área así lo requieran, 
                     siempre y cuando éste no exceda de la jornada legal de trabajo prevista por la Ley Federal del Trabajo.
                </p>
                <p className='text-xl mb-8'>
                    <strong>QUINTA. -</strong> El trabajador se obliga a no divulgar, por ningún motivo ya sea por medio de publicaciones, informes, conferencias, 
                    medios electrónicos, o de manera verbal o por escrito, o por cualquier otra forma los datos, la información o resultados derivados de los servicios 
                    objeto de este contrato, salvo en los casos en que medie autorización expresa que por escrito otorgue el CIMAV. Esta obligación de confidencialidad 
                    será de naturaleza permanente y no cesará con motivo de la suspensión o terminación del presente contrato; por lo que se hará responsable al trabajador 
                    de los daños y perjuicios que se ocasionaren al CIMAV por el incumplimiento de esta cláusula.
                </p>
                <p className='text-xl mb-8'>
                    <strong>SEXTA. -</strong> Las partes convienen en que el descanso semanal del trabajador será el día sábado adicional al domingo, en aplicación de la modalidad que establece el artículo 59 de la Ley Federal del Trabajo.
                </p>
                <p className='text-xl mb-8'>
                    <strong>SÉPTIMA. -</strong> Las partes convienen en que el salario que perciba el trabajador le será pagado quincenalmente en moneda de curso legal los días 14 y 29 ó 30 
                    (cuando el mes sea de 31 días), por medio de tarjeta electrónica. Cuando el día de pago sea no laborable, se le pagará el salario, el día anterior 
                    laborable de la quincena correspondiente.
                </p>
                <p className='text-xl mb-8'>
                    <strong>OCTAVA. -</strong> Son días de descanso obligatorio: 1 de enero, primer lunes de febrero en conmemoración del 5 de febrero, tercer lunes de marzo en conmemoración 
                    del 21 de marzo, jueves y viernes de Semana Santa, 1 y 5 de mayo, 16 de septiembre, 2 de noviembre, tercer lunes de noviembre en conmemoración del 20 de 
                    noviembre, 1 de diciembre de cada seis años, cuando corresponda a la transmisión del Poder Ejecutivo Federal, el 25 de diciembre y los que determinen las 
                    Leyes Federales y Locales Electorales en el caso de elecciones ordinarias, para efectuar la jornada electoral, con el pago íntegro del salario.
                </p>
                <p className='text-xl mb-8'>
                    <strong>NOVENA. -</strong> El trabajador disfrutará de 20 días laborables de vacaciones al año divididos en dos periodos, uno en la segunda quincena 
                    de julio y el otro en la segunda quincena de diciembre proporcionalmente a su fecha de ingreso. Asimismo, percibirá el pago correspondiente a su prima 
                    vacacional de acuerdo al tabulador de prestaciones vigente.
                </p>
                <p className='text-xl mb-8'>
                    <strong>DÉCIMA. -</strong> Anualmente el trabajador tendrá derecho a percibir antes del 20 de diciembre, 40 días de salario por concepto de aguinaldo, 
                    o la parte proporcional tomando en consideración su fecha de ingreso.
                </p>
                <p className='text-xl mb-8'>
                    <strong>DÉCIMA PRIMERA. -</strong> El trabajador manifiesta que conoce y acepta sujetarse a las normas y condiciones laborales expresadas en 
                    el Reglamento Interior de Trabajo vigente. DECIMA SEGUNDA. - El patrón no podrá modificar unilateralmente y en perjuicio del trabajador las 
                    condiciones de trabajo pactadas en el presente contrato.
                </p>
                <p className='text-xl mb-8'>
                    <strong>DÉCIMA TERCERA. -</strong> Las partes manifiestan que lo no establecido en el presente contrato, que derive de la relación de trabajo entre 
                    celebrantes del mismo, queda sujeto a las disposiciones de la Ley Federal de Trabajo y/o Reglamento Interior de Trabajo.
                </p>
                <p className='text-xl mb-8'>
                    <strong>DÉCIMA CUARTA. -</strong> El trabajador conviene en someterse a los reconocimientos médicos que ordene el patrón en los términos de la fracción x del artículo 134 de la ley federal del trabajo.
                </p>
                <p className='text-xl mb-8'>
                    <strong>DÉCIMA QUINTA. -</strong> El trabajador será capacitado o adiestrado en los términos de los planes y programas establecidos (o que se establezcan), 
                    por el patrón, conforme a lo dispuesto en la Ley Federal del Trabajo.
                </p>
                <p className='text-xl mb-8'>
                    <strong>DÉCIMA SEXTA. -</strong> Los datos personales que con motivo de su trabajo proporcione el trabajador al patrón serán tratados y resguardados 
                    con base en los principios de licitud, finalidad, lealtad, consentimiento, calidad, proporcionalidad, información y responsabilidad consagrados en la 
                    Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados, por lo que el patrón se sujetará a las facultades y atribuciones que la 
                    normatividad aplicable le confiera.
                </p>
                <p className='text-xl mb-3'>
                Leído que fue por ambas partes el presente contrato, lo firmaron en Chihuahua, Chih., el día veintisiete de enero de dos mil veinte, quedando el original en poder del patrón y una copia para el trabajador.
                </p>
                <p className='font-bold text-4xl mb-10 mt-10'>One 2 One Nurses</p>
                <p className='text-xl mb-10'>www.onetoone.mx</p>
            </div>
        </div>
        <DescubreMasO2O/>
        </>
    )
}

export default ContratoServicios