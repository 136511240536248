import React, { useState } from 'react'
import { imgUrl } from '../../helpers/recursosurl'
import CustomInput from '../../components/inputs/CustomInput'
import CustomButtonOval from '../../components/buttons/CustomButtonOval'
import * as Yup from "yup";
import { useFormik } from "formik";
import login from '../../store/auth/login/reducer';
import { useDispatch } from 'react-redux';
import { postLoginToken, resetPassword,/*Funcion parar reset*/ } from '../../helpers/configRoutes/backend_helper'
import { useNavigate } from 'react-router-dom';
import { loginSuccess } from '../../store/auth/login/action';
import { setContador } from '../../store/conversaciones/action';

const Login = () => {
    const dispath = useDispatch()
    const navigate = useNavigate();
    const [olvideContrasena, setOlvideContrasena] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Ingresa tu correo electronico"),
            password: Yup.string().when('olvideContrasena', {
                is: false,
                then: Yup.string().required("Ingresa tu contraseña")
            })
        }),
        onSubmit: async (values, { setErrors }) => {
            if (olvideContrasena) {
                const response = await resetPassword(values)
                if (response.status == true) {
                    setOlvideContrasena(false)
                }
            } else {
                const response = await postLoginToken(values)
                if (response.status == true) {
                    dispath(setContador(response.totalSinLeer)) 
                    dispath(loginSuccess(response))
                    navigate('/home')
                } else {
                    if (response.errors?.length) {
                        setErrors(response.errors);
                    }
                }
            }
        }
    });

    return (
        <>
            <form onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}
            >
                <div className="flex flex-wrap bg-stone-100 rounded-t-lg p-4 m-10">
                    <div className='w-full flex justify-end' onClick={()=>navigate('/')}>
                        <img src={`${imgUrl}/Layer_1.png`} alt="Logo" className="h-16 mr-2" />
                    </div>
                    <div className='w-full md:w-1/3'>
                        <div className="w-full font-bold">
                            <h1>Bienvenid@</h1>
                        </div>
                        <div className="w-full text-base mb-10">
                            Cuidados enfermeros a un click de distancia
                        </div>
                        <div className="w-full px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                Correo electrónico
                            </div>
                            <CustomInput
                                placeholder={'example@mail.com'}
                                type={'email'}
                                name="email"
                                error={validation.errors?.email}
                                value={validation.values.email}
                                /* error={errorsForm?.email}
                                onBlur={({target}) => blurChange(target)} */

                                onChange={validation.handleChange}
                            />
                        </div>
                        {!olvideContrasena && (
                            <div className="w-full  px-2 mb-3">
                                <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-3">
                                    Contraseña
                                </div>
                                <CustomInput
                                    placeholder={'*******'}
                                    type={'password'}
                                    name="password"
                                    error={validation.errors?.password}
                                    value={validation.values.password}
                                    /* error={errorsForm?.contrasena} */
                                    onChange={validation.handleChange}
                                />
                            </div>
                        )}
                        <div className="w-full  px-2 mb-3">
                            <CustomButtonOval
                                className={'bg-o2o-aqua hover:bg-o2o-aqua-hover w-full font-bold'}

                                type='sucess'
                            >
                                {olvideContrasena ? 'Recuperar contraseña' : 'Iniciar sesión'}
                            </CustomButtonOval>
                        </div>
                        <div className="w-full  px-2 mb-3 text-sm">
                            <button
                                className={'w-full hover:underline'}
                                type='button'
                                onClick={() => setOlvideContrasena(!olvideContrasena)}
                            >
                                {olvideContrasena ? 'Regresar' : 'Olvidé mi contraseña'}
                            </button>
                        </div>
                    </div>

                    <div className='w-full md:w-2/3'>
                        <img src={`${imgUrl}/login.png`} alt="banner" className="w-full h-full object-cover" />
                    </div>
                </div>
            </form>
        </>
    )
}

export default Login