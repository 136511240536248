import React from 'react'
import Buscador from '../home/Buscador'
import BlogResumen from '../blog/BlogResumen'
import { imgUrl } from '../../../helpers/recursosurl'
import { useNavigate } from 'react-router-dom'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'

const Manifesto = () => {

  const navigate = useNavigate();

  return (
    <>
    <Buscador/>
    <div className="flex flex-col px-5 justify-center mb-10">
      <div className="flex gap-2.5 justify-center self-start">
        <CustomButtonOval
          className='bg-o2o-gris hover:bg-o2o-gris-hover self-start'
          onClick={() => navigate('/quienes-somos')}
        >
          Regresar a ¿Quiénes somos?
        </CustomButtonOval>
      </div>
      <div className="self-center mt-20 text-[48px] font-bold leading-10 text-center text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl max-md:leading-10 font">
        Nuestro compromiso es un faro que guía cada paso que damos:
      </div>
      <div className="self-center mt-9 text-2xl leading-8 text-center text-black max-w-[808px] w-[808px] max-md:max-w-full font">
        <span className='font-bold'>
        En One 2 One Nurses, nos enorgullece presentar nuestro manifiesto,{" "}
        </span>
        <span>
          un testimonio de nuestro compromiso inquebrantable con la salud, la
          atención personalizada y el bienestar.
        </span>
      </div>


      <div className="justify-center px-10 mt-16 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full font">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow text-black max-md:mt-10 max-md:max-w-full">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/manifesto1.png`}
                className="w-full aspect-[1.33] max-md:max-w-full"
              />
              <div className="mt-3 text-2xl font-bold max-md:max-w-full">
                Excelencia en la selección de profesionales
              </div>
              <div className="mt-3 text-base leading-6 max-md:max-w-full">
                En One 2 One Nurses, reconocemos la importancia de contar con
                profesionales altamente capacitados y comprometidos con su
                vocación. Nuestro proceso de selección se rige por los más altos
                estándares para garantizar que quienes confíen en nosotros
                reciban atención de primera.
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow text-black max-md:mt-10 max-md:max-w-full">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/manifesto2.png`}
                className="w-full aspect-[1.33] max-md:max-w-full"
              />
              <div className="mt-3 text-2xl font-bold max-md:max-w-full">
                Facilitación de la Interacción
              </div>
              <div className="mt-3 text-base leading-6 max-md:max-w-full">
                Entendemos que la atención de la salud es un proceso que debe
                ser accesible y sin complicaciones. Nuestra plataforma está
                diseñada para simplificar la interacción entre profesionales y
                pacientes, brindando atención y tranquilidad en momentos
                críticos.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-center px-10 mt-8 w-full max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow text-black max-md:mt-10 max-md:max-w-full">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/manifesto3.png`}
                className="w-full aspect-[1.33] max-md:max-w-full"
              />
              <div className="mt-3 text-2xl font-bold max-md:max-w-full">
                Dignificación de la Enfermería
              </div>
              <div className="mt-3 text-base leading-6 max-md:max-w-full">
                Valoramos profundamente el papel de los y las enfermeras en el
                cuidado de la salud. Trabajamos incansablemente para elevar su
                estatus y compromiso profesional, reconociendo que su labor va
                más allá de la atención médica, ya que también ofrecen apoyo
                emocional y empático.
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow text-black max-md:mt-10 max-md:max-w-full">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/manifesto4.png`}
                className="w-full aspect-[1.33] max-md:max-w-full"
              />
              <div className="mt-3 text-2xl font-bold max-md:max-w-full">
                Comunidad Unificada
              </div>
              <div className="mt-3 text-base leading-6 max-md:max-w-full">
                Estamos construyendo una comunidad sólida y unificada de
                profesionales de la salud y pacientes. Creemos en la importancia
                de compartir experiencias, apoyo mutuo y el poder de la empatía
                en momentos de dificultad.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="flex flex-col px-5 justify-center text-center mb-10 font ">
      <div className="w-full text-5xl leading-10 max-md:max-w-full max-md:text-4xl font-bold">
        Un Futuro Saludable
      </div>
      <div className="mt-6 w-full text-2xl leading-8 max-md:max-w-full">
        One 2 One Nurses es más que una plataforma; somos la promesa de un
        futuro saludable. Estamos decididos a ser un faro de esperanza en los
        momentos más oscuros y a brindar atención con el corazón.
      </div>
      <div className="mt-6 w-full text-base leading-6 max-md:max-w-full">
        Únete a nosotros en este viaje, donde la dedicación, la humanidad y el
        compromiso se entrelazan para forjar un mañana más saludable y lleno de
        posibilidades.{" "}
        <span className="font-bold">
          En One 2 One Nurses, estamos aquí para tí, y juntos, construiremos un
          mundo donde la salud y el bienestar sean prioridades compartidas.
        </span>
      </div>
      <div className="flex justify-center items-center px-16 mt-11 w-full text-sm leading-4 whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5">
          <div className="grow justify-center ">
          <CustomButtonOval
            className='bg-o2o-gris hover:bg-o2o-gris-hover'
            onClick={() => navigate('/como-funciona')}
          >
            ¿Cómo funciona?
          </CustomButtonOval>
          </div>
          <div className="grow justify-center">
            <CustomButtonOval
              className='bg-o2o-gris hover:bg-o2o-gris-hover'
              onClick={() => navigate('/testimoniales')}
            >
              Lee los testimoniales
            </CustomButtonOval>
          </div>
        </div>
      </div>
    </div>
    <BlogResumen/>
    </>
  )
}

export default Manifesto;