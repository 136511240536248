"use client"
import React from 'react'
import Buscador from '../home/Buscador'
import BlogResumen from '../blog/BlogResumen'
import { useNavigate } from 'react-router-dom'
import { imgUrl } from '../../../helpers/recursosurl'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'

const QuienesSomos = () => {

  const navigate = useNavigate();

  return (
    <>
    <Buscador/>
    
    <div className="flex flex-col items-center px-5 text-black mb-10">
      <div className="text-5xl leading-10 text-center max-md:max-w-full max-md:text-4xl font mt-[72px]">
        Tu casa es el mejor entorno para sanar
      </div>
      <div className="mt-9 text-2xl leading-8 text-center max-w-[808px] w-[808px] max-md:max-w-full font">
        <span className="font-bold">
          Y nuestro servicio una opción muy segura.{" "}
        </span>
        Nuestros enfermeros cuentan con las más altas certificaciones y un
        genuino compromiso por el bienestar integral de las familias.
      </div>
      <div className="flex overflow-hidden relative flex-col items-start self-stretch px-16 pt-20 pb-0.5 mt-11 w-full min-h-[1011px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <img
          loading="lazy"
          srcSet={`${imgUrl}/lazy.png`}
          className="object-cover absolute inset-0 size-full"
        />
        <div className="flex relative flex-col ml-14 max-w-full mt-[671px] w-[472px] max-md:mt-10">
          <div className="text-[32px] leading-9 max-md:max-w-full font-bold font">
            Estamos reinventando el servicio en cuidados de enfermería
          </div>
          <div className="mt-5 text-base leading-6 max-md:max-w-full mb-3">
            <span className="font-bold">
              Creemos que la enfermería puede actualizarse a las necesidades
              actuales.{" "}
            </span>
            El uso de las tecnologías digitales te permitirá coincidir
            fácilmente con el perfil de enfermero ideal para ti y tus
            necesidades médicas.
          </div>
          <CustomButtonOval
            className='bg-o2o-aqua hover:bg-o2o-aqua-hover self-start'
            onClick={() => navigate("/manifesto")}
          >
            Lee nuestro manifiesto
          </CustomButtonOval>
        </div>
      </div>
    </div>
    
    <div className="flex flex-col">
      <div className="flex-wrap px-5 w-full shadow-sm max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow self-stretch px-6 pt-6 pb-20 w-full text-black rounded-xl bg-stone-200 max-md:px-5 max-md:mt-3 font">
              <div className="text-2xl font-bold whitespace-nowrap">
                Nuestra Visión
              </div>
              <div className="mt-3 text-base leading-6">
                <span className="font-bold">
                  Somos una agencia de enfermería en línea{" "}
                </span>
                con un propósito claro y audaz, ser una marca de servicio que
                trascienda las expectativas, siendo fuerte en su carácter,
                madura en su aproximación y sensible en su atención.
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow self-stretch p-6 w-full text-black rounded-xl bg-stone-200 max-md:px-5 max-md:mt-3 font">
              <div className="text-2xl font-bold whitespace-nowrap">
                Nuestra Misión
              </div>
              <div className="mt-3 text-base leading-6">
                <span className="font-bold">
                  Atender a las personas con humanidad a través de una
                  plataforma innovadora
                </span>{" "}
                que simplifica la selección de profesionales de la salud y
                aborda necesidades críticas de interacción.
                <br />
                <br />
                En One 2 One Nurses, estamos comprometidos con la dignificación
                de la profesión de enfermería y el papel esencial que desempeñan
                en el cuidado de la salud.
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full font">
            <div className="flex flex-col grow self-stretch px-6 pt-6 pb-11 w-full text-black rounded-xl bg-stone-100 max-md:px-5 max-md:mt-3">
              <div className="text-2xl font-bold leading-8">
                La esencia de nuestra comunidad
              </div>
              <div className="mt-3 text-base leading-6">
                One 2 One Nurses no es solo una plataforma, es el origen de una
                comunidad. Reunimos a personas aptas para atender y a aquellos
                que están abiertos a recibir la atención necesaria para superar
                las adversidades que atentan contra la salud. En este crisol de
                habilidades, empatía y compasión, vemos un futuro saludable
                forjado con dedicación.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center pr-11 mt-3 w-full rounded-xl shadow-sm bg-stone-100 max-md:pr-5 max-md:max-w-full">
        <div className="max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[42%] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/directora.png`}
                className="grow self-stretch w-full aspect-[1.02] max-md:mt-10 max-md:max-w-full"
              />
            </div>
            <div className="flex flex-col ml-5 w-[58%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-between self-stretch py-11 text-black max-md:mt-10 max-md:max-w-full font">
                <div className="text-[32px] font-bold leading-9 max-md:max-w-full">
                  Lee el mensaje de nuestra directora
                </div>
                <div className="mt-8 text-2xl leading-8 max-md:max-w-full">
                  Conoce la historia, la visión y el compromiso que One to One
                  Nurses tiene —tanto con clientes como profesionistas— que
                  llevó a diseñar el servicio que ahora puedes disfrutar.
                </div>
                <CustomButtonOval
                  className='bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm self-start'
                  onClick={() => navigate('/carta-de-la-directora')}
                >
                  Lee la carta de Ximena
                </CustomButtonOval>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        loading="lazy"
        srcSet={`${imgUrl}/nurse_morado.png`}
        className=" self-center w-full aspect-[1.85] max-w-[1043px] max-md:max-w-full"
      />
    </div>

    <div className="flex justify-center items-center px-16 py-20 text-black bg-white max-md:px-5">
      <div className="flex gap-5 justify-between w-full max-w-[1054px] max-md:flex-wrap max-md:max-w-full">
        <img
          loading="lazy"
          src={`${imgUrl}/monograma-cuadrado.svg`}
          className="shrink-0 self-start max-w-full aspect-[1.01] w-[101px]"
        />
        <div className="flex flex-col flex-1 max-md:max-w-full font">
          <div className="text-[32px] font-bold leading-9 max-md:max-w-full">
            Nuestros profesionistas están certificados por la Escuela de
            Enfermería Angelópolis
          </div>
          <div className="mt-5 text-2xl leading-8 max-md:max-w-full">
            La EEA es una de instituciones educativas en enfermería más
            importantes del país. El personal de One to One Nurses es egresado
            de la carrera técnica o licenciatura en enfermería y —como
            requisito— cursa certificaciones periódicas.
          </div>
          <div className="mt-5 text-base leading-6 max-md:max-w-full">
            La Escuela de Enfermería Angelópolis tiene más de 30 años de
            trayectoria en la formación de licenciados y técnicos en enfermería.
            Además ofrecemos una variedad de certificaciones, especializaciones
            y posgrados para robustecer el perfil de enfermeros y médicos.
            Tenemos más de 1000 egresados en...
          </div>
          <div className="flex gap-3 justify-center self-start mt-5 text-sm leading-4 text-center whitespace-nowrap max-md:flex-wrap">
            <CustomButtonOval
              className='bg-o2o-violeta hover:bg-o2o-violeta-hover self-start font-bold'
              onClick={() => window.open('https://www.escueladeenfermeriangelopolis.edu.mx', '_blank')}
            >
              www.escueladeenfermeriaangelopolis.edu.mx
            </CustomButtonOval>
            <CustomButtonOval
              className='bg-o2o-gris hover:bg-o2o-gris-hover self-start'
              onClick={() => navigate('/testimoniales')}
            >
              Lee los testimoniales
            </CustomButtonOval>
          </div>
          <div className="mt-11 text-2xl font-bold max-md:mt-10 max-md:max-w-full">
            Perfil del egresado EEA
          </div>
          <div className="mt-5 text-base leading-6 max-md:max-w-full">
            Gestiona desde el ámbito estrategias para la solución de problemas
            de salud individual, familiar y/o comunitaria, a través de una
            formación humanitaria, con experiencia práctica hospitalaria,
            basados en estándares de calidad nacionales e internacionales, con
            referentes científicos y tecnológicos modernos.
            <br />
            <br />
            Responsabilidad e integridad humana y profesional, bajo los
            estándares bioéticos establecidos en el código moral profesional de
            la Enfermería.
          </div>
        </div>
      </div>
    </div>

    {/* BLOG */}
    <BlogResumen/>
    </>
  )
}

export default QuienesSomos