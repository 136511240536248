import React from 'react'

const CustomTextArea = ({ rows, error='', ...props }) => {

    const ErrorMensaje = () => {
        if(error){
            return (
                <span className="text-red-600">
                    {error}
                </span>
            )
        }
        return
    }

    return (
        <div className="flex flex-col w-full py-1">
            <textarea
                rows={rows || 3} // Por defecto, 3 filas si no se proporciona ninguna
                className="block w-full py-2 px-4 rounded bg-stone-100 border border-gray-300 focus:outline-none focus:border-o2o-aqua-hover"
                {...props}
            />
            <ErrorMensaje/>
        </div>
        
    )
}

export default CustomTextArea