import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { imgUrl } from '../../../helpers/recursosurl'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import { entradasBlog } from '../../../helpers/configRoutes/backend_helper'

const BlogResumen = () => {
  
  const navigate = useNavigate();
  const [entradas,setEntradas] = useState([])

  useEffect(() => {
    const getEntradas = async () => {
        const response = await entradasBlog();
        setEntradas(response.entradas)
    }
    getEntradas()
}, []);
  

  return (
    <div className="flex flex-col px-12 pt-12 pb-10 rounded-2xl bg-stone-200 max-md:px-5 mb-2">
      <div className="flex gap-2.5 text-black max-md:flex-wrap text-start">
        <div className="flex-auto">
          <h2>Últimas entradas del Blog</h2>
        </div>
        <CustomButtonOval
          className={'bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm'}
          onClick={() => navigate('/blog')}
        >
        Ir al Blog
        </CustomButtonOval>
      </div>
      <div className="mt-6 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow text-sm leading-4 text-black max-md:mt-9">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/blog-1.png`}
                className="w-full aspect-[1.45]"
              />
              <div className="mt-[30px] text-2xl font-bold leading-8 flex-1">
                <h3>Los cuidados post-natal que un profesional puede enseñarte</h3>
              </div>
              <div className="mt-3 font-bold whitespace-nowrap">
                <p className='text-sm'>Por Lic. en enfermería María Martínez</p>
              </div>
              <div className="whitespace-nowrap">Marzo 17 2023</div>
              <div className="mt-[30px] leading-6">
                <p className='text-base'>Durante las primeras horas y días posteriores al nacimiento, las
                madres experimentan una serie de cambios físicos y emocionales,
                mientras que los recién nacidos se adaptan a su nuevo entorno.
                Los enfermeros especializados en atención post-natal desempeñan
                un papel crucial en la monitorización de la salud de la madre y
                el bebé, brindando educación sobre la lactancia materna, el
                autocuidado y ayudando a las familias a navegar por esta fase de
                transición.
                </p>
              </div>
              <div className="justify-center self-start mt-5">
                <CustomButtonOval
                  className={'bg-o2o-gris-calido hover:bg-o2o-gris-calido-hover'}
                >
                Seguir leyendo
                </CustomButtonOval>
              </div>
              
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow text-sm leading-4 text-black max-md:mt-9">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/blog-2.png`}
                className="w-full aspect-[1.45]"
              />
              <div className="mt-[30px] text-2xl font-bold leading-8 flex-1">
                <h3>Cuidados y recomendaciones post-quirúrgicas para la recuperación
                en casa</h3>
              </div>
              <div className="mt-3 font-bold whitespace-nowrap">
                <p className='text-sm'>Por Lic. en enfermería María Martínez</p>
              </div>
              <div className="whitespace-nowrap">Marzo 17 2023</div>
              <div className="mt-[30px] leading-6">
                <p className='text-base'>Durante las primeras horas y días posteriores al nacimiento, las
                madres experimentan una serie de cambios físicos y emocionales,
                mientras que los recién nacidos se adaptan a su nuevo entorno.
                Los enfermeros especializados en atención post-natal desempeñan
                un papel crucial en la monitorización de la salud de la madre y
                el bebé, brindando educación sobre la lactancia materna, el
                autocuidado y ayudando a las familias a navegar por esta fase de
                transición.
                </p>
              </div>
              <div className="justify-center self-start mt-5">
                <CustomButtonOval
                  className={'bg-o2o-gris-calido hover:bg-o2o-gris-calido-hover'}
                >
                Seguir leyendo
                </CustomButtonOval>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow text-sm leading-4 text-black max-md:mt-9">
              <img
                loading="lazy"
                srcSet={`${imgUrl}/blog-3.png`}
                className="w-full aspect-[1.45]"
              />
              <div className="mt-[30px] text-2xl font-bold leading-8 flex-1">
                <h3>Protocolos para facilitarle al enfermero el cuidado del paciente
                en el domicilio</h3>
              </div>
              <div className="mt-3 font-bold whitespace-nowrap">
                <p className='text-sm'>Por Lic. en enfermería María Martínez</p>
              </div>
              <div className="whitespace-nowrap">Marzo 17 2023</div>
              <div className="mt-[30px] leading-6">
                <p className='text-base'>Durante las primeras horas y días posteriores al nacimiento, las
                madres experimentan una serie de cambios físicos y emocionales,
                mientras que los recién nacidos se adaptan a su nuevo entorno.
                Los enfermeros especializados en atención post-natal desempeñan
                un papel crucial en la monitorización de la salud de la madre y
                el bebé, brindando educación sobre la lactancia materna, el
                autocuidado y ayudando a las familias a navegar por esta fase de
                transición.
                </p>
              </div>
              <div className="justify-center self-start mt-5">
                <CustomButtonOval
                  className={'bg-o2o-gris-calido hover:bg-o2o-gris-calido-hover'}
                >
                Seguir leyendo
                </CustomButtonOval>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogResumen