import axios from 'axios'

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL_CLIENT,
    //baseURL: 'https://one2one-back.danthopdev.com/api/client',
    headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json; charset=utf-8'
    },
});

export const axiosCookie = axios.create({
    //baseURL: process.env.REACT_APP_BACKEND_URL,
    //https://one2one-back.danthopdev.com
    baseURL: process.env.REACT_APP_BACKEND_URL,
   // baseURL:'https://one2one-back.danthopdev.com',
    headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json; charset=utf-8'
    }
});