import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useIsMobile } from '../../../store/hooks/useIsMobile';
import { getEstrellas } from '../../../helpers/recursos';
import { imgUrl } from '../../../helpers/recursosurl';

const GoogleSatisfaccion = () => {

    const { isMobile } = useIsMobile();

    const cards = [
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
    ];

    return (
        <>
        <div className="flex flex-col px-5 text-3xl font-bold leading-9 text-center text-black justify-center items-center">
            <div className="self-center w-[539px] max-md:max-w-full">
                Esto es lo que las personas dicen de One 2 One Nurses en:
            </div>
            <div className="self-center w-[70%] max-md:max-w-full">
                <img
                    loading="lazy"
                    srcSet={`${imgUrl}/google-logo.png`}
                    className="mt-9 w-full aspect-[2.94] max-md:max-w-full"
                />
            </div>
            <div className="self-center w-full max-md:max-w-full">
                <div className="flex flex-col ">
                    <div className='container relative'>
                        <div className='swiperContainer'>
                            <Swiper
                                modules={[Navigation, Pagination]}
                                spaceBetween={20}
                                slidesPerView={!isMobile ? 2.7 : 1}
                                navigation
                                pagination={{ clickable: true }}
                            >
                                {cards.map((data, index) => (
                                <SwiperSlide key={index}>
                                    <div className="flex flex-col p-5 rounded-xl shadow-sm bg-zinc-100">
                                        <div className="flex gap-3">
                                            <img
                                            loading="lazy"
                                            srcSet={`${imgUrl}/perfil-adolfo.png`}
                                            className="shrink-0 aspect-square w-[20%] h-[20%]"
                                            />
                                            <div className="flex flex-col flex-1">
                                            <div className="text-xl font-bold text-black">
                                                Adolfo Domínguez
                                            </div>
                                            <div className="text-sm leading-4 text-black ">
                                                25 de Octubre 2023
                                            </div>
                                            <div className="flex gap-1.5 pt-3 pr-10">
                                                {getEstrellas(data?.calificacion)}
                                            </div>
                                            </div>
                                            <img
                                            loading="lazy"
                                            src={`${imgUrl}/google-icon.png`}
                                            className="shrink-0 self-start w-6 aspect-square"
                                            />
                                        </div>
                                        <div className="mt-3 text-base leading-6 text-black font-normal">
                                            One 2 One salvó la vida de mi esposo. El servicio es impecable y
                                            excelente, nos sentimos seguros y escuchados desde el inicio. La
                                            enfermera está más que capacitada: es amable y apasionada.
                                        </div>
                                    </div>
                                </SwiperSlide>
                                ) )}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default GoogleSatisfaccion