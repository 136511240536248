import React from 'react'

const SliderHome = () => {
    const items = [
        { id: 1, name: 'Roberta González', ranking: 5, comment: 'No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá! No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá!' },
        { id: 2, name: 'Roberta González', ranking: 5, comment: 'No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá!' },
        { id: 3, name: 'Roberta González', ranking: 5, comment: 'No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá!' },
        { id: 4, name: 'Roberta González', ranking: 5, comment: 'No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá!' },
        { id: 5, name: 'Roberta González', ranking: 5, comment: 'No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá!' },
        { id: 6, name: 'Roberta González', ranking: 5, comment: 'No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá!' },
        { id: 7, name: 'Roberta González', ranking: 5, comment: 'No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá!' },
        { id: 8, name: 'Roberta González', ranking: 5, comment: 'No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá!' },
        { id: 9, name: 'Roberta González', ranking: 5, comment: 'No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá!' },
        { id: 10, name: 'Roberta González', ranking: 5, comment: 'No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá! No sabía dónde encontrar servicios de enfermería a domicilio, una búsqueda en Google y me apareció One2One. Estoy convencida que es el mejor servicio. Renata es una gran enfermera ¡Gracias por cuidar de mi mamá!' }
    ];

    const prevSlider = () => {
        const bodySlider = document.getElementById('bodySlider');
        bodySlider.scrollTo(bodySlider.scrollLeft - 336, 0)
    }
    const nextSlider = () => {
        const bodySlider = document.getElementById('bodySlider');
        bodySlider.scrollTo(bodySlider.scrollLeft + 336, 0)
    }
    return (
        <div className='flex flex-col bottom-0 absolute'>
            <div className=' overflow-hidden w-screen max-w-screen items-stretch' id="bodySlider">
                <div className='inline-flex relative space-x-2 py-6 overflow-hidden h-full' id="bodySlider2">
                    {
                        items.map((item, index) => (
                            <div className='flex flex-col rounded-xl bg-o2o-gris-arena relative w-[336px] h-[235px] p-2' key={index}>
                                <div className='flex absolute -top-5 ml-2'>
                                    <img src='/img/profile.png' alt='profile' />
                                </div>
                                <div className='flex flex-row justify-between mt-8'>
                                    <p className='font-bold'>{item.name}</p>
                                    <div className='flex flex-row rounded-[18px] px-2 space-x-1 bg-white'>
                                        {
                                            new Array(item.ranking).fill("").map((start, index) => (
                                                <img src='/icons/start-fill.svg' alt="start" key={index} />
                                            ))
                                        }
                                    </div>
                                </div>
                                <p className='line-clamp-6'>
                                    {item.comment}
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='max-w-o2o w-full mx-auto'>
                <div className='flex flex-row w-full justify-end z-10 '>
                    <h3 className='mr-20'>Personas «como tú y como yo», dicen</h3>
                    <div className='flex flex-row items-center my-auto space-x-4 h-10'>
                        <div className='flex rounded-full items-center my-auto h-9 w-9 bg-o2o-gris-arena cursor-pointer' onClick={prevSlider}>
                            <button className='flex items-center mx-auto'>&nbsp;
                                <span className='material-symbols-outlined my-auto items-center text-[18px]'>
                                    arrow_back_ios
                                </span>
                            </button>
                        </div>
                        <div className='flex rounded-full items-center mx-auto h-9 w-9 bg-o2o-gris-arena cursor-pointer' onClick={nextSlider}>
                            <button className='flex items-center mx-auto'>
                                <span className='material-symbols-outlined text-[18px]'>
                                    arrow_forward_ios
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SliderHome