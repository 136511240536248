import React from 'react';

const Card = ({ title, content, tags }) => {
  return (
    <div className="bg-white rounded-xl overflow-hidden shadow-lg">
      <div className="p-[18px]">
        <div className="h-[30px] mb-[18px]">{title}</div>
        <div className="text-gray-700 text-base">
            {content}
        </div>
      </div>
      <div className="px-6 pt-1">
        {tags.map((tag, index) => (
          <span key={index} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">{tag}</span>
        ))}
      </div>
    </div>
  );
};

export default Card;