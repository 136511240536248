import React from "react";
import PropTypes from 'prop-types';
import CustomButtonOval from '../buttons/CustomButtonOval';

const SaltarPaso = ({ title, text, textBold, step, setStep, button, textButton }) => {
    const saltarPaso = () => {
        setStep(step);
    }
    button = button !== undefined ? button : true;

    return (
        <>
            <div className="bg-o2o-gris-calido flex flex-wrap max-w-[70%] ml-[25%] rounded-lg p-[18px] pt-2 mt-12 max-md:max-w-full max-md:ml-0">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[69%] max-md:ml-0 max-md:w-full">
                        <div className="flex grow gap-3 max-md:flex-wrap mt-4">
                            <div className="justify-center items-center self-start w-6 h-6 text-base font-bold leading-6 text-center text-white whitespace-nowrap bg-rose-600 rounded-2xl shadow-sm">
                                !
                            </div>
                            <div className="flex flex-col flex-1 text-black max-md:max-w-full">
                                <div className="text-base font-bold leading-6 max-md:max-w-full">
                                    {title}
                                </div>
                                <div className="mt-1.5 text-sm leading-5 max-md:max-w-full">
                                    {text}.{" "}<br /> 
                                    <span className="font-bold">
                                        {textBold}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {button && (
                        <div className="flex flex-col ml-5 w-[31%] max-md:ml-0 max-md:w-full self-end">
                            <CustomButtonOval
                                onClick={saltarPaso}
                                className="bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover"
                            >
                                {textButton || 'Saltar este paso y crear un paciente después'}
                            </CustomButtonOval>
                        </div>
                    )}
                </div>
            </div>

        </>
    )
}

SaltarPaso.propTypes = {
    setStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    textBold: PropTypes.string.isRequired,
    button: PropTypes.bool,
    textButton: PropTypes.string
}

export default SaltarPaso